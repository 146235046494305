* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  font-size: 1rem;
  line-height: auto;
}

body::-webkit-scrollbar {
  display: none;
}

.scrollBarDiv::-webkit-scrollbar {
  display: none;
}

label {
  margin: 0;
}

.sidebar_normal::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/PoppinsRegular.woff2") format("woff2"),
    url("./fonts/PoppinsRegular.woff") format("woff");
}

@font-face {
  font-family: "Poppins Medium";
  src: url("./fonts/PoppinsMedium.woff2") format("woff2"),
    url("./fonts/PoppinsMedium.woff") format("woff");
}

@font-face {
  font-family: "Poppins Semi Bold";
  src: url("./fonts/PoppinsSemibold.woff2") format("woff2"),
    url("./fonts/PoppinsSemibold.woff") format("woff");
}

@font-face {
  font-family: "Poppins Bold";
  src: url("./fonts/PoppinsBold.woff2") format("woff2"),
    url("./fonts/PoppinsBold.woff") format("woff");
}


@import "bootstrap/dist/css/bootstrap.min.css";

.logo-header {
  width: 12.5rem;
  object-fit: contain;
}

.gaping {
  gap: 56px;
}

.contact_inouts {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b6c4ce;
  border-radius: 4px;
  /* height: 46px; */
  margin-bottom: 18px;
  resize: none;
  padding: 10px 0;
  color: #000 !important;
}

input.contact_inouts::placeholder {
  color: black !important;
}

textarea.contact_inouts::placeholder {
  color: #000 !important;
}

.contact-btns {
  background: transparent linear-gradient(296deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 22px;
  border: 0;
  height: 46px;
  color: #fff;
  display: block;
}

.GrAttachment_Icon {
  position: absolute;
  right: 6px;
  top: 6px;
  z-index: 33;
}

.login-button {
  width: 9.1875rem;
  height: 3rem;
  border-radius: 1.875rem !important;
  border: none !important;
  background: linear-gradient(90deg,
      rgba(15, 122, 212, 1) 8%,
      rgba(106, 64, 212, 1) 98%);
  color: white;
  font-size: 1.25rem !important;
  cursor: pointer;
}

.twoAuthenticatebutton {
  width: 180px;
  height: 3rem;
  border-radius: 1.875rem !important;
  border: none !important;
  background: linear-gradient(90deg,
      rgba(15, 122, 212, 1) 8%,
      rgba(106, 64, 212, 1) 98%);
  color: white;
  font: normal normal normal 16px/25px Poppins;
  cursor: pointer;
}

.navbar-main {
  padding: 1.75rem 50px;
  background-color: #f1f5f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.form-main h2 {
  letter-spacing: 0rem;
  color: #0a365b;
  font-size: 2.25rem;
  line-height: 3.4375rem;
  font-family: "Poppins Semi Bold";
  margin-bottom: 1.375rem;
}

.form-group {
  position: relative;
}

.bg-term-gray {
  background-color: #b7b7b733 !important;
}

.form-group svg {
  position: absolute;
  top: 0.8125rem;
  left: 0.5rem;
  width: 1.0625rem;
  height: 1.0625rem;
}

.login-main .form-group input {
  padding-left: 2.1875rem;
}

.form-group input {
  height: 2.5rem;
  outline: none;
  padding: 0.3125rem 10px;
  background: #ffffff;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  width: 100%;
  letter-spacing: 0rem;
  font-size: 1rem;
  line-height: 1.5625rem;
  font-family: "Poppins";
  color: #808d98 !important;
}

.form-group input::placeholder {
  color: #808d98 !important;
}

.mobile_width .form-group input {
  height: 46px;
  outline: none;
  padding: 0.3125rem 10px;
  background: #ffffff;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  width: 100%;
  letter-spacing: 0rem;
  font-size: 1rem;
  line-height: 1.5625rem;
  font-family: "Poppins";
  color: #000 !important;
}

.upload-document-modal.modal.show::before {
  content: "";
  width: 100%;
  background: #000;
  height: 100%;
  position: absolute;
  opacity: 59%;
}

span.casemanage {
  border: 1px solid #E9E9E9 !important;
  border-radius: 50px !important;
  background-color: #fff !important;
  color: #060606 !important;
}

a.menu_boxing.active span.casemanage {
  background-color: #2a68d4 !important;
  color: #fff !important;
}



.form-group label {
  width: 100%;
  margin-bottom: 0.8125rem;
  letter-spacing: 0rem;
  color: #0a365b;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  font-family: "Poppins Semi Bold";
}

.save-btn {
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 1.875rem;
  padding: 0.75rem 20px;
  color: #fff;
  max-width: 18.75rem;
  width: 100%;
  margin-top: 2.125rem;
  font-size: 1.25rem;
  cursor: pointer;
}

.main-area .row.forget-password {
  height: calc(100vh - 7.1875rem);
  display: flex;
  align-items: center;
  padding: 1.25rem 0px;
}

.forget-right img {
  max-width: 28.375rem;
  max-height: 29.4375rem;
  width: 100%;
  height: 100%;
}

.forget-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-main .heading-text h3 {
  font-size: 2.25rem;
  line-height: 3.4375rem;
  font-family: "Poppins Semi Bold";
  letter-spacing: 0rem;
  color: #0a365b;
}

.login-main .heading-text p {
  letter-spacing: 0rem;
  color: #0a365b;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  font-family: "Poppins Semi Bold";
}

.login-main .form-group .forget-click {
  position: absolute;
  right: 5.6875rem;
  top: 0.5rem;
}

.login-main .form-group .forget-click svg {
  width: 1.875rem;
  height: 1.875rem;
  cursor: pointer;
}

.patientFirstName__single-value.css-1dimb5e-singleValue div div {
  margin: 0 !important;
}

.access_agreement_main {
  max-height: 570px;
  overflow-y: scroll;
}

/* .login-main {
        padding: 3.125rem 10px 50px 103px;
        display: flex;
        align-items: center;
        height: 100vh;
    } */

.login-left {
  max-width: 31.25rem;
  width: 100%;
}

.login-left .form-group input.checkbox {
  height: 1.5rem;
  width: 1.5rem;
}

.login-left .form-group.checkbox-list {
  display: flex;
  margin-top: 1.9375rem;
  margin-bottom: 2.5625rem;
}

.login-left .form-group.checkbox-list label {
  margin-bottom: 0.8125rem;
  font-size: 1rem;
  line-height: 1.5625rem;
  font-family: "Poppins Medium";
  letter-spacing: 0rem;
  color: #0a365b;
  margin-left: 0.8125rem;
}

/* / edit by nitika 05/08 start / */

.login-logo {
  max-width: 25rem;
  margin-bottom: 6.25rem;
}

/* / edit by nitika 05/08 end / */

.login-logo img {
  width: 100%;
  height: 100%;
}

.input-error {
  width: 30.4375rem;
  height: 2.25rem;
  /* UI Properties */
  background: #ffecec 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #f1a1a1;
  opacity: 1;
  text-align: left;
  /* font: normal medium Poppins; */
  font-family: "Poppins" !important;
  font-size: 0.875rem !important;
  letter-spacing: 0rem;
  color: #d85a5a !important;
  padding: 0.1875rem;
  padding-left: 1.25rem;
  font-weight: normal !important;
  margin-top: 0.3125rem;
}

.input-successful {
  background: #cfffb6 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #37953d;
  padding: 0.25rem 10px;
  letter-spacing: 0rem;
  color: #4ebd52;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  font-family: "Poppins";
  margin-top: 0.3125rem;
}

.visit-lists {
  /* padding-top: 1.6875rem;
        padding-bottom: 2.375rem; */
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 93%;
  margin: 0 auto;
  position: relative;
}

.visit-lists p {
  font-size: 1.5rem;
}

.home-list {
  border-radius: 1.125rem;
  background-color: #dfeffc;
  margin-bottom: 1.5625rem;
  display: flex;
  padding: 2.25rem 20px 34px 21px;
  /* height: 80%; */
}

.home-list .home-list-icon {
  background-color: #fff;
  padding: 0.9375rem;
  max-width: 5.875rem;
  height: 5.875rem;
  border-radius: 50%;
  object-fit: contain;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-list .home-list-content {
  margin-left: 2.1875rem;
  /* display: flex;
  flex-direction: column; */
}

.home-list .home-list-icon svg {
  width: 2.1875rem;
  height: 3.4375rem;
  object-fit: contain;
}

.home-list .home-list-content h4 {
  letter-spacing: 0rem;
  color: #0a365b;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-family: "Poppins Semi Bold";
  margin-bottom: 0.375rem;
  /* flex-grow: 1; */
}

/* / 09/08/22 edit by nitika / */

.home-lists {
  /* padding-top: 1.375rem;
    padding-bottom: 2.875rem;
    padding-left: 0.9375rem;
    justify-content: center; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 3px 10px #00000029;
}

.home-lists-1 {
  width: 100%;
  /* height: 25.8125rem; */
  padding-bottom: 1.5625rem !important;
  padding: 0;
}

/* / 09/08/22 edit by nitika / */

.home-list.list1 {
  background-color: #dfeffc;
  cursor: pointer;
}

.home-list.list2 {
  background-color: #fdeddc;
  cursor: pointer;
}

.home-list.list3 {
  background-color: #d9f8dc;
  cursor: pointer;
}

.home-list.list4 {
  background-color: #e8e6ff;
  cursor: pointer;
}

.home-list.list5 {
  background-color: #dffcf8;
  cursor: pointer;
}

.home-list.list6 {
  background-color: #fddcea;
  cursor: pointer;
}

.home-list.list7 {
  background-color: #d9f3f8;
  cursor: pointer;
}

.home-list .home-list-content span {
  letter-spacing: 0rem;
  font-size: 2.625rem;
  line-height: 1.5rem;
  font-family: "Poppins Semi Bold";
  margin-bottom: 0.625rem;
}

.home-list.list1 .home-list-content span {
  color: #0f7ad4;
}

.home-list.list2 .home-list-content span {
  color: #d98c3a;
}

.home-list.list3 .home-list-content span {
  color: #3ab746;
}

.home-list.list4 .home-list-content span {
  color: #746bd2;
}

.home-list.list5 .home-list-content span {
  color: #1ac4ac;
}

.home-list.list6 .home-list-content span {
  color: #d55c8f;
}

.home-list.list7 .home-list-content span {
  color: #58b0c1;
}

.user-type-main {
  /* padding-right: 3.3125rem; */
  margin-top: 5%;
  /* margin-bottom: 4%; */
  /* padding-bottom: 3.125rem; */
}

.home-user-type.active {
  background: transparent linear-gradient(294deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 1.125rem;
  border: none !important;
}

.home-user-type {
  padding: 1.0625rem 20px;
  font-size: 1.125rem;
  color: #0f7ad4;
  font-weight: 800;
  display: flex;
  border-bottom: 0.1875rem solid #dfeffc;
  margin-bottom: 0.625rem;
  align-items: center;
  cursor: pointer;

  height: 5.75rem;
}

.home-user-type.active .home-user-type-number {
  background-color: #fff;
  padding: 0.9375rem;
  max-width: 3.625rem;
  height: 3.625rem;
  border-radius: 50%;
  object-fit: contain;
  width: 100%;
  display: flex;
  justify-content: center;
}

.home-user-type .home-user-type-number {
  background-color: #fdeddc;
  padding: 0.9375rem;
  max-width: 3.625rem;
  height: 3.625rem;
  border-radius: 50%;
  object-fit: contain;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-right: 1.0625rem;
  align-items: center;
}

.home-user-type .home-user-type-number p {
  text-align: left;
  letter-spacing: 0rem;
  color: #e71d1d;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-family: "Poppins Semi Bold";
}

/* Tab Css Begin Here */
ul.tabs_unfinish li button#controlled-tab-example-tab-personalinfo.nav-link.active {
  background-color: #dfeffc !important;
  border-radius: 12px 12px 0px 0px !important;
  border: 1px solid #dfeffc !important;
  border-bottom: none !important;
  color: #0a365b !important;
  opacity: 1;
}

#controlled-tab-example-tabpane-personalinfo {
  border: 10px solid #dfeffc !important;
  padding: 47px 29px;
  margin-top: -1px;
}

ul.tabs_unfinish li button#controlled-tab-example-tab-paymentinfo.nav-link.active {
  background-color: #e8e6fd !important;
  border-radius: 12px 12px 0px 0px !important;
  border: 1px solid #e8e6fd !important;
  border-bottom: none !important;
  color: #0a365b !important;
  opacity: 1;
}

#controlled-tab-example-tabpane-paymentinfo {
  border: 10px solid #e8e6fd !important;
  padding: 47px 29px;
  margin-top: -1px;
}

ul.tabs_unfinish li button#controlled-tab-example-tab-defaultrate.nav-link.active {
  background-color: #faedde !important;
  border-radius: 12px 12px 0px 0px !important;
  border: 1px solid #faedde !important;
  border-bottom: none !important;
  color: #0a365b !important;
  opacity: 1;
}

#controlled-tab-example-tabpane-defaultrate {
  border: 10px solid #faedde !important;
  padding: 47px 29px;
  margin-top: -1px;
}

ul.tabs_unfinish li button#controlled-tab-example-tab-credentials.nav-link.active {
  background-color: #dff7de !important;
  border-radius: 12px 12px 0px 0px !important;
  border: 1px solid #dff7de !important;
  border-bottom: none !important;
  color: #0a365b !important;
  opacity: 1;
}

#controlled-tab-example-tabpane-credentials {
  border: 10px solid #dff7de !important;
  padding: 47px 29px;
  margin-top: -1px;
}

ul.tabs_unfinish li button#controlled-tab-example-tab-blocklist.nav-link.active {
  background-color: #f8d9d9 !important;
  border-radius: 12px 12px 0px 0px !important;
  border: 1px solid #e8e6fd !important;
  border-bottom: none !important;
  color: #0a365b !important;
  opacity: 1;
}

#controlled-tab-example-tabpane-blocklist {
  border: 10px solid #f8d9d9 !important;
  padding: 47px 29px;
  margin-top: -1px;
}

#controlled-Therapist-profiles-tab-personalinfo.nav-link.active {
  background-color: #dfeffc !important;
}

#controlled-Therapist-profiles-tabpane-personalinfo {
  border: 10px solid #dfeffc !important;
  padding: 47px 29px;
  margin-top: -1px;
}

#controlled-Therapist-profiles-tab-profexperience.nav-link.active {
  background-color: #fae6ff !important;
}

#controlled-Therapist-profiles-tabpane-profexperience {
  border: 10px solid #fae6ff !important;
  padding: 47px 29px;
  margin-top: -1px;
}

#controlled-Therapist-profiles-tab-paymentinfo.nav-link.active {
  background-color: #E8E6FF !important;
  height: 100% !important;
}

#controlled-Therapist-profiles-tabpane-paymentinfo {
  border: 10px solid #e8e6ff !important;
  padding: 47px 29px;
  margin-top: -1px;
}

#controlled-Therapist-profiles-tab-defaultrate.nav-link.active {
  background-color: #fdeddc !important;
  height: 100%;
}

#controlled-Therapist-profiles-tabpane-defaultrate {
  border: 10px solid #fdeddc !important;
  padding: 47px 29px;
  margin-top: -1px;
}

#controlled-Therapist-profiles-tab-credentials.nav-link.active {
  background-color: #d9f8dc !important;
  height: 100%;
}

.tooltipOnProfile {
  margin-top: 80px !important;
  margin-left: 110px !important;
}

#controlled-Therapist-profiles-tabpane-credentials {
  border: 10px solid #d9f8dc !important;
  padding: 47px 0px;
  margin-top: -1px;
}

#controlled-Therapist-profiles-tab-blocklist.nav-link.active {
  background-color: #f8d9d9 !important;
  height: 100%;
}

#controlled-Therapist-profiles-tabpane-blocklist {
  border: 10px solid #f8d9d9 !important;
  padding: 47px 29px;
  margin-top: -1px;
}

/* .home-user-type.active .home-user-type-number p {
        color: #0F7AD4;
    } */
input.width_set {
  max-width: 200px;
}

.labels_set_width {
  min-width: 560px;
}

.home-user-type .home-list-content h4 {
  text-align: left;
  letter-spacing: 0rem;
  color: #0a365b;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Poppins Medium" !important;
  flex-grow: 1;
  font-weight: 500;
}

.home-user-type.active .home-list-content h4 {
  color: #ffffff;
  font-family: "Poppins Semi Bold";
}

.home-user-type .home-list-content {
  font-size: 1.125rem;
  font-weight: 800;
  display: flex;
  flex-direction: column;
}

.user-table-main tbody td {
  letter-spacing: 0rem;
  color: #0a365b;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Poppins";
  vertical-align: middle;
  border-bottom: 0.0625rem solid #dee2e6;
  border-top: none;
  white-space: nowrap;
  width: auto;
}

.user-table-main {
  margin-top: 2.5rem;
}

.user-table-main tbody td b {
  font-family: "Poppins Semi Bold";
}

.table td {
  border-top: 0.0625rem solid #fff !important;
}

.view-btn {
  background: transparent;
  border-radius: 6.25rem;
  border: none;
  padding: 0.5625rem 27px;
  letter-spacing: 0rem;
  color: #a1aeb9;
  font-size: 0.9375rem !important;
  line-height: 1.5rem;
  font-family: "Poppins Semi Bold";
  border: 0.125rem solid #a1aeb9;
  cursor: pointer;
  outline: none;
}

.p3 {
  color: #6182fd;
}

/* .view-btn2{
    width: 10%;
    background: transparent;
    border-radius: 6.25rem;
    border: none;
    padding: 0.5625rem 27px;
    letter-spacing: 0rem;
    color: #A1AEB9;
    font-size: 0.9375rem;
    line-height: 1.6875rem;
    font-family: 'Poppins Semi Bold';
    border: 0.125rem solid #A1AEB9;
    } */

.view-btn.active {
  background: transparent linear-gradient(308deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 6.25rem;
  border: none;
  padding: 0.5625rem 27px;
  letter-spacing: 0rem;
  color: #ffffff;
  font-size: 0.9375rem;
  line-height: 1.6875rem;
  font-family: "Poppins Semi Bold";
}

.tr-h1 {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #a1aeb9;
  text-transform: uppercase;
  opacity: 1;
}

.adminModalParent1 {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  border-radius: 4.8px;
  outline: 0;
  width: 50%;
}

.title-22 {
  /* UI Properties */
  text-align: left;
  font: normal normal 600 1rem/1.5rem Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  text-transform: capitalize;
  margin: 0;
}

.title-24 {
  text-align: left;
  font: normal normal normal 1rem/1.5rem Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  letter-spacing: 0px;
  color: #a1aeb9;
  opacity: 1;
  margin: 0;
}

.tr-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.0625rem;
  height: 2.5rem;
  background: transparent;
  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.125rem solid #a1aeb9;
  border-radius: 5.5rem;
  opacity: 1;
  color: #a1aeb9;
}

.AccountingInsideTable .inside_table_tabs .tr-btn {
  background: transparent linear-gradient(302deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  color: #fff;
}

@media (min-width:1440px) {
  .AccountingInsideTable .th-child:nth-child(1) {
    padding-left: 2.41rem !important;
  }

  .AccountingInsideTable .invoiceNumberTitle {
    margin-left: 2.19rem;
  }
}

.invoiceNumberTitle {
  margin-left: 60px;
}

.tr-btn:hover {
  background: transparent linear-gradient(302deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  /* border-radius: 5.375rem; */
  /* opacity: 1; */
  border: none;
  color: #fff;
}

.tr-b-1 {
  border-bottom: 0.0625rem solid #e4ecf2;
  padding-top: 3% !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.user-table-main thead th {
  text-align: left;
  letter-spacing: 0rem;
  color: #a1aeb9;
  text-transform: uppercase;
  border-bottom: none !important;
  border-top: none;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Poppins";
  font-weight: 300;
  padding-top: 0;
}

.user-table-main td .user-image img {
  width: 4.25rem;
  height: 4.25rem;
  border-radius: 50%;
}

.user-image {
  width: 9.375rem;
}

.user-image img {
  width: 4.25rem;
  height: 4.25rem;
  border-radius: 50%;
}

.commonhead2 {
  margin-left: -14rem !important;
  padding-right: 0rem;
  margin-top: -2%;
  display: flex;

  margin-bottom: 4%;
}

.ColRow {
  width: 20.3125rem;
}

.ColRow2 {
  width: 22.75rem;
}

.react-calendar {
  width: 31.25rem;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 0.5rem;
  box-shadow: 0 0.75rem 24px rgb(0 0 0 / 20%);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  position: absolute;
  left: 46%;
  top: 50%;
  min-height: 40vh;
}

.react-calendar__navigation button {
  color: #6f48eb;
  min-width: 2.75rem;
  background: none;
  font-size: 1rem;
  margin-top: 0.5rem;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

abbr[title] {
  text-decoration: none;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 0.375rem;
}

.react-calendar__tile--now {
  background: #6f48eb33;
  border-radius: 0.375rem;
  font-weight: bold;
  color: #6f48eb;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #6f48eb33;
  border-radius: 0.375rem;
  font-weight: bold;
  color: #6f48eb;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}

.react-calendar__tile--active {
  background: #6f48eb;
  border-radius: 0.375rem;
  font-weight: bold;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #6f48eb;
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}

.react-calendar__tile--range {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 0;
}

.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  background: #6f48eb;
  color: white;
}

.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background: #6f48eb;
  color: white;
}

/* / nitika css start / */

.right_side_img {
  position: absolute;
  background: url("../src/images/loginpage1.png");
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: 0 100%;
}

.login-main .left_side_form_parent {
  /* max-width: 42.5rem; */
  margin: 0 auto;
  /* transform: translate(0.5rem, 38%); */
  margin-bottom: 1.875rem;
}

.left_side_form_parent {
  padding-left: 103px;
}

.left_side_form_parent .checkbox-list {
  display: flex;
  align-items: center;
}

.left_side_form_parent .checkbox-list input.checkbox {
  width: 1.5rem;
}

.left_side_form_parent .checkbox-list label {
  margin-top: 0.8125rem;
  margin-left: 0.625rem;
}

/* / 08/8/22 start / */

/* 

    .main_left_nav .main-left_menus {
        margin-top: 2.5rem;
    }
    .left_menu_side .left_inner_menu {
        margin-top: 2.5rem;
    }
    .left_menu_side .left_menu_logo {
        max-width: 70%;
        padding-top: 0.625rem;
        padding-left: 3.75rem;
    }
    .collapse{display: none;}

    .collapseBar {
        width: 30%;
    }
    .left_menu_side .left_collpas {
        margin-left: 1.875rem;
    }
    .left_menu_side .left_collpas svg {
        font-size: 2.5rem;
        color: rgb(106,64,212)!important;
    }
    .left_menu_side .loGRnW .bszCmo {
        text-align: left;
        display: flex;
        justify-content: flex-start;
        padding: 0.9375rem 40px;
        align-items: flex-start;
        margin-bottom: 1.875rem;
    }
    .left_menu_side .loGRnW .bszCmo svg {
        font-size: 1.4375rem;
        color: rgb(106,64,212) !important;
        width: 1.3125rem;
        height:1.375rem;
    }
    .left_menu_side .loGRnW .bszCmo.active svg {
        color: #fff!important;
    }
    .collapseBar .left_menu_logo .btgFJI svg {
        font-size: 2.5rem;
        margin-left: 1.5625rem;
        color: rgb(106,64,212)!important;
    }
    .collapseBar .left_inner_menu .itaNev {
        display: flex;
        justify-content: center;
        margin-bottom: 1.875rem;
    }
    .collapseBar .left_inner_menu {
        margin-top: 1.25rem;
    }
    .collapseBar .left_inner_menu .itaNev svg {
        width: 1.3125rem;
        height: 1.375rem;
        color: rgb(106,64,212) !important;
    }
    .left_menu_side .left_inner_menu .loGRnW .active {
        border-top-right-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
    }
    .collapseBar .left_inner_menu .itaNev.active {
        border-top-right-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
    }
    .collapseBar .left_inner_menu .loGRnW .itaNev.active svg {
        color: #fff !important;
    }
    .left_menu_side .left_inner_menu .loGRnW h5 {
        font-size: 1.25rem;
        color: #0A365B;
        letter-spacing: 0;
    }
    .left_menu_side .left_inner_menu .active h5 {
        color: #fff;
    } */

.sidebar_normal img {
  width: 67%;
  padding-left: 1.625rem;
  object-fit: cover;
}

.admin-image {
  width: 75%;
  padding-left: 0.625rem;
  object-fit: cover;
}

.sidebar_normal {
  overflow: auto;
  width: 17.1875rem;
  height: 100%;
  transition: 0.3s all ease-in-out;
  background: #f5faff;
  z-index: 5;
  padding-top: 0.9375rem;
  position: fixed;
}

.sidebar_collapse {
  width: 5.9375rem;
  height: 100%;
  transition: 0.3s all ease-in-out;
  background: #f1f5f8;
  z-index: 5;
  position: fixed;
}

.chat-box-download {
  background: #eceff2 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  text-align: left;
  font: normal normal normal 16px/26px Poppins;
  font-size: 16px;
  letter-spacing: 0px;
  color: #0a365b;
  padding: 16px 17px;
  border: none;
  width: 70%;
}

.sidebar_normal .menu_icon_slidein {
  margin-left: auto;
  padding-right: 1.25rem;
  margin-left: auto;
  padding-right: 1.25rem;
  display: flex;
  justify-content: center;
}

.sidebar_normal .menu_icon_slidein svg {
  width: 1.75rem;
  height: auto;
  color: rgb(106, 64, 212) !important;
}

.sidebar_normal .inner_content_leftbar .menuItemss {
  margin-bottom: 0.625rem;
  padding: 0.75rem 30px;
}

.sidebar_normal .inner_content_leftbar .menuItemss svg {
  width: 1.3125rem;
  height: 1.375rem;
  color: rgb(106, 64, 212) !important;
}

.sidebar_normal .inner_content_leftbar .menuItemss.active svg {
  color: #fff !important;
}

.sidebar_normal .inner_content_leftbar .menuItemss h5 {
  font-size: 1.25rem;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 0;
}

.sidebar_normal .inner_content_leftbar .menuItemss.active {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.sidebar_collapse .menu_icon_slidein svg {
  width: 1.75rem;
  height: auto;
  color: rgb(106, 64, 212) !important;
}

.sidebar_collapse .inner_content_leftbar {
  margin-top: 1.875rem;
}

.sidebar_collapse .inner_content_leftbar .active {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.sidebar_collapse .inner_content_leftbar svg {
  color: rgb(106, 64, 212) !important;
  width: 1.3125rem;
  height: 1.3125rem;
}

.sidebar_collapse .inner_content_leftbar .active {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  padding: 0;
}

.sidebar_collapse .inner_content_leftbar .active svg {
  color: #fff !important;
}

.sidebar_normal .inner_content_leftbar svg {
  color: rgb(106, 64, 212);
  width: 100%;
}

.sidebar_normal .inner_content_leftbar .activeee svg {
  color: #fff !important;
}

.sidebar_normal .inner_content_leftbar .profileIcon {
  width: 6.625rem;
  height: 6.625rem;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s all ease-in-out;
}

.menu-name {
  font: normal normal medium 1.25rem/68px Poppins;
  letter-spacing: 0rem;
  font-family: "Poppins Medium";
  opacity: 1;
  font-weight: normal;
  font-size: 1.25rem;
  color: #0a365b;
}

.m-activee {
  font: normal normal medium 1.25rem/68px Poppins;
  letter-spacing: 0rem;
  font-family: "Poppins Medium";
  opacity: 1;
  font-weight: normal;
  font-size: 1.25rem !important;
  color: #fff !important;
}

.sidebar_normal .inner_content_leftbar .profileIcon2 {
  /* width: 6.625rem;
    height: 6.625rem; */
  border-radius: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s all ease-in-out;
}

.sidebar_collapse .inner_content_leftbar .menuIcons {
  margin-bottom: 20%;
  margin-top: 20%;
}

.sidebar_collapse .inner_content_leftbar .hcgXix {
  height: 4.75rem;
  width: 4.75rem;
  background-color: green;
  display: none;
}

.sidebar_normal .inner_content_leftbar h4 {
  color: #0f7ad4;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 0;
  margin-top: 0.8125rem;
}

.sidebar_normal .inner_content_leftbar p {
  font-size: 0.875rem;
  color: #0a365b;
  margin-bottom: 0;
}

/* / 08/8/22 end /

    / 09/8/22 start / */

.right_main_top_nav {
  display: flex;
  align-items: center;
}

.right_main_top_nav svg {
  margin-right: 0.75rem;
  color: rgb(106, 64, 212) !important;
}

.right_main_top_nav span {
  font-size: 1.75rem;
  line-height: 2.625rem;
  font-weight: 600;
  color: #0a365b;
  /* font: normal normal 600 0.875rem/21px Poppins;
        letter-spacing: 0rem;
        color: #0f7ad4;
        text-transform: uppercase;
        opacity: 1; */
}

.home-lists .title_home_list h3 {
  font-size: 1.375rem;
  line-height: 2.0625rem;
  font-weight: 600;
  color: #0a365b;
  font-family: Poppins, SemiBold;
  padding: 0.625rem;
}

/* .tabs_user_type {
    padding-left: 2.9375rem;
} */

.inside_table_tabs tbody {
  border-top: none !important;
}

.inside_table_tabs .table.user-table-main {
  max-width: 100%;
  margin: 0 auto 0;
}

.user-table-main tbody tr td button.view-btn {
  /* margin-left: auto; */
  display: flex;
}

.inside_table_tabs .user-table-main tbody tr td .user-image {
  display: flex;
  align-items: center;
}

.inside_table_tabs .user-table-main tbody tr td .user-image p {
  margin-left: 1.25rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  margin-bottom: 0;
}

.inside_table_tabs .user-table-main tbody td .inside_tab-text {
  display: flex;
  align-items: baseline;
}

.inside_table_tabs .user-table-main tbody td .inside_tab-text p {
  font-size: 1rem;
  color: #0a365b;
  font-family: Poppins, Regular;
  line-height: 1.5rem;
  margin-bottom: 0;
  font-weight: 400;
}

.user-table-main tbody tr td button.view-btn {
  /* margin-left: auto; */
  display: flex;
  border-color: transparent;
  color: #fff;
  background: linear-gradient(30deg,
      rgba(106, 64, 212, 1) 0%,
      rgba(15, 122, 212, 1) 100%);
}

/* .right_side_parent {
    padding-left: 16%;
    padding-right: 0rem;
    transition: 0.3s all ease-in-out;
} */

.left_side_parent {
  padding-left: 3.5%;
  transition: 0.3s all ease-in-out;
}

.chart_content .chart_main_parent h3 {
  font-size: 1.5rem;
  font-family: Poppins, SemiBold;
  line-height: 2.1875rem;
  font-weight: 600;
  color: #0a365b;
  margin-bottom: 0.5625rem;
}

.chart_content .chart_main_parent p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: Poppins, "Poppins Medium";
  color: #0a365b;
  margin-bottom: 0;
  font-weight: 400;
}

.right_side_from_chart {
  display: flex;
  justify-content: center;
  text-align: center;
}

.right_side_from_chart ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 0;
}

.right_side_from_chart ul li .date_text p {
  width: 100%;
  font-size: 0.9375rem;
  line-height: 1.4375rem;
  color: #0a365b;
  margin-bottom: 0;
  margin-right: 1rem;
  font-family: Poppins, "Poppins Medium";
}

.right_side_from_chart ul li .next_prev_btns svg {
  width: 1.4375rem;
  height: 1.4375rem;
  color: #c3d2de !important;
}

.right_side_from_chart ul li .froms_side_rigth {
  margin-left: 1.4375rem;
}

.right_side_from_chart ul li .froms_side_rigth button.view-btn {
  margin: 0 0.625rem 0;
  padding: 0.5625rem 30px;
}

/* / 09/8/22 end /

    / 10/08/22 strat /     */

.chart_content .top_chart_parent {
  padding: 0.625rem 20px;
  width: 90%;
  margin: 0 auto;
}

.chart_content .chart_main_top {
  margin-top: 3.375rem;
}

.chart_content {
  box-shadow: 0rem 3px 10px #00000029;
  padding-top: 1%;
}

.patient_main_parent {
  display: flex;
  justify-content: flex-start;

  padding-top: 2rem;
}

.patient_main_parent svg {
  color: #9cacb8;
  width: 1.375rem;
  height: 1.375rem;
}

.patient_main_parent input {
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #808d98;
  font-weight: 400;
}

.patient_main_parent .search_bar_frm {
  display: flex;
  align-items: center;
}

.patient_main_parent .patient_right_form {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.patient_main_parent .patient_right_form ul {
  display: flex;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}

.patient_main_parent .patient_right_form ul li {
  padding: 0 0.625rem;
}

.patient_main_parent .patient_right_form ul .inner_content_form {
  display: flex;
  align-items: center;
}

.patient_main_parent .inner_content_form p {
  font-size: 1rem;
  margin-bottom: 0;
  font-family: "Poppins Medium";
  line-height: 1.5625rem;
  color: #0a365b;
  letter-spacing: 0;
  margin-right: 0.8125rem;
  margin-left: 0.8125rem;
  font-weight: normal;
}

.patient_main_parent .inner_content_form select {
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  width: 15.625rem;
  height: 2.875rem;
  padding: 0.4375rem 20px;
  background: #dfeffc;
  border-color: #dfeffc;
  border-radius: 3.125rem;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  font-family: Poppins, Medium;
  font-weight: 400;
  outline: none;
  background: url(images/caret-down-fill.svg) no-repeat right white;
  background-position-x: 91%;
  background-position-y: 58%;
  display: inline-block;
  position: relative;
  background-color: #dfeffc;
}

.modal-header {
  border: none !important;
  padding-bottom: 0 !important;
}

.modal-header .btn-close:focus {
  outline: none;
  box-shadow: none;
}

.main-pointer {
  cursor: pointer;
}

select {
  /* -webkit-appearance: none; */
  /* width: 6.25rem; */
  font-size: 0.6875rem;
  border: 0;
}

.head1 {
  font-size: 1.125rem;
  font-weight: bolder;
  color: #0a365b;
}

.Patient_table_main {
  padding: 0rem 47px;
}

.Patient_table_main table {
  margin: 3.125rem 0 0;
}

.Patient_table_main .table tbody {
  border-top: none;
}

.Patient_table_main .table tbody tr td .user-image {
  display: flex;
  align-items: center;
}

.Patient_table_main .inner_text_info {
  margin-left: 1.25rem;
}

.Patient_table_main .inner_text_info p {
  margin-bottom: 0;
  font-family: Poppins, SemiBold;
  font-size: 1rem;
  font-weight: 600;
  color: #0a365b;
  line-height: 1.5rem;
  letter-spacing: 0;
}

.Patient_table_main .inner_text_info p span {
  color: #a1aeb9;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: Poppins, Medium;
  font-weight: 500;
}

.Patient_table_main tbody tr td .open_select {
  background: #d9f8dc;
  border: 0.0625rem solid #d9f8dc;
  width: 11.125rem;
  padding: 0.625rem 20px;
  color: #17a325;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-family: Poppins, Medium;
  font-weight: 500;
  border-radius: 1.125rem;
}

.open_selectt {
  background: #d9f8dc;
  border: none;
  width: 11.125rem;
  padding: 0.625rem 20px;
  color: #17a325;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-family: Poppins, Medium;
  font-weight: 500;
  border-radius: 1.125rem;
}

.Patient_table_main tbody tr td .right_patient {
  margin-right: auto;
}

/* 
    / 10/08/22 end /

    / 11/08/22 start / */

.patient_right_form .inner_content_form {
  /* display: flex; */
  align-items: center;
}

.home_page_modal .modal-dialog .modal-content {
  width: 65.8125rem;
  margin: 0 auto;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 3px 10px #00000029;
  opacity: 1;
}

.home_page_modal .modal-content .modal-header {
  border-bottom: none;
}

.home_page_modal .modal-header .btn-close {
  width: 1.1875rem;
  height: 1.1875rem;
  color: #a1aeb9;
  padding-top: 0.8125rem;
  display: flex;
  justify-content: right;
}

.modal_parent .modal-dialog {
  max-width: 65.8125rem;
}

.modal_parent .modal-header {
  padding-top: 0.8125rem;
  border-bottom: none;
}

.modal_parent .modal-header button.btn-close {
  width: 1.1875rem;
  height: 1.1875rem;
  color: #a1aeb9 !important;
}

.modal_parent .modal-header button.btn-close:focus {
  box-shadow: none;
}

.modal_parent .modal_cross_btn {
  display: flex;
  padding-left: 2.375rem;
  align-items: center;
}

.modal_parent .modal_cross_btn h5 {
  font-size: 1.5rem;
  line-height: 2.1875rem;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  color: #0a365b;
  margin-bottom: 0;
  letter-spacing: 0;
}

.modal_parent .modal-content {
  width: 90.5rem;
  right: 11.125rem;
}

.modal_parent .modal-content .form_input {
  margin: 0 auto;
}

.modal_parent .modal-content .form_input svg {
  width: 1.375rem;
  height: 1.375rem;
  color: #9cacb8;
}

.modal_parent .modal-content .form_input input {
  font-size: 0.875rem !important;
  font-family: Poppins, Regular;
  line-height: 1.5rem;
  font-weight: 400;
  width: 15.625rem;
  color: #808d98;
}

.modal_parent .modal_inner_text {
  padding: 0 2.375rem;
  padding-bottom: 2.4375rem;
}

.modal_parent .modal_inner_text tbody {
  border-top: none;
}

.modal_parent .modal_inner_text thead tr th {
  font-weight: 500;
  color: #a1aeb9;
  font-size: 1rem;
  font-family: Poppins, Regular;
  padding-left: 1.5%;
}

.row1 {
  margin-right: 3% !important;
  margin-left: 3% !important;
}

/* .modal_parent .modal_inner_text thead tr th:first-child{
        padding-left: 2.5rem;
    } */

.modal_parent .modal_inner_text tbody tr td .inside_tab-text {
  display: flex;
  align-items: center;
}

.modal_parent .modal_inner_text tbody .inside_tab-text p.first__name {
  margin-bottom: 0;
  margin-left: 1.625rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: #0a365b;
  font-family: Poppins, SemiBold;
}

.modal_parent .modal_inner_text tbody tr td {
  border-bottom: none;
}

.modal_parent .modal_inner_text tbody td .inside_tab-text p {
  font-size: 1rem;
  margin-bottom: 0;
  line-height: 1.5rem;
  font-family: Poppins, Regular;
  font-weight: 500;
}

.user-table-main thead tr th span {
  padding-left: 5.625rem;
}

/* / 11/08/22 end /

    / 17/08/22 start / */

.inside_table_tabs {
  width: 100%;
  display: flex;
  justify-content: center;
}

.newusertable {
  width: 100%;
  margin-top: 3.1875rem;
  justify-content: center;
}

.inside_tab-text .inner_text_info p span {
  color: #a1aeb9;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: Poppins, Medium;
  font-weight: 500;
}

.commonh4 {
  font: normal normal 600 1.5rem/35px Poppins;
  font-size: 1.5rem;
  font-family: "Poppins Semi Bold";
}

.profile-icon {
  top: 14rem;
  left: 20.3125rem;
  width: 10.375rem;
  height: 10.3125rem;
  /* UI Properties */
  /* background: #f1f5f8 0% 0% no-repeat padding-box;
        border-radius: 12.5rem; */
  opacity: 1;
}

.profile-icon {
  width: 10.3125rem;
  background: #f1f5f8 0% 0% no-repeat padding-box;
  color: #0f7ad4;
  border-radius: 100%;
}

.upload-P {
  top: 17.375rem;
  left: 32.1875rem;
  width: 7.8125rem;
  height: 1.4375rem;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #a1aeb9;
  text-transform: uppercase;
  opacity: 1;
}

.browse-f {
  /* Layout Properties */
  top: 19.3125rem;
  left: 32.1875rem;
  width: 7.8125rem;
  height: 1.4375rem;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.inputFields {
  width: 35.625rem !important;
}

.top-input {
  width: 38.5625rem !important;
}

.lableField {
  /* UI Properties */
  font: normal normal 600 1.125rem/27px Poppins !important;
  letter-spacing: 0rem !important;
  color: #0a365b !important;
  opacity: 1 !important;
}

.editField {
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #0f7ad4;
  opacity: 1;
}

.FieldBox {
  width: 79%;
}

.d-field {
  width: 100% !important;
}

.h-div1 {
  height: 10%;
}

.d-570 {
  width: 35.625rem;
}

.submit-btn1 {
  width: 18.75rem !important;
  height: 2.875rem !important;
  margin-top: 8%;
  border-radius: 1.25rem;
  border: none;
  background: linear-gradient(90deg,
      rgba(15, 122, 212, 1) 8%,
      rgba(106, 64, 212, 1) 98%);
  color: white;
  cursor: pointer;
}

.alert {
  padding: 0.1875rem;

  font-size: 0.875rem;
  margin-top: 0.1875rem;
}

.alert-danger {
  border: 0.0625rem solid #e89797 !important;
  background-color: #ffecec !important;
  color: #e89797 !important;
}

.heading_1 {
  text-align: left;
  font: normal normal 600 1.5rem/35px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.textarea1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  opacity: 1;
}

.textarea1::placeholder {
  text-align: left;
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #808d98;
  opacity: 1;
  margin-left: 0.625rem;
  padding: 0.625rem;
  padding-top: 0rem;
}

.main_bodyT {
  padding-left: 4%;
}

.f-8 {
  font-size: 1.5rem;
}

.f-10 {
  font-size: 1.125rem;
}

.input-therepist2 {
  width: 2.25rem;
}

.input-text2 {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #a1aeb9;
  text-transform: uppercase;
  opacity: 1;
  margin-bottom: 0.125rem;
}

.input-text3 {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  font-size: 1rem;
  color: #0a365b;
  text-transform: capitalize;
}

.input-div {
  margin-left: 1.4375rem;
  margin-top: 1.3125rem;
}

.frame-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.mapcheckbox {
  height: 2.25rem;
}

.dfile-box {
  display: flex;
  border: 0.0625rem solid #d5e3ee;
  border-radius: 0.5rem;
  justify-content: flex-start;
  gap: 1.125rem;
  width: 47.5rem;
  padding: 0.5rem 10px 8px 15px;
  align-items: center;
}

.dfile-margin-1 {
  margin-bottom: 1.25rem;
}

.dfile-img {
  width: 4.25rem;
  height: 4.25rem;
  border-radius: 50%;
  background-color: #dfeffc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-img {
  width: 4.25rem;
  height: 4.25rem;
  margin-right: 5%;
  margin-left: -1.3%;
  /* margin-left: 10%; */
}

.rounded-img {
  border-radius: 200px;
}

.table_left_padding {
  padding: 0 0 0 44px;
}

.admin-image {
  width: 6.625rem !important;
  height: 6.625rem !important;
  margin-left: 10% !important;
  padding: 0 !important;
  border-radius: 50%;
}

.tt-common {
  text-align: center;
}

.th-child {
  padding-left: 5px !important;
  border-bottom: 0.0625rem solid #fff !important;
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #a1aeb9;
  text-transform: uppercase;
  opacity: 1;
}

.th-child:nth-child(1) {
  padding-left: 5.41rem !important;
}

.td-h1 {
  /* padding-top: 5% !important; */
  border-bottom: 0.0625rem solid #e4ecf2 !important;
}

.td-h1:nth-child(2) {
  padding-top: 5% !important;
  border-bottom: 0.0625rem solid #e4ecf2 !important;
}

.td-h1:nth-child(3) {
  padding-top: 5% !important;
}

.td-h1:nth-child(4) {
  padding-top: 3% !important;
}

.td-h1:nth-child(5) {
  padding-top: 3% !important;
  border-bottom: 0.0625rem solid #e4ecf2 !important;
}

.table {
  border: 0rem solid #fff;
}

.table thead th {
  /* border-bottom: 0rem solid #fff ; */
  border-top: 0rem solid #fff;
  /* width: 27%; */
}

.table-seprate-therapist .table thead th {
  border-bottom: 1px solid#EBEAED !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  color: #A1AEB9 !important;
}

.table-seprate-therapist .th-child:nth-child(1) {
  padding-left: 4rem !important;
}

.table-seprate-therapist .table td {
  border-top: 1px solid#EBEAED !important;
}

.therapist-body {
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  color: #0A365B !important;
}

.f-color {
  text-align: left;
  font: normal normal 600 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.f-color2 {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.td-switch {
  /* padding: 3% !important; */
  border-bottom: 0.0625rem solid #e4ecf2;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 3.625rem;
  height: 1rem;
  background: #b6c4ce;
  border-radius: 6.25rem;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: --0.625rem;
  left: -0.125rem;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 2.8125rem;
  transition: 0.2s;
  background: #9b9b9b;
  box-shadow: 0 0 0.125rem 0 rgba(196, 179, 179, 0.29);
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
  left: calc(100% - -0.125rem);
  transform: translateX(-100%);
  background: #60d670;
}

.switch-btn {
  width: 25%;
  border: none;
  padding-bottom: 1%;
}

/* .td-switch {
    padding-top: 2% !important;
} */
.table td {
  height: 1.875rem;
}

.tablecss {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 11.5% !important;
  padding: 1.0625rem 0px;
  border-bottom: 0.0625rem solid rgba(128, 128, 128, 0.22);
  align-items: center;
}

.checkBox {
  width: 2.25rem;
  margin-top: -0.5625rem;
  margin-right: 0.9375rem;
}

.O-div {
  margin-top: 1.5625rem;
}

.c-signup {
  text-align: left;
  /* font: normal normal medium 1.75rem/42px Poppins; */
  font-family: "Poppins", sans-serif;
  font-size: 1.75rem;
  font-weight: medium;
  letter-spacing: 0rem;
  color: #0f7ad4;
  text-transform: uppercase;
  opacity: 1;
}

.cs-date {
  text-align: left;
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 0rem;
  opacity: 1;
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.cs-heading {
  text-align: left;
  font: normal normal 600 2.25rem/55px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  padding-top: 2%;
  margin-bottom: 1.2%;
}

.cs-subhead {
  text-align: left;
  font: normal normal 600 1.125rem/27px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.mb-6 {
  margin-bottom: 2% !important;
}

.cs-input {
  width: 100%;
  height: 2.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  opacity: 1;
  padding: 0.625rem;
  color: #000000 !important;
  -moz-appearance: textfield;
}

.cs-input::placeholder {
  text-align: left;
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #808d98;
  opacity: 1;
}

.error-b {
  bottom: 0.75rem;
}

.mb-7 {
  margin-bottom: 30%;
}

.ca-lable {
  font: normal normal 600 0.9375rem/1.4375rem Poppins;
  letter-spacing: 0rem;
  color: #0f7ad4;
  opacity: 1;
}

.ca-btn {
  width: 18.75rem;
  height: 2.875rem;
  /* UI Properties */
  background: transparent linear-gradient(285deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  border: none;
  color: #fff;
  text-align: center;
  font: normal normal normal 1.125rem/27px Poppins;
  letter-spacing: 0rem;
  color: #ffffff;
}

.ca-remove {
  background-color: #fff;
  border: none;
  font-size: 1.875rem !important;
  margin-left: 8px;
}

.cs-addbtn {
  /* UI Properties */
  border: none;
  opacity: 1;
  padding: 0.625rem;
  background-color: transparent;
  text-decoration: underline;
  color: #0f7ad4;
}

.gap-15 {
  gap: 15px;
}

.top-change {
  top: 0.25rem !important;
}

.box-icon {
  width: 2.875rem;
  cursor: pointer;
  height: 2.875rem;
  /* UI Properties */
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 100%;
}

.Hiplus {
  font-size: 2.8125rem;
  padding: 0.625rem;
  color: #fff;
}

.ca-li {
  text-align: left;
  font: normal normal 600 1.125rem/27px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.ca-loginBtn {
  border: none;
  text-align: center;
  width: 9.1875rem;
  height: 3rem;
  background: transparent linear-gradient(300deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  text-align: center;
  font: normal normal normal 1.25rem/30px Poppins;
  letter-spacing: 0rem;
  color: #ffffff;
  opacity: 1;
}

.ca-body {
  overflow: hidden;
  background-image: url(../src/images/logo_hologram_img@2x.png);
  background-repeat: no-repeat;
  background-size: 29.75rem;
  background-position-x: 100%;
  background-position-y: 17.5rem;
  min-height: 100vh;
  background-attachment: fixed;
}

/* .Reason_of_rejection{
  height: 89vh;
  min-height: unset;
}
.Reason_of_rejection .terms{
 height: 11.875rem;
} */

.navImg {
  width: 14rem;
  padding: 0.625rem;
}

.alert-span {
  width: 100%;
}

.ad-headd {
  text-align: left;
  font: normal normal Poppins;
  font-size: 1.125rem;
  letter-spacing: 0rem;
  color: #808d98;
  opacity: 1;
  cursor: pointer;
  font-weight: 600;
}

.div-bdrr {
  border-bottom: 0.0625rem solid #e4ecf2;
  border-bottom: 0.3125rem solid #0f7ad4 100%;
}

.ad-labels {
  text-align: left;
  font: medium Poppins;
  font-size: 1rem;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.ad-input {
  width: 21.875rem;
  height: 2.875rem;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  opacity: 1;
  padding: 0rem 10px;
}

.PTSectionInput .ad-input {
  color: #007bff !important;
}

.ad-input::placeholder {
  font: normal normal normal 1rem/25px Poppins;
  color: #808d98;
  opacity: 1;
}

.ad-btnn {
  width: 8.75rem;
  height: 2.875rem;
  background: transparent linear-gradient(300deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  border: none;
  color: #fff;
  padding: 0.3125rem;
  display: flex;
  align-items: center;
  gap: 10%;
}

.ad-btn-back {
  width: 8.875rem;
  height: 2.875rem;
  /* UI Properties */
  background: #d8dfe4 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  text-align: center;
  font: normal normal normal 1.125rem/27px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  border: none;
  margin-left: 1%;
}

.ad-activee {
  text-align: left;
  font: normal normal bold Poppins;
  letter-spacing: 0rem;
  font-size: 1.25rem;
  color: #0f7ad4;
  font-weight: 600;
  opacity: 1;
  position: relative;
  margin-bottom: 0;
}

.ad-activee::before {
  content: "";
  position: absolute;
  bottom: 0;
  background: linear-gradient(300deg, #6a40d4 0%, #0f7ad4 100%);
  background-position: bottom;
  height: 2px;
  width: 100%;
}

.cs-prefres {
  text-align: left;
  font: normal normal medium Poppins;
  font-size: 1rem/25px;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.py-input {
  padding: 0rem 10px;
  width: 34.375rem;
  height: 2.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  opacity: 1;
}

.css-1fdsijx-ValueContainer {
  /* width: 34.375rem; */
  padding: 0;
  overflow: hidden;
}

.css-t3ipsp-control {
  border: 0.0625rem solid #b6c4ce !important;
  box-shadow: unset !important;
}

.wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 6.25rem;
  width: 6.25rem;
  z-index: 9;
}

.desktop-50 {
  width: 50% !important;
}

.zindex {
  z-index: 10;
}

.spinner-border {
  position: relative;
  height: 6.25rem;
  width: 6.25rem;
  animation: bounce 1s infinite;
}

.clear-pdf {
  position: relative;
  height: 16px;
  width: 16px;
  animation: bounce 1s infinite;
  margin-right: 11px;
}

.wrap:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.678);
}

/* position: absolute;
        content: '';
        display: block;
        top: 0;
        width: 0.9375rem;
        height: 0.9375rem;
        border-radius: 50%;
        background-color: #fbae17;
        transform-origin: 50%;
        animation: bounce 500ms alternate infinite ease;
    }  */
@keyframes bounce {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0);
  }
}

.py-input-ca {
  width: 100% !important;
}

.py-input1 input {
  border: none;
}

.py-input1 input::placeholder {
  border: none;
}

.py-input1 {
  padding: 0rem 10px;
  width: 16.875rem;
  height: 2.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  opacity: 1;
}

.py-input1::placeholder {
  text-align: left;
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #808d98;
  opacity: 1;
}

.py-input::placeholder {
  text-align: left;
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #808d98;
  opacity: 1;
}

.py-p {
  text-align: left;
  font: normal normal medium Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  font-size: 1rem;
  font-weight: 600;
}

.py-lb {
  text-align: left;
  font: normal normal Poppins;
  letter-spacing: 0rem;
  color: #0f7ad4;
  opacity: 1;
  font-size: 1.125rem;
  font-weight: 600;
}

.py-lb2 {
  text-align: left;
  font: normal normal Poppins;
  letter-spacing: 0rem;
  color: #808d98;
  opacity: 1;
  font-size: 1.125rem;
  font-weight: 600;
}

.py-dt {
  text-align: left;
  font: normal Poppins;
  letter-spacing: 0rem;
  color: #0f7ad4;
  font-size: 1rem;
  font-weight: 800;
}

.terms {
  height: 26.875rem;
  overflow-y: scroll;
  font-size: 1.125rem;
  color: rgb(46, 46, 46);
  font-family: normal Poppins;
  letter-spacing: 0rem;
  opacity: 1;
  width: 89%;
}

.tch3 {
  text-align: left;
  font: normal Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  font-size: 2.25rem;
  font-weight: 600;
}

.tcp {
  text-align: left;
  font: normal normal medium Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  font-size: 1rem;
  font-weight: 600;
}

.tclb {
  text-align: left;
  font: normal medium Poppins;

  color: #0a365b;
  opacity: 1;
  font-size: 1rem;
  font-weight: 600;
}

.t-signup {
  font-size: 1.75rem;
}

.pd-p {
  font-size: 1rem;
  font-weight: 600;
  font: normal poppins;
  color: rgb(0, 0, 0);
}

.pd-dt {
  font-weight: 800;
  color: #0f7ad4;
}

.pd-bx {
  border-radius: 0.3125rem;
  height: 3.75rem;
}

.tcch {
  width: 1.5rem;
  height: 1.5rem;
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 0.25rem;
  opacity: 1;
}

.term-p {
  padding-left: 3%;
  width: 81.1875rem;
  height: 26.875rem;
}

.w-550 {
  width: 34.375rem;
}

.w-270 {
  width: 16.875rem;
}

.spacing_left {
  padding-left: 60px !important;
}

.searching_spacing {
  padding-top: 2rem;
}

.img-modal {
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
}

.tableLang {
  word-wrap: break-word;
}

.therapist-name {
  word-wrap: break-word;
}

h6.new_user_text {
  font-size: 15px;
}

.ss-h4 {
  font-size: 36px !important;
  text-align: center;
  font: normal normal 600 2.25rem/3.4375rem Poppins SemiBold;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 2.25rem;
  line-height: 3.4375rem;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.ss-p {
  text-align: left;
  font: normal normal 600 1.125rem/1.6875rem Poppins;
  letter-spacing: 0rem;
  color: #808d98;
  opacity: 1;
}

.np-h1 {
  font-size: 2.25rem;
  font-weight: 600;
  font-family: "Poppins";
  color: #0a365b;
}

.np-p {
  font-size: 1rem;
  font-weight: 600;
  font-family: "Poppins";
  color: rgb(116, 116, 116);
}

.np-it-h6 {
  font-family: "Poppins medium";
  color: rgb(0, 0, 0);
  text-transform: capitalize;
  font-size: 1.125rem;
  color: #0a365b;
  /* overflow: hidden; */
  word-break: break-all;
}

.np-lb {
  font-size: 1rem;
  font-family: "Poppins";
  color: #808d98;
  margin-right: 0.3125rem;
}

.np-ch {
  min-width: 2.25rem;
  min-height: 2.25rem;

  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
}

.npdt {
  font-weight: 800;
  color: #0f7ad4;
}

.np-dt {
  font: normal Poppins;
  letter-spacing: 0rem;
  opacity: 1;
  font-size: 1rem;
  font-weight: 800;
  color: #0c63e4;
}

.top-26 {
  top: -0.75rem;
}

.top-44 {
  top: -2.75rem;
}

.top-11 {
  top: -0.6875rem;
}

.bottom-11 {
  bottom: 1.1875rem;
}

.alert {
  --bs-alert-bg: transparent !important;
  --bs-alert-padding-x: none !important;
  --bs-alert-padding-y: none !important;
  --bs-alert-margin-bottom: none !important;
  margin-bottom: 0rem !important;
}

.cred-p {
  font-size: 1rem;
  font-weight: 800;
  font: normal poppins;
  color: rgb(0, 0, 0);
}

.cred-covid {
  width: 100% !important;
}

.cred-sign {
  border-radius: 0.3125rem;
  background: transparent linear-gradient(285deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  width: 21.1875rem !important;
  opacity: 1;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 1.125rem;
  font-family: "Poppins";
  letter-spacing: 0rem;
  color: #ffffff;
  opacity: 1;
}

.cred-lbl {
  font-size: 1rem;
  font-weight: 600;
  font: normal poppins;
}

.cred-ch-box {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.3125rem;
}

.cred-last-in {
  width: 47%;
  height: 2.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  opacity: 1;
  padding: 0.625rem;
}

.from-height {
  height: calc(36px + 8px);
}

/* .cs-input-f,
.form-control {
  height: 100% !important;
} */

.pteval-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ptinput {
  height: auto !important;
}

.therapist-inputs {
  width: 2.25rem;
  height: 2.5rem;
}

input[type="text"] {
  font-size: 1.5rem;
}

input,
optgroup,
select,
textarea {
  margin: 0;
  font: normal medium poppins;
  font-size: 1rem !important;
  line-height: inherit;
  color: #808d98 !important;
}

.css-1dl6o6q-control {
  height: 46px !important;
  border-color: #b6c4ce !important;
  box-shadow: unset !important;
}

.css-1u9des2-indicatorSeparator {
  background-color: unset;
}

.css-5gt656-control {
  min-height: 46px !important;
  height: 46px !important;
  border-color: #b6c4ce !important;
}

.css-qbdosj-Input {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.css-qbdosj-Input {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.css-19bb58m {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.form-select:focus {
  border-color: #b6c4ce;
  outline: unset;
  box-shadow: unset;
}

.css-1jqq78o-placeholder {
  font-size: 1rem !important;
  white-space: nowrap;
}

.select-icon.form-select {
  border-radius: 4px;
}

.map {
  width: 65%;
  height: 31.25rem;
}

.lc-location {
  font-size: 1.25rem;
  font-weight: 600;
  font-family: "poppins";
}

.commontable {
  position: absolute !important;
  top: -12%;
  left: -4%;
}

.mt-46 {
  margin-top: 6.125rem !important;
}

input:focus-visible {
  outline: none;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  border-color: transparent !important;
  box-shadow: transparent;
}

.input-245 {
  width: 16.875rem;
  height: 2.875rem;

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  opacity: 1;
  padding: 0.625rem;
}

.input-245::placeholder {
  font-size: 0.9375rem;
}

.width-63 .modal-content {
  width: 90.5rem;
  left: -9%;
  overflow: hidden;
}

.width-63 {
  left: -12% !important;
}

.creat-m-13 {
  margin-bottom: 0.8125rem;
}

.creat-m-31 {
  margin-bottom: 0.875rem;
}

.creat-m-33 {
  margin-bottom: 2.0625rem;
}

.m245 {
  margin-top: 4.3% !important;
}

.desktop_justify {
  justify-content: flex-start;
}

.therapist-color-1 {
  background-color: #dcf0ff !important;
}

.therapist-color-2 {
  background-color: #e8e6ff !important;
}

.therapist-color-3 {
  background-color: #fdeddc !important;
}

.therapist-color-4 {
  background-color: #d9f8dc !important;
}

.therapist-color-5 {
  background-color: #f8d9d9 !important;
}

.chatLoader .wrap {
  z-index: 14;
}

.otpScreen .wrap {
  z-index: 1200;
}

.padding-chat-user {
  padding: 20px 10px;
  padding-left: 36px;
}

.acc-lable-sidebar {
  width: 100%;
  text-align: left;
  padding-left: 2.1875rem;
  margin-bottom: 1.5rem;
  font: normal normal normal 0.875rem/1.3125rem Poppins;
  font-size: 0.875rem;
  letter-spacing: 0px;
  color: #9ca1b0;
  margin-top: calc(30px - 16px);
  margin-bottom: 16px;
  text-transform: uppercase;
}

.menu_boxing span {
  background: #fdeddc 0% 0% no-repeat padding-box;
  border-radius: 16px;
  width: 49px;
  height: 32px;
  /* width: fit-content; */
  display: flex;
  text-align: center;

  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  margin: 0;
  color: #e71d1d;
  font: normal normal 600 16px/37px Poppins;
  letter-spacing: 0px;
  opacity: 1;
}

.menu_boxing.active span {
  color: #fff;

  background: #2a68d4 0% 0% no-repeat padding-box;
}

.menu_boxing p {
  margin: 0;
  margin-top: 9px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font: normal normal medium 16px/24px Poppins;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #7b7b7b;
}

.commen2 .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-top: none;
  border-left: none;
  border-right: none;
}

.menu_boxing.active,
.menu_boxing.active:focus,
.menu_boxing.active:hover {
  font-weight: 600;

  border-bottom: 3px solid #2a68d4 !important;
  color: #2a68d4 !important;
}

.menu_boxing.active:focus p,
.menu_boxing.active:hover p,
.menu_boxing.active p {
  font-weight: 600;

  color: #2a68d4 !important;
}

.commen2 .nav-tabs .nav-link {
  border-top: none;
  border-left: none;
  border-right: none;
}

select.for-btnc option {
  background: #fff;
  color: #0a365b;
  font-size: 13px;
  margin-bottom: 1.5rem;
}

select.for-btnc {
  appearance: none;
}

.EarliestAvailability {
  display: flex;
  flex-direction: inherit;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 24px;
  padding-right: 80px;
}

.EarliestAvailability label {
  width: fit-content !important;
}

.text-area-scroll::-webkit-scrollbar {
  display: none;
}

/* .td-switch .MuiSwitch-root
    {
        width: 3.75rem;
        height: 3.125rem;
        border-radius: 1.25rem;
    }
    .td-switch .MuiSwitch-thumb {
        width: 1.5625rem;
        height: 2.375rem;
    } */
/* / 17/08/22 end /

    / 22/08/22 strat /


    / 22/08/22 end /


    / nitika css end /

    / RESPONSIVE / */
@media (max-width: 2560px) {
  .list-2 {
    width: 100%;
    /* height: 9.625rem; */
  }

  .ca-body {
    background-position-x: 104%;
    background-position-y: 17.5rem;
  }

  .fields-1 {
    width: 17.1875rem;
  }

  .inputFields {
    width: 35.625rem;
  }

  .span-error {
    height: 2.125rem;
  }

  .right_side_from_chart {
    display: flex;
    justify-content: flex-end;
    text-align: center;
  }

  /* .right_side_parent {
        padding-left: 10%;
    } */
  /* .home-list .home-list-content h4 {
            letter-spacing: 0rem;
            color: #0a365b;
            font-size: 1.375rem;
            line-height: 1.5rem;
            font-family: "Poppins Semi Bold";
            margin-bottom: 1.625rem;
            flex-grow: 1;
        } */

  /* .tabs_user_type {
   
    } */
  .user-type-main {
    /* padding-right: 3.3125rem; */
    margin-top: 2%;
    /* margin-bottom: 1%; */
  }

  .d-width {
    width: 60% !important;
  }

  .commonhead2 {
    margin-left: 6rem !important;
    padding-right: 0rem;
    margin-top: -2%;
    display: flex;
    /* position: relative; */
    margin-bottom: 4%;
  }

  .home-list .home-list-content h4 {
    letter-spacing: 0rem;
    color: #0a365b;
    line-height: 1.5rem;
    font-family: "Poppins Semi Bold";
    margin-bottom: 1.125rem !important;
    /* flex-grow: 1; */
  }

  .home-list .home-list-icon {
    background-color: #fff;
    padding: 0.9375rem;
    max-width: 5.875rem;
    height: 5.875rem;
  }

  .sidebar_normal .inner_content_leftbar .menuItemss h5 {
    font-size: 1.6875rem;
    min-width: 11.4375rem;
    font-family: "poppins";
  }

  .sidebar_normal .inner_content_leftbar .menuItemss svg {
    width: 90%;
    height: 2.25rem;
  }

  .home-lists .title_home_list h3 {
    font-size: 1.375rem;
  }

  .sidebar_normal .inner_content_leftbar .menuItemss {
    margin-bottom: 1.875rem;
  }

  /* .sidebar_collapse .inner_content_leftbar svg {

        width: 100%;
        height: 1.9375rem;
    } */
  .ColRow2 {
    width: 20.5625rem;
    margin-left: 2.125rem;
  }

  .cvOpil {
    margin-top: 23%;
  }

  /* .w-65 .modal-content {
            position: relative;
            display: -ms-flexbox;
            display: flex;
            left: 20%;
            right: 20%;
            -ms-flex-direction: column;
            flex-direction: column;
            width: 63% !important;
            pointer-events: auto;
            background-color: #fff;
            background-clip: padding-box;
            border: 0.0625remsolidrgba(0,0,0,.2);
            border-radius: 4.8px;
            outline: 0;
        
        }  */
  .w-100 .modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    left: -1%;
    right: 20%;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100% !important;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.0625remsolidrgba (0, 0, 0, 0.2);
    border-radius: 4.8px;
    outline: 0;
  }

  /* .accordion-body{
        height: 18vh;
        padding: 0.25rem 66px;
        display: flex;
        justify-content: space-between;
    } */

  /* .Pdiv1 {
            height: 0rem !important;
            padding: 1.6875rem 143px;
            display: flex;
            justify-content: space-between;
        } */
  .Pdiv3 {
    margin-left: 7%;
  }

  .accordion-collapse.collapse.show {
    padding-bottom: 0.0625rem !important;
  }

  .addressBox {
    font-size: 1rem;
    color: #0a365b;
    font-weight: 600;
  }

  .td-h1:nth-child(2) {
    padding-top: 3% !important;
  }

  .td-h1:nth-child(3) {
    padding-top: 3% !important;
  }
}

@media (max-width: 1920px) {
  .start-19 {
    justify-content: flex-start !important;
  }

  .ca-body {
    background-position-x: 104%;
    background-position-y: 25.0625rem;
  }

  .td-h1:nth-child(2) {
    padding-top: 3% !important;
  }

  .td-h1:nth-child(3) {
    padding-top: 3% !important;
  }

  .fields-1 {
    width: 17.1875rem !important;
  }

  .inputFields {
    width: 35.625rem;
  }

  .home-list .home-list-icon svg {
    width: 3.25rem;
    height: 3.4375rem;
    object-fit: contain;
  }

  .sidebar_normal .inner_content_leftbar .menuItemss {
    margin-bottom: 1.875rem;
  }

  .sidebar_normal .inner_content_leftbar .menuItemss h5 {
    font-size: 1.375rem;
    min-width: 11.3125rem;
    font-family: normal normal medium 1.25rem/4.25rem Poppins;
  }

  /* .sidebar_collapse .inner_content_leftbar svg {
     
        width: 100%;
        height: 1.9375rem;
    } */
  .sidebar_normal img {
    width: 67%;
    padding-left: 1.625rem;
    object-fit: cover;
  }

  .sidebar_normal .menu_icon_slidein svg {
    width: 2.3125rem;
    height: auto;
    color: rgb(106, 64, 212) !important;
    padding-right: 0;
  }

  .cvOpil {
    margin-top: 28%;
  }

  .home-list .home-list-content h4 {
    letter-spacing: 0rem;
    color: #0a365b;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-family: "Poppins Semi Bold";
    margin-bottom: 0.1875rem !important;
    /* flex-grow: 1; */
  }

  .home-list .home-list-icon {
    background-color: #fff;
    padding: 0.9375rem;
    max-width: 5.875rem;
    height: 5.875rem;
  }

  textarea.discribe_text::placeholder {
    color: red;
  }

  /* .tabs_user_type {
       
    } */
  /* .user-type-main {
        padding-right: 7.5625rem;
        margin-top: 5%;
        margin-bottom: 1%;
    } */
  .commonhead2 {
    margin-left: 0.4375rem !important;
  }

  .d-width {
    width: 54% !important;
  }

  .home-list {
    padding: 1.125rem 18px 18px 18px !important;
    padding-bottom: 7% !important;
    width: 21.0625rem;
  }

  .home-lists .title_home_list h3 {
    font-size: 1.375rem;
  }

  .home-list .home-list-content span {
    margin-top: 0.625rem;
    margin-bottom: 0rem;
  }

  .f-field {
    width: 35.625rem;
  }

  .span-error {
    height: 2.0625rem;
    padding: 0.0625rem;
  }

  .span-error2 {
    height: 1.75rem;
    padding: 0.0625rem;
  }

  .right_side_parent {
    padding-left: 14%;
  }

  /* .span-error3
        {
            position: absolute;
        } */
}

@media (max-width: 1440px) {
  .Patient-calender .fc-col-header-cell-cushion {
    width: 30px !important;
    line-height: 18px !important;
  }

  #controlled-Therapist-profiles-tab-credentials.nav-link.active {
    height: 100% !important;
  }

  .table_left_padding {
    padding: 0 0 0 10px;
  }

  .my-p-0 {
    padding: 0rem !important;
    margin: 0.625rem;
  }

  .ca-body {
    background-image: url(../src/images/bglogo.png);
    background-repeat: no-repeat;
    background-size: 23.75rem;
    background-position-x: 100%;
    background-position-y: 17.8125rem;
    min-height: 100vh;
  }

  .cred-input {
    min-width: 15.625rem !important;
  }

  .therapist-credentials {
    min-width: 41%;
  }
}

@media (max-width: 75rem) {
  .file-upload-wrapper {
    min-width: 50% !important;
  }

  .cred-date {
    min-width: 50%;
  }

  .therapist-credentials {
    min-width: 100%;
  }

  .cred-input {
    min-width: 50%;
  }

  .cred-sign {
    min-width: 100%;
  }

  /* .my-input-100 {
    min-width: 100% !important;
  } */

  .respo {
    margin-left: 1%;
  }

  .w-75 {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
  }

  .ca-body {
    background-image: none;
  }

  .map {
    width: 100%;
    height: 31.25rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .top-link {
    font-size: 0.8125rem !important;
  }
}

@media (max-width: 49.9375rem) {
  .last-btn {
    width: 100% !important;
  }

  .my-head {
    justify-content: space-between !important;
    min-width: 100% !important;
  }

  .table-container-1 {
    padding-left: 2.3125rem !important;
    padding-right: 2rem !important;
  }
}

@media screen and (max-height: 43.75rem) {
  .mainBarr {
    margin-bottom: -1%;
  }

  .sidebar_normal .inner_content_leftbar .menuItemss {
    margin-bottom: 0rem !important;
  }

  .sidebar_normal .inner_content_leftbar .menuItemss h5 {
    font-size: 1.125rem;
    min-width: 11rem;
    font-family: normal normal medium 1.25rem/68px Poppins;
  }

  .sidebar_normal .inner_content_leftbar .profileIcon2 {
    width: 5.875rem;
    /* height: 5.875rem; */
  }
}

@media screen and (max-height: 54.4375rem) {
  .sidebar_normal .inner_content_leftbar .menuItemss {
    margin-bottom: 0.625rem;
  }
}

@media screen and (max-height: 41.625rem) {
  .sidebar_normal .inner_content_leftbar .menuItemss h5 {
    font-size: 0.9375rem;
  }

  .sidebar_normal .inner_content_leftbar .menuItemss {
    margin-bottom: 0.625rem;
    padding: 0.5625rem 30px;
  }

  .sidebar_normal .inner_content_leftbar .profileIcon2 {
    width: 3.75rem;
    /* height: 3.5625rem; */
  }
}

@media (min-width: 36rem) {
  .modal-dialog {
    max-width: 55.5rem;
    margin: 28px auto;
  }
}

@media screen and (max-height: 37.5rem) {
  .sidebar_normal .inner_content_leftbar .menuItemss h5 {
    font-size: 0.9375rem;
  }

  .sidebar_normal .inner_content_leftbar .menuItemss {
    margin-bottom: 0.625rem;
    padding: 0.5625rem 30px;
  }

  .sidebar_normal .inner_content_leftbar .profileIcon2 {
    width: 3.75rem;
    /* height: 3.5625rem; */
  }
}

@media (max-width: 1024px) {
  .left_side_form_parent {
    padding-left: 0px;
  }
}

@media (max-width: 1083px) {
  .Functional_Mobility_table .table-responsive {
    display: block !important;
  }
}

@media (max-width: 767.9808px) {
  .mobile_justify-start {
    justify-content: flex-start !important;
  }

  .col-lg-6 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}

@media (max-width: 61.9988rem) {
  .login-back {
    object-fit: cover;
    width: 100%;
  }

  .inside_table_tabs .user-table-main tbody tr td .user-image p {
    font-size: 0.75rem;
  }
}

@media (max-width: 108.75rem) {
  .home-list {
    width: 18.75rem;
  }
}

@media (max-width: 97rem) {
  .home-list {
    width: 17.0625rem;
  }
}

@media (max-width: 90.6238rem) {
  .login-logo {
    max-width: 15.625rem;
    width: 100%;
    max-height: 8.9375rem;
    height: 100%;
    margin-bottom: 3.125rem;
  }

  .right_side_from_chart ul {
    display: -webkit-box;
  }

  .right_side_from_chart ul li {
    margin-top: 5%;
  }

  .home-user-type.active .home-list-content h4 {
    font-size: 0.9375rem;
  }

  .inside_table_tabs .user-table-main tbody tr td .user-image p {
    font-size: 0.875rem;
  }

  .home-list {
    width: 16.8125rem;
  }
}

@media (max-width: 86.875rem) {
  .home-list {
    width: 14.6875rem;
  }
}

@media (min-width: 75rem) and (max-width: 1599.98px) {

  /* .sidebar_normal {
        width: 16.5%;
    } */
  .inside_table_tabs .user-table-main tbody tr td .user-image p {
    font-size: 0.8125rem;
  }

  /* .home-list .home-list-content h4 {
        font-size: 0.875rem;
        line-height: 1.1rem;
    } */
  .home-list .home-list-content {
    margin-left: 1.25rem;
  }

  /* .home-list .home-list-content span {
        font-size: 1.875rem;
    } */
  /* .home-list .home-list-icon {
        width: 3.75rem;
        height: 3.75rem;
    } */
  .home-list {
    padding: 0.75rem 10px;
  }

  .right_side_from_chart ul {
    padding: 0rem;
  }

  .right_side_from_chart ul li .froms_side_rigth button.view-btn {
    margin: 0rem 8px 0px 0px;
    padding: 0.1875rem 15px;
  }
}

.input-group .form-control:focus {
  box-shadow: unset !important;
  border: 1px solid #ced4da !important;
  border-left: 0 !important;
}

.switch {
  --inactive-bg: #cfd8dc;
  --active-bg: #00e676;
  --size: 32px;
  appearance: none;
  width: calc(var(--size) * 2.2);
  height: var(--size);
  display: inline-block;
  border-radius: calc(var(--size) / 2);
  cursor: pointer;
  background-color: var(--inactive-bg);
  background-image: radial-gradient(circle calc(var(--size) / 2.1),
      #fff 100%,
      #0000 0),
    radial-gradient(circle calc(var(--size) / 1.5), #0003 0%, #0000 100%);
  background-repeat: no-repeat;
  background-position: calc(var(--size) / -1.75) 0;
  transition: background 0.2s ease-out;
}

.switch:checked {
  background-color: var(--active-bg);
  background-position: calc(var(--size) / 1.75) 0;
}

.agency-width-75 {
  width: 91%;
}

.care-blue {
  color: #0a365b !important;
}

.agency-reject-gap-40 {
  gap: 30%;
}

/* Preeti css  23/08/22*/

.icon {
  margin-right: 1.875rem;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1%;
}

.check-btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 149px;
  height: 46px;
  border-radius: 100px;
  border: 0;
  background: linear-gradient(299deg, #6a40d4 0%, #0f7ad4 100%);
  padding: 7px 6px;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.p1 {
  text-align: center;
}

.icon-call {
  top: 12.9375rem;
  width: 2.375rem;
  height: 2.375rem;
  padding: 0.4375rem;
  border-radius: 1.25rem;
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
}

.div-block-assistant {
  display: block;
}

.btn1 {
  color: white;
  top: 8.5625rem;
  left: 98.25rem;
  width: 9.3125rem;
  height: 2.875rem;
  margin-right: 1.5rem;
  background: transparent linear-gradient(299deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
}

.btn2 {
  color: white !important;
  width: 8.375rem;
  height: 2.875rem;
  background: #e71d1d 0% 0% no-repeat padding-box !important;
  border-radius: 5.375rem;
  opacity: 1;
}

.agency-btn {
  width: 9.3125rem !important;
  height: 2.875rem !important;
}

.check-icon {
  margin-left: -1.625rem;
  margin-right: 0.6875rem;
  padding: 0.3125rem;
  width: 2.0625rem;
  height: 2.0625rem;
  color: #0a365b;
  background-color: white;
  border-radius: 0.9375rem;
}

.icon-check {
  margin-right: 1.5625rem !important;
  margin-left: 0rem !important;
}

.cross-icon {
  margin-left: -1.625rem;
  margin-right: 0.6875rem;
  padding: 0.3125rem;
  width: 1.875rem;
  height: 1.875rem;
  color: #db0f0f;
  background-color: white;
  border-radius: 0.9375rem;
}

.credential-div {
  display: flex;
}

.address-div {
  display: flex;
}

.title {
  font-weight: bold;
  font-size: 1.875rem;
}

.details {
  text-align: left;
  /* font: normal normal normal 1rem/24px Poppins; */
  font-family: "poppins" sans-serif;
  font-size: 1rem;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.heading-uppercase {
  color: #a1aeb9;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.5rem;
}

.agency-mail-heading,
.document-title {
  font-weight: 800;
  color: #0a365b;
  margin-top: 0.875rem;
}

.mail-heading {
  color: #0f7ad4;
  font-weight: 800;
  margin-top: 0.875rem;
}

.mail-details {
  margin-right: 24%;
}

.margin-rignt-12 {
  margin-right: 14% !important;
}

/* .swipe-btn{
    width: "25%"!important;

    } */
.navbar-toggler:focus {
  box-shadow: none;
}

.active {
  text-align: left;
  font: normal normal 600 1.125rem/27px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.125rem;
}

.nav-link {
  text-align: left;
  font: normal normal 600 1.125rem/27px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.125rem;
}

.active-ca {
  text-align: left;
  letter-spacing: 0rem;
  color: #0a365b !important;
  opacity: 1;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.125rem;
}

.table-background {
  box-shadow: 0rem 3px 4px #00000029;
  border-radius: 1.25rem;
  border: 0.0625rem solid #eaebeb;
  border-radius: 0.625rem;
}

.table {
  margin-bottom: 0% !important;
}

.labels {
  margin-top: 1rem;
  color: #808d98;
  font-size: 1.125rem;
  font-weight: 800;
  justify-content: space-between;
}

.agency-user-type.label-heading.active {
  font-weight: 800;
  border-bottom: 0.125rem solid #0f7ad4;
  margin-bottom: 0.625rem;
  color: #0f7ad4;
  cursor: pointer;
}

.parent-div-space {
  justify-content: space-between;
}

.rates-physical-therapy {
  margin: 1.8125rem 0px;
  font-family: "Poppins Medium";
  color: #0a365b;
}

.documents-btn {
  padding: 1.1875rem;
  border: 0.1875rem solid #cfdde8;
  border-radius: 0.625rem;
  width: 100%;
}

.icon-background {
  margin-right: 0.6875rem;
  border-radius: 2.5625rem;
  padding: 0.9375rem;
  background: #d2e4f3;
}

.document-icon {
  border-radius: 5.375rem;
  opacity: 1;
}

.plus-icon {
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  padding: 0.625rem 13px;
  border-radius: 1.5625rem;
}

.div3 {
  margin-top: 0.625rem;
}

.div4 {
  margin-top: 1.5625rem;
}

.profile-heading {
  margin-bottom: 0.1875rem;
}

.therapist-details {
  margin-top: 0.625rem;
}

.therapist-details p {
  margin-bottom: 0.25rem;
  margin-left: 0.9375rem;
}

.therapist-question {
  margin: 1.6875rem 0px;
}

.therapist-question-heading {
  text-align: left;
  font: normal normal 600 1.5rem/35px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  padding-bottom: 0.8125rem;
}

.checkbox-options {
  color: #a1aeb9;
  font-size: 1rem;
}

.largerCheckbox {
  width: 1.5625rem;
  height: 1.5625rem;
}

.checkbox-main-div div {
  width: 16%;
}

/* / /24 -Gursimran/ / */

.blockApprovebtn {
  padding: 6px 7px;
  padding-right: 4%;
  margin-top: 1.375rem;
  width: 9.3125rem;
  height: 2.875rem;
  border-radius: 3.125rem;
  border-width: 0rem;
  background-image: linear-gradient(to right, #0f7ad4, #6a40d4);
  color: white;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.blocklisth5 {
  color: #0a365b;
  font-weight: 800;
  font-size: 1.5rem;
}

.blocklist_p {
  color: #0a365b;
  /* / font-weight: 800; / */
  font-size: 1rem;
}

.Reason_h5 {
  color: #0a365b;
  font-weight: 700;
  font-size: 1.25rem;
}

.Reason_p {
  text-align: left;
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #808d98;
  opacity: 1;
}

.checkicon {
  margin-right: 0.9375rem;
}

.closeicon {
  margin: 0.625rem;
  cursor: pointer;
  opacity: 35%;
}

.closeicon_div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.blockUserIcon {
  font-size: 6.875rem;
  color: #4359d4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1.75rem;
}

.view-btn.active.admin-user {
  width: 16.875rem;
  height: 2.875rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  justify-content: flex-start;
  padding: 0.5rem 0.25rem 0.5rem 0.5rem;
  color: #fff !important;
  font-weight: 600;
}

/* tanu? */

.Referral-info {
  display: flex;
  min-width: 25%;
  align-items: center;
  justify-content: space-evenly;
}

.block-err0r {
  width: 494px;
}

textarea:focus-visible {
  outline: none;
}

.referral_icon {
  margin-top: 6px;
  width: 2.5rem;
  height: 2.1875rem;
  margin-right: 0.25rem;
  /* background: #0a365b 0% 0% no-repeat padding-box; */
  opacity: 1;
}

.referral_name {
  margin: 0;
  /* margin-bottom: 8px; */
  /* width: 10.5rem;  sagar */
  top: 28.4375rem;
  left: 22.5625rem;
  /* height: 1.4375rem; */
  text-align: left;
  font: normal normal normal 0.75rem/1.5rem Poppins;
  font-size: 0.75rem;
  letter-spacing: 0rem;
  color: #a1aeb9;
  text-transform: uppercase;
  opacity: 1;
}

.form-check-input[type="radio"] {
  width: 2.25rem;
  height: 2.25rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 5.375rem;
  opacity: 1;
  margin-bottom: 0.125rem;
  margin-right: 0.625rem;
  margin-top: 0rem !important;
  font-size: 1rem;
}

.checkbox-w {
  width: 2.25rem;
  height: 3.875rem;
  border-radius: 0.25rem;
}

.form-check-label {
  padding-top: 10%;
  margin-left: 1.375rem;
}

.referral_value {
  text-align: left;
  font: normal normal normal 1rem/1.5rem Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  font-weight: normal;
}

.Referral-info {
  display: flex;
  /* margin-top: 1.875rem; */
  justify-content: flex-start;
  align-items: center;
}

.referral_info {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
}

.referral_icon img {
  min-width: 33.2px;
  width: 100%;
  display: flex;
  /* margin: 0px auto !important; */
}

.Referral-info_block {
  padding: "0.625rem 150px 10px 40px";
  display: "flex";
  border: "0.125rem solid red";
}

p.p1,
p.p2 {
  margin-left: 11%;
  width: 3.625rem;
  /* color: #fff; */
  height: 3.625rem;
  /* padding: 0.9375rem; */
  border-radius: 50%;
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Call,
.Msg {
  color: black;
  font-size: 1rem;
}

.defaultBtn {
  border-bottom: 0.0625rem solid #dfeffc;
  padding: 1.25rem 25px;
  background-color: transparent;
  border-width: 0rem;
  border-bottom-width: 0.0625rem;
}

.therapistBtn.active {
  background: transparent linear-gradient(326deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 0.5rem;
  opacity: 1;
  border: none;
  padding: 14px 25px;
  color: #fff !important;
  border-bottom: none;
}

.labels {
  margin-top: 1rem;
  color: #808d98;
  font-size: 1.125rem;
  font-weight: 800;
  justify-content: space-between;
  border-bottom: 0.125rem solid #e4ecf2;
}

.label-heading {
  cursor: pointer;
}

.parent-div-space {
  justify-content: space-between;
}

.rates-physical-therapy {
  text-align: left;
  font-size: 1rem;
  /* font-weight: 700; */
  font-family: normal normal medium 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  margin: 1.0625rem 0px !important;
}

.loglist {
  margin-top: 1.375rem !important;
  margin-bottom: -0.375rem !important;
}

tr.therapist_heading {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #a1aeb9;
  text-transform: uppercase;
  opacity: 1;
}

.tableImg {
  width: 4.25rem;
  border-radius: 50%;
  height: 4.25rem;
  margin-right: 0.625rem;
}

.customModalparent .modal-dialog {
  max-width: 90%;
}

p.tableName {
  text-align: left;
  font: normal normal 600 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  margin-bottom: 0;
}

p.tableNo {
  text-align: left;
  font: normal normal medium 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #a1aeb9;
  opacity: 1;
}

p.tableDiscipline {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  width: 1.125rem;
  height: 1.4375rem;
}

p.tableExp {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  width: 3.875rem;
  height: 1.4375rem;
}

p.tableSpec {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  width: 1.625rem;
  height: 1.4375rem;
}

p.tableLang {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  width: 7.9375rem;
  height: 1.4375rem;
}

p.tableRates {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  width: 1.875rem;
  height: 1.4375rem;
}

p.tableStatusText {
  color: #e71d1d;
  background: #fddcdc 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  width: 12rem;
  height: 2.5rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* td.tableApprovbox {
    background: #FDEDDC 0% 0% no-repeat padding-box;
    border-radius: 1.125rem;
    opacity: 1;
    border: 0.0625rem solid red;
    } */

p.tableApprovText {
  text-align: center;
  border-radius: 1.0625rem;
  padding: 0.125rem;
  color: #ca7718;
  background: #fdeddc;
  font-size: 1rem;
}

.Approvbutton {
  text-align: center;
  border-radius: 1.0625rem;
  color: #ca7718;
  background: #fdeddc;
  width: 11.125rem;
  height: 2.4375rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

p.p6 {
  text-align: center;
  color: #a1aeb9;
  font-size: 1rem;
}

.div1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 9px 10px #00000029;
  opacity: 1;
  padding: 3.125rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
}

.customModalparent .accordion-item {
  width: 95%;
  margin: 0 auto;
  background-color: #f1f5f8;
  margin-bottom: 1.875rem;
  border: none;
}

.patientDetailAcc .accordion-item {
  width: 95%;
  margin: 0 auto;
  background-color: #f1f5f8;
  margin-bottom: 1.875rem;
  border: none;
}

.accordion-collapse.collapse.show {
  padding-bottom: 3.125rem;
}

.accordion-button {
  background: transparent;
  text-align: left;
  font: normal normal 600 1.5rem/35px Poppins;
  letter-spacing: 0rem;
  color: #0f7ad4;
  opacity: 1;
}

.accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}

.customRates {
  padding: 0 1.25rem;
}

.parent-div-space {
  margin-top: 0.625rem;
  /* padding: 0 1.25rem; */
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: transparent;
  box-shadow: none;
}

.accordion-body {
  padding: 0rem;
}

.patientDetailAcc .accordion-button:not(.collapsed) {
  color: #0f7ad4;
  background-color: transparent;
  box-shadow: none;
}

.documentaionTable table {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.documentaionTable thead tr {
  border: none;
}

.documentaionTable thead tr:nth-child(1) {
  border: none;
}

.documentaionTable table tbody tr {
  border: 0.125rem solid #dee2e6;
  border-top: 0.125rem solid #dee2e6 !important;
  outline: 0.125rem solid #dee2e6;
}

.documentaionTable table tbody tr td,
.documentaionTable .table thead th,
.documentaionTable .table thead tr {
  border: none;
}

.documentaionTable .table thead tr th {
  padding: 0;
}

.documentaionTable table tbody tr td:nth-child(1) {
  border-left: 0.125rem solid #dee2e6 ;
}

.documentaionTable table tbody tr td:nth-last-child(1) {
  border-right: 0.125rem solid #dee2e6 ;
}

.patientDoc p {
  text-align: left;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0rem;
  color: #0a365b;
  font-family: "Poppins Medium";
  color: #0a365b;
}

.caseOpenbtn {
  background: #d9f8dc 0% 0% no-repeat padding-box;
  border-radius: 1.125rem;
  opacity: 1;
  padding: 0.3125rem 50px;
  border: none;
  color: #17a325;
  font-size: 0.875rem;
}

.iframeBox iframe {
  width: 100%;
  margin-bottom: 3.25rem;
  height: 27vh;
}

.iframeBox {
  margin: 0.625rem;
  width: 100%;
}

.iframeBox iframe {
  max-width: 100%;
}

.icon {
  margin-right: 1.875rem;
}

.p1 {
  text-align: center;
}

.icon-call {
  top: 12.9375rem;
  width: 2.375rem;
  height: 2.375rem;
  padding: 0.4375rem;
  border-radius: 1.25rem;
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
}

.div-block-assistant {
  display: block;
}

.btn1 {
  color: white;
  top: 8.5625rem;
  left: 98.25rem;
  width: 9.3125rem;
  height: 2.875rem;
  margin-right: 1.5rem;
  background: transparent linear-gradient(299deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
}

.btn2 {
  color: white;
  width: 8.375rem;
  height: 2.875rem;
  background: #e71d1d 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
}

.check-icon {
  margin-left: -1.625rem;
  margin-right: 0.6875rem;
  padding: 0.3125rem;
  width: 2.0625rem;
  height: 2.0625rem;
  color: #0a365b;
  background-color: white;
  border-radius: 0.9375rem;
}

.cross-icon {
  margin-left: -1.625rem;
  margin-right: 0.6875rem;
  padding: 0.3125rem;
  width: 1.875rem;
  height: 1.875rem;
  color: #db0f0f;
  background-color: white;
  border-radius: 0.9375rem;
}

.credential-div {
  display: flex;
}

.address-div {
  display: flex;
}

.title {
  font-weight: bold;
}

.details {
  text-align: left;
  /* font: normal normal normal 1rem/24px Poppins; */
  font-family: "poppins" sans-serif;
  font-size: 1rem;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.heading-uppercase {
  color: #a1aeb9;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.5rem;
}

.agency-mail-heading,
.document-title {
  font-weight: 800;
  color: #0a365b;
  margin-top: 0.875rem;
}

.mail-heading {
  color: #0f7ad4;
  font-weight: 800;
  margin-top: 0.875rem;
}

.mail-details {
  margin-right: 24%;
}

/* .swipe-btn{
    width: "25%"!important;

    } */

.table-background {
  box-shadow: 0rem 3px 4px #00000029;
  border-radius: 1.25rem;
  border: 0.0625rem solid #eaebeb;
  border-radius: 0.625rem;
}

.table {
  margin-bottom: 0% !important;
}

.labels {
  margin-top: 1rem;
  color: #808d98;
  font-size: 1.125rem;
  font-weight: 800;
  justify-content: space-between;
}

.agency-user-type.label-heading.active {
  font-weight: 800;
  border-bottom: 0.125rem solid #0f7ad4;
  margin-bottom: 0.625rem;
  color: #0f7ad4;
  cursor: pointer;
}

.parent-div-space {
  justify-content: space-between;
}

.rates-physical-therapy {
  margin: 1.25rem 0px;
}

.documents-btn {
  /* margin: 1.125rem 0px; */
  padding: 1.1875rem;
  border: 0.1875rem solid #cfdde8;
  border-radius: 0.625rem;
  width: 100%;
}

.icon-background {
  margin-right: 0.6875rem;
  border-radius: 2.5625rem;
  padding: 0.9375rem;
  background: #d2e4f3;
}

.document-icon {
  border-radius: 5.375rem;
  opacity: 1;
}

.plus-icon {
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  padding: 0.625rem 13px;
  border-radius: 1.5625rem;
}

.div3 {
  margin-top: 0.625rem;
}

.div4 {
  margin-top: 1.5625rem;
}

.profile-heading {
  margin-bottom: 0.1875rem;
}

.therapist-details {
  margin-top: 0.625rem;
}

.therapist-details p {
  margin-bottom: 0.25rem;
  margin-left: 0.9375rem;
}

.therapist-question {
  margin: 1.6875rem 0px;
}

.therapist-question-heading {
  text-align: left;
  font: normal normal 600 1.5rem/35px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  padding-bottom: 0.8125rem;
}

.checkbox-options {
  color: #a1aeb9;
  font-size: 1rem;
}

.largerCheckbox {
  width: 1.5625rem;
  height: 1.5625rem;
}

.checkbox-main-div div {
  width: 16%;
}

.icon {
  margin-right: 1.875rem;
}

.p1 {
  text-align: center;
}

.icon-call {
  top: 12.9375rem;
  width: 2.375rem;
  height: 2.375rem;
  padding: 0.4375rem;
  border-radius: 1.25rem;
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
}

.patient_chat-call {
  width: fit-content;
  color: #fff;
  display: flex;
  padding: 10px 20px;
  border-radius: 100px;
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.icon-center {
  color: #4359d4;
  margin-right: 1rem;
}

.div-block-assistant {
  display: block;
}

.btn1 {
  color: white;
  top: 8.5625rem;
  left: 98.25rem;
  width: 9.3125rem;
  height: 2.875rem;
  margin-right: 1.5rem;
  background: transparent linear-gradient(299deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  border: none;
}

.btn2 {
  color: white;
  width: 8.375rem;
  height: 2.875rem;
  background: #e71d1d 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  border: none;
}

.check-icon {
  margin-left: -1.625rem;
  margin-right: 0.6875rem;
  padding: 0.3125rem;
  width: 2.125rem;
  height: 2.125rem;
  color: #0a365b;
  background-color: white;
  border-radius: 100%;
}

.cross-icon {
  margin-left: -2.625rem;
  margin-right: 0.6875rem;
  padding: 0.3125rem;
  width: 2.125rem;
  height: 2.125rem;
  color: #db0f0f;
  background-color: white;
  border-radius: 100%;
}

.credential-div {
  display: flex;
}

.address-div {
  display: flex;
}

.title {
  font-weight: bold;
  color: #0a365b;
}

.details {
  text-align: left;
  /* font: normal normal normal 1rem/24px Poppins; */
  font-family: "poppins" sans-serif;
  font-size: 1rem;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.heading-uppercase {
  color: #a1aeb9;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5rem;
  margin: 0;
}

.agency-mail-heading,
.document-title {
  font-weight: 800;
  color: #0a365b;
  margin-top: 0.875rem;
}

.mail-heading {
  color: #0f7ad4;
  font-weight: 800;
  margin-top: 0.875rem;
}

.mail-details {
  margin-right: 24%;
}

/* .swipe-btn{
    width: "25%"!important;

    } */

.table-background {
  box-shadow: 0rem 3px 4px #00000029;
  border-radius: 1.25rem;
  border: 0.0625rem solid #eaebeb;
  border-radius: 0.625rem;
}

.table {
  margin-bottom: 0% !important;
}

.labels {
  margin-top: 1rem;
  color: #808d98;
  font-size: 1.125rem;
  font-weight: 800;
  justify-content: space-between;
  margin-top: 1.875rem;
}

.label-heading {
  cursor: pointer;
}

.agency-user-type.label-heading.active {
  font-weight: 800;
  border-bottom: 0.125rem solid #0f7ad4;
  margin-bottom: 0rem;
  cursor: pointer;
}

.agency-user-type-notactive {
  cursor: pointer;
}

.parent-div-space {
  justify-content: space-between;
}

.rates-box {
  margin-top: 1.125rem;
  border: 0.0625rem solid #b6c4ce;
  padding: 0.25rem;
  width: 21.875rem;
  height: 2.875rem;
  border-radius: 0.25rem;
  /* sagar width */
}

.documents-btn {
  padding: 1.1875rem;
  border: 0.1875rem solid #cfdde8;
  border-radius: 0.625rem;
  width: 100%;
}

.icon-background {
  margin-right: 0.6875rem;
  border-radius: 2.5625rem;
  padding: 0.9375rem;
  background: #d2e4f3;
}

.document-icon {
  border-radius: 5.375rem;
  opacity: 1;
}

.card-bottom {
  margin-bottom: 0px;
  margin-top: 0.25rem;
  font-size: 0.9375rem;
  color: #0a365b;
}

.plus-icon {
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  padding: 0.625rem 13px;
  border-radius: 1.5625rem;
}

.div4 {
  margin-top: 1.5625rem;
}

.addressBox {
  margin-bottom: 2.5rem;
  margin-left: 0.625rem;
}

.addressBox p:first-child {
  margin-bottom: 0.5rem;
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #a1aeb9;
  text-transform: uppercase;
  opacity: 1;
}

.addressBox p:last-child {
  color: #0a365b;
  margin-bottom: 0.5rem;
}

.therapist-details {
  margin-top: 0.625rem;
}

.therapist-details p {
  margin-bottom: 0.25rem;
  margin-left: 0.9375rem;
}

.therapist-question {
  margin: 1.6875rem 0px;
}

.therapist-question-heading {
  text-align: left;
  font: normal normal 600 1.5rem/35px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  padding-bottom: 0.8125rem;
}

.checkbox-options {
  color: #a1aeb9;
  font-size: 1rem;
}

.largerCheckbox {
  width: 1.5625rem;
  height: 1.5625rem;
}

.checkbox-main-div div {
  width: 16%;
}

.healthVisit {
  padding: 0 3.125rem;
}

.healthVisit h4 {
  font-size: 1.5rem;
  color: #0a365b;
  font-family: "Poppins Semi Bold";
}

.homeHealth {
  margin-bottom: 3.125rem;
}

.homeHealth div {
  width: 25%;
  display: flex;
  align-items: center;
}

.homeHealth div input {
  margin-right: 0.625rem;
}

.homeHealth label {
  margin: 0rem;
}

.menuItemss-1 {
  cursor: pointer;
  width: 90% !important;
  padding: 0.625rem 0.625rem 0.6875rem 1.75rem;
  gap: 0.8125rem !important;
  margin-bottom: 5%;
  margin: auto;
}

.menuItemss-1.activeee {
  display: flex;
  justify-content: flex-start;
  background: linear-gradient(90deg,
      rgba(15, 122, 212, 1) 8%,
      rgba(106, 64, 212, 1) 98%) !important;
  cursor: pointer;
  display: flex;
  cursor: pointer;
  height: 3.25rem;
  width: 90% !important;
  gap: 0.8125rem !important;
  border-radius: 0.375rem;
  transition: 0.4s width ease-in-out;
  padding: 0.625rem 0.625rem 0.6875rem 1.75rem;
  color: #fff !important;
  position: relative;
  display: block;
  margin: auto;
  align-items: center;
}

.menuItemss-1.activeee svg {
  width: 102%;
}

.menuItemss-1tab {
  position: relative;
  width: 90%;
}

.menuItemss-1tab.activeee::before {
  content: "";
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -14px;
  border-radius: 0px 4px 4px 0px;
  background: linear-gradient(90deg,
      rgba(15, 122, 212, 1) 8%,
      rgba(106, 64, 212, 1) 98%) !important;
}

.mainBarr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar_normal2 {
  height: auto !important;
}

.menuContainerr {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  height: 100%;
  margin-top: 1.75rem;
  display: flex;
}

.adminModall {
  display: flex;
  align-items: center;
  /* padding: 0 5%;
    margin-top: 3%;
    padding-left: 0rem; */
}

.adminModallCircle {
  height: 10.375rem;
  width: 10.375rem;
  border-radius: 50%;
  /* background-color: #f1f5f8; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18%;
}

.adminModall label {
  cursor: pointer;
  color: #0a365b;
  font-weight: bold;
  font-size: 0.875rem;
}

.adminModalParent {
  display: flex;
  justify-content: space-between;
}

.adminModalParent p {
  font-size: 1.5rem;
  color: #0a365b;
  font-weight: 700;
  margin-top: 1.25rem;
}

.adminModalDetails {
  width: 39.375rem;
  position: absolute;
  transform: translate(100%, 2%);
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.card-details {
  display: flex !important;
}

.gap-40 {
  gap: 2.5rem;
}

.box-step {
  width: 32.3125rem;
  position: relative;
  background: #e4f4ff 0% 0% no-repeat padding-box;
  border-radius: 0.75rem;
  height: 8.75rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.box-step h1 {
  text-align: center;
  font: normal normal 600 1.25rem/30px Poppins;
  letter-spacing: 0rem;
  color: #0f7ad4;
  opacity: 1;
}

.box-step i {
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-icon-1 {
  align-self: flex-end;
  /* align-items: self-start; */
  /* transform: translate(-0.625rem, -94px);
     */
  transform: translate(6.5rem, -98px);
}

.btn-dob {
  width: 6.0625rem;
  font-family: "Poppins";
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #a1aeb9;
  border-radius: 1.125rem;
  padding: 0.375rem;
  color: #a1aeb9;
  font-size: 0.9375rem !important;
}

.date_text svg {
  width: 1.5625rem;
  color: #c3d2de;
}

.table-container-2 {
  padding-left: 6.75rem !important;
  padding-right: 3.0625rem !important;
}

.table-container-3 {
  padding-left: 3.75rem !important;
  padding-right: 3.0625rem !important;
}

.searching {
  font-size: 1.25rem;
}

.border-bottom td {
  font-size: 1rem;
}

.input-width {
  width: 100%;
}

.sidebarwidth {
  width: 8.5%;
}

.col1-2 {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 13.666667%;
  max-width: 8.666667%;
}

.col1-3 {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  flex: 1 0 13.666667%;
  max-width: 22.666667%;
}

.col1-10 {
  flex: 2 0 83.333333%;
  max-width: 91.333333%;
}

.col2-10 {
  flex: 1 0 83.333333%;
  max-width: 86.333333%;
}

.card-top-img {
  width: 12.625rem;
}

.margin-bar {
  margin-bottom: 56px !important;
}

.rate-card-m {
  margin-bottom: 48px;
}

img.image_rounded {
  height: 165px;
  width: 165px;
}

.d-tharepist {
  text-align: left;
  font: normal normal 600 1.5rem/35px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.circel-add {
  width: 2.875rem;
  height: 2.875rem;
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 100%;
}

.plus-add {
  width: 100%;
  height: 100%;
  font-size: 1.6875rem;
  color: #fff;
  padding: 22%;
}

.date-ex {
  text-align: left !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  letter-spacing: 0rem !important;
  color: #0f7ad4 !important;
  opacity: 1 !important;
  font-weight: normal !important;
}

.license-ex {
  text-align: left !important;
  font-size: 1.125rem !important;
  letter-spacing: 0rem !important;
  color: #0a365b !important;
  opacity: 1 !important;
  font-family: "Poppins medium" !important;
  font-weight: normal !important;
}

.creat-m-31,
.creat-m-31::placeholder {
  font-size: 14px !important;
}

.agency-reject-w-50 {
  width: 50%;
}

.sidebar_normal {
  padding-right: 0rem;
}

.font-titel {
  text-align: left;
  font: normal normal 600 0.875rem/21px Poppins;
  letter-spacing: 0rem;
  color: #0f7ad4;
  text-transform: uppercase;
  opacity: 1;
}

.icon-center {
  font-size: 30px;
  min-width: 30px;
}

.details-p {
  text-align: left;
  font: normal normal normal 13px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.switch-p .data {
  padding: 0rem !important;
}

.switch-p .react-switch-bg {
  margin-left: 0rem !important;
}

.switch-tr {
  background: #f1f5f8 0% 0% no-repeat padding-box;
  box-shadow: 0rem 3px 4px #00000029;
  border: 0.0625rem solid #eaebeb;
  border-radius: 0.25rem;
  width: 68rem;
  height: 3.875rem;
}

.details-email {
  text-align: left;
  font: normal normal normal 1rem/25px Poppins !important;
  font-size: 1rem !important;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.switch-name {
  text-align: left;
  font: normal normal 600 0.9375rem/23px Poppins;
  letter-spacing: 0rem;
  color: #0f7ad4;
  opacity: 1;
}

.Seconday-tharepist {
  font-size: 1rem !important;
}

.email-h-p {
  padding-left: 12.8px;
}

/* Block modal */
.Block-modal .modal-dialog {
  max-width: 37.25rem;
}

.box-img-1 {
  width: 10.375rem;
  height: 10.3125rem;
  background: #f1f5f8 0% 0% no-repeat padding-box;
  border-radius: 12.5rem;
  opacity: 1;
}

.reject-input {
  max-width: 209.008px !important;
  max-height: 35.2px !important;
}

.card-1 {
  width: 23.125rem;
  height: 5.25rem;
}

.card-2 {
  width: 23.125rem;
  height: 7rem;
}

.AgencyModal .modal-dialog {
  max-width: 63.0625rem;
}

.padding-m-1 {
  padding: 16px;
  padding-left: 48px;
  padding-right: 48px;
}

.terms p {
  font-size: 1rem;
}

.top-box-profil {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 3px 10px #00000029;
  opacity: 1;
}

.my-profile1 {
  font: normal normal 600 1.5rem/35px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.my-profile {
  width: 166px;
  height: 165px;
}

.Pmargin-top-b {
  margin-top: 30px;
  margin-bottom: 33.008px;
}

.desktop_justify-end {
  justify-content: flex-end;
}

.ref-height-6 .css-13cymwt-control {
  height: 46px;
}

.profile-details-input {
  height: 46px !important;
}

.agency-name-p {
  font: normal normal 600 2rem/3rem Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  text-transform: capitalize;
}

.border-input-1 {
  border-radius: 0rem 4px 4px 0px !important;
}

.border-input-2 {
  border-radius: 0.25rem 0px 0px 4px !important;
}

/* tharepist tooltips */

.th-rate-tooltip-0 .tooltip-arrow {
  display: none !important;
}

.th-rate-tooltip-0 .tooltip-inner {
  background-color: #0f7ad4 !important;
  text-align: start !important;
  min-width: 24.25rem !important;

  padding: 0.9375rem 15px !important;
  font-size: 0.875rem !important;
  font-family: "Poppins" !important;
  letter-spacing: 0rem;
  line-height: 1.125rem;
}

.th-rate-tooltip-0 {
  /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
  transform: translate3d(170%, 190%, 0rem) !important;
}

.profileMargin-0.th-rate-tooltip-0 {
  /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
  transform: translate3d(320%, 170%, 0rem) !important;
}

/*  */
.th-rate-tooltip-2 .tooltip-arrow {
  display: none !important;
}

.th-rate-tooltip-2 .tooltip-inner {
  background-color: #0f7ad4 !important;
  text-align: start !important;
  min-width: 24.25rem !important;

  padding: 0.9375rem 15px !important;
  font-size: 0.875rem !important;
  font-family: "Poppins" !important;
  letter-spacing: 0rem;
  line-height: 1.125rem;
}

.th-rate-tooltip-2 {
  transform: translate3d(170%, 32.5rem, 0px) !important;
}

.profileMargin-2.th-rate-tooltip-2 {
  transform: translate3d(320%, 52rem, 0px) !important;
}

.bg-f1f5f8 {
  background-color: #f1f5f8 !important;
}

.exp-body {
  color: #808d98 !important;
}

/* campliance page */
.congratulation-box {
  width: 76.875rem;

  background: #e1ffe2 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #3bef42;
  border-radius: 0.25rem;
  opacity: 1;
  padding: 0.8125rem 23px;
  margin-top: 3.125rem;
}

.congratulation-box h4 {
  text-align: left;
  font: normal normal 600 1.25rem/30px Poppins;
  letter-spacing: 0rem;
  color: #37b42a;
  opacity: 1;
}

.congratulation-box p {
  text-align: left;
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #0ba711;
  opacity: 1;
}

.compliance-head {
  text-align: left;
  font: normal normal 600 2.25rem/55px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.compliance-margin {
  margin-top: 3.1875rem;
  margin-bottom: 1.9375rem;
}

.compliance-btn.btn1 {
  width: 7.5rem !important;
  display: flex;
  align-items: center;
  padding-left: 0.3125rem;
}

.compliance-btn .icon-check {
  margin-right: 0.625rem !important;
}

.terms.height {
  height: 15.625rem;
  width: 100%;
  padding-right: 2rem;
}

.right_side_parent .right_sec .form-group input {
  height: 2.875rem;
}

.right_side_parent .right_sec .input-error {
  width: 100%;
}

.right_side_parent .right_sec .profile-icon {
  width: 8.125rem;
  height: 8.125rem;
}

.right_side_parent .right_sec .upload-P {
  color: #0a365b;
  margin-bottom: 0;
}

.right_side_parent .right_sec .ad-input {
  width: 100%;
}

.right_side_parent .right_sec .py-input1 {
  width: 100%;
}

.top-cred {
  top: 5px;
}

.mobile_width-990 {
  width: 60%;
}

.desktop_width_25 {
  width: 25%;
}

/* staffing css */
.right_sec {
  padding: 48px;
}

.staffNewReferralMain h4 {
  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom: 16px;
}

.profile_sec {
  margin: 16px 0px;
}

.right_sec {
  padding: 48px;
  width: 60%;
}

.right_sec h2 {
  color: #0a365b;
  font-size: 1.625rem;
  font-weight: 600;
  margin-bottom: 16px;
  user-select: none;
}

.right_sec .col-md-12 {
  margin-bottom: 48px;
}

.right_sec label {
  width: 100%;
  margin-bottom: 0.8125rem;
  letter-spacing: 0rem;
  color: #0a365b;
  font-size: 1rem;
  line-height: 1.6875rem;
  font-family: "Poppins Semi Bold";
}

.radio_btns label {
  margin-bottom: 0 !important;
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #808d98;
  opacity: 1;
}

.radio_btns input {
  margin-right: 16px !important;
}

.radio_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.right_sec .css-6j8wv5-Input,
.css-1pndypt-Input {
  padding-left: 0.625rem !important;
}

.right_sec .css-6j8wv5-Input,
.css-1pndypt-Input {
  margin: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.right_sec .css-319lph-ValueContainer {
  padding: 0 !important;
}

.right_sec .css-14el2xx-placeholder {
  margin-left: 0.625rem !important;
  text-align: left;
  font: normal normal normal 16px/25px Poppins !important;
  letter-spacing: 0px !important;
  color: #808d98 !important;
  opacity: 1;
}

.right_sec .visit_sec .col-md-12 {
  margin-bottom: 0;
  padding-left: 0 !important;
}

.select-icon svg {
  display: none;
}

.select-icon span {
  display: none;
}

.select-icon-input select {
  padding: 0.625rem;
}

.secondarydignosisModalParent {
  display: flex;
  justify-content: space-between;
}

.secondarydignosisModal .modal-content {
  padding: 2em;
}

.secondarydignosisModal input {
  margin-right: 1rem;
}

.secondarydignosisModal label {
  color: #808d98;
  font-weight: 400;
  font-size: 1.1875rem;
}

.daigSaveBtn {
  margin-top: 16px;
}

.daigSaveBtn button {
  padding: 0.625rem 26px;
}

.secondarydignosisModalParent p {
  color: #0a365b;
  font-size: 1.625rem;
  font-weight: 600;
  margin-bottom: 16px;
  user-select: none;
}

.secondarydignosisModalParent svg {
  position: absolute;
  right: 0.25rem;
  top: 0.75rem;
}

.radio_btns .agen-reject-check {
  min-width: 1.875rem !important;
  min-height: 1.875rem !important;
  background: #0f7ad4 0% 0% no-repeat padding-box !important;
  border: 0.0625rem solid #0f7ad4 !important;
  border-radius: 0.25rem !important;
  opacity: 1 !important;
  margin-right: 0rem;
}

.css-b62m3t-container .css-1s2u09g-control {
  min-height: 2.875rem;
}

.radio_btns_modal {
  display: flex;
  align-items: center;
  margin: 32px 0;
}

.radio_btns_modal .agen-reject-check {
  min-width: 1.5625rem !important;
  min-height: 1.5625rem !important;
  background: #0f7ad4 0% 0% no-repeat padding-box !important;
  border: 0.0625rem solid #0f7ad4 !important;
  border-radius: 0.25rem !important;
  opacity: 1 !important;
  margin-right: 0rem;
}

.form_head {
  margin-top: 48px;
}

.secondarydignosisModal .radio_btns_modal label {
  margin-bottom: 0;
  margin-left: 0.625rem;
}

.div-heights {
  height: 80vh;
}

.background-text {
  font: normal normal medium 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  font-family: "Poppins Medium";
}

.progressbar {
  counter-reset: step;
}

.progressbar li {
  list-style: none;
  display: inline-block;
  width: 24.33%;
  position: relative;
  text-align: center;
  cursor: pointer;
  font: normal normal medium 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 3.125rem;
  height: 3.125rem;
  background-image: url("../src/images/check.svg");
  background-size: 100%;
  line-height: 1.875rem;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 0.625rem auto;
  background-color: #fff;
}

.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  background-color: #ddd;
  top: 1.5rem;
  left: -50%;
  z-index: -1;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active {
  color: #0ba711;
}

.progressbar li.active:before {
  background-image: url("../src/images/check02.svg");
  background-size: 100%;
}

.progressbar li.active+li:after {
  background-color: black;
  background-image: url("../src/images/check02.svg");
  background-size: 100%;
}

.padding-gap-profile {
  padding: 18px 15px !important;
  margin-bottom: 16px !important;
  gap: 16px !important;
}

.therepist-sidebar {
  height: 100% !important;
}

.agency-sidebar {
  height: 100% !important;
}

.margin-bar-sidebar {
  margin-top: 30.375rem !important;
}

.margin-bar-sidebar-open {
  margin-top: 24.375rem !important;
}

.margin-top-myside {
  margin-top: 77%;
}

/* /* new sttafig Reff */
.New-Staffing-1 {
  text-align: left;
  font: normal normal 600 2.25rem/55px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.new-staffral-inputbox {
  width: 50%;
  /* height: 100% !important; */
  /* width: 193px; */
  height: 2.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  opacity: 1;
  padding: 0.625rem;
}

.input-border-care {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #b6c4ce !important;
  border-radius: 4px !important;
  opacity: 1;
}

.option-color {
  text-align: left;
  font: normal normal medium 15px/24px Poppins;
  letter-spacing: 0px;
  color: #0a365b !important;
  opacity: 1;
}

.margin-top-31 {
  margin-top: 1.9375rem;
}

.new-staffral-inputbox {
  height: 46px !important;
}

.New-Staffing-input input {
  height: 46px;
}

.w-49 {
  width: 100% !important;
}

/* .New-Staffing-input input {
  width: 24.8125rem !important;
}
.New-Staffing-input {
  width: 24.8125rem;
  height: unset;
}
.New-Staffing-input1 {
  width: 11.8125rem !important;
}
.New-Staffing-input5 {
  width: 50.9375rem;
} */
.browse-f1 {
  font: normal normal normal 1rem/24px Poppins !important;
  letter-spacing: 0rem;
  color: #808d98 !important;
  opacity: 1;
}

/* / modal css /  */
.cancel_modal p {
  font-size: 1.5625rem;
  font-weight: 600;
  color: #0a365b;
}

.cancel_modal .mrn {
  font-weight: 400 !important;
}

.cancel_modal {
  text-align: center;
  padding: 16px 3rem;
}

.check_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
}

.check_btns .radio_btns {
  margin: 0 48px;
}

.can_check .custom-control-label::before {
  position: absolute;
  top: 0;
  left: -28.8px;
  display: block;
  width: 24px;
  height: 24px;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6;
}

.can_check .custom-control-label::after {
  position: absolute;
  top: 0;
  left: -28.8px;
  display: block;
  width: 24px;
  height: 24px;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.can_check .custom-control {
  position: relative;
  display: block;
  min-height: 24px;
  padding-left: 0;
}

.can_check .custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 0;
}

.can_check .radio_btns label {
  margin-bottom: 0 !important;
  color: #808d98;
  font-weight: 400;
  font-size: 0.9375rem;
  margin-left: 1.25rem;
}

.text_box {
  margin-top: 16px;
}

.offer.mx-5 h1 {
  color: #0a365b;
  font-weight: 600;
  font-size: 1.875rem;
}

.text_box label {
  color: #0a365b;
  font-weight: 600;
  font-size: 1.4375rem;
}

.submit_btn button {
  background: transparent linear-gradient(299deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  border: none;
  padding: 0.6875rem 28px;
  border-radius: 5rem;
}

.custom-control.custom-control-inline.no_padding_left {
  padding-left: 0;
}

.text_box {
  width: 100%;
}

ul>li::marker {
  list-style-position: inside;
}

.second>li {
  text-indent: 10px;
  line-height: auto;
}

.alphaNumeric {
  list-style: lower-alpha;
  list-style-position: inside;
}

table {
  border-collapse: collapse;
}

.taxt-form-page tr,
.taxt-form-page tr td,
.taxt-form-page tr th,
.ul-li-margin td {
  border: 1px solid #000;
  vertical-align: top;
  padding: 0 4px;
  line-height: 1rem;
}

.text_box input {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  padding: 0.625rem;
  height: 5.3125rem;
  vertical-align: baseline;
}

.submit_btn {
  margin: 0.9375rem 0;
  padding-left: 0;
}

.cancel_modal .radio_btns label {
  font-size: 1.25rem;
}

.new-staffing-btn {
  width: 14.25rem;
  height: 2.875rem;
  background: transparent linear-gradient(290deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  border: none;
  color: #fff;
}

/* staffaccept */
/* .th-staffaccept .modal-dialog {
    max-width: 62.5rem;
}
*/
.th-staffaccept .modal-content {
  padding: 40px;
  padding-top: 24px;
}

.th-staffaccept .asis_btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 0px 12px #00000029;
  border-radius: 1.125rem;
  padding: 2.0625rem 77px 34px 20px;
  width: 50%;
}

.th-staffaccept .asis_btn label {
  color: #0f7ad4;
}

.th-staffaccept h2 {
  color: #0a365b;
  font-weight: 600;
}

.th-staffaccept p {
  color: #808d98;
  font-size: 1.125rem;
  margin-bottom: 48px;
}

@media only screen and (max-width: 47.9375rem) {
  .th-staffaccept .asis_btn {
    width: 100% !important;
  }
}

.make-offer-btn {
  width: 8.75rem;

  background: #cde8ff 0% 0% no-repeat padding-box;
  border-radius: 1.125rem;
  opacity: 1;
  color: #0f7ad4;
  justify-content: center;
  align-items: center;
  border: none;
}

.accept-btn {
  width: 8.75rem;
  height: 2.4375rem;
  background: #d9f8dc 0% 0% no-repeat padding-box;
  border-radius: 1.125rem;
  opacity: 1;
  color: #17a325;
  justify-content: center;
  align-items: center;
  border: none;
}

.decline-btn {
  width: 8.75rem;
  height: 2.4375rem;
  background: #fdeddc 0% 0% no-repeat padding-box;
  border-radius: 1.125rem;
  opacity: 1;
  color: #ca7718;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.Staffnew-Modal .modal-dialog {
  max-width: 50%;
}

.staff-container {
  padding: 10% 5%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.yes-btn {
  width: 7.0625rem;
  height: 2.5rem;
  /* UI Properties */
  background: transparent linear-gradient(302deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  color: #fff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-btn {
  width: 7.0625rem;
  height: 2.5rem;
  background: #e71d1d 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  color: #fff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accept-modal .modal-dialog {
  max-width: 75.125rem;
}

.modal-backdrop.fade {
  opacity: 0.5;
  z-index: 1050;
}

.th-sttafhead {
  text-align: left;
  font: normal normal 600 1.5rem/35px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.th-sttafing-btn {
  width: 14rem;
  height: 4.5rem;
  /* UI Properties */
  background: transparent linear-gradient(300deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 1.125rem;
  opacity: 1;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-resposive {
  overflow: auto !important;
}

.spanp {
  font: normal normal normal 1rem/1.5rem Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.progress-check {
  width: 1.25rem;
  height: 1.25rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.progress-check-after {
  width: 1.25rem;
  height: 1.25rem;
  color: #37b42a;
  opacity: 1;
  border-radius: 100%;
  background: transparent 0% 0% no-repeat padding-box;
}

.big-checkbox {
  width: 3.625rem;
  height: 3.625rem;
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
}

/* The container */
.container1 {
  display: block;
  position: relative;
  padding-left: 2.1875rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  font-size: 1.375rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  border-radius: 50%;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 3.625rem;
  height: 3.625rem;
  background-color: #eee;
  transform: translate(0rem, -17px);
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container1:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container1 input:checked~.checkmark {
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;

  border-radius: 50%;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container1 input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container1 .checkmark:after {
  /* left: 0.5625rem;
    top: 0.3125rem;
    width: 2.1875rem;
    height: 1.25rem;
    border: solid white;
    border-width: 0 0.1875rem 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); */

  left: 1.1875rem;
  top: 0.75rem;
  width: 1.125rem;
  height: 1.8125rem;
  border: solid white;
  border-width: 0 0.3125rem 5px 0;
  transform: rotate(40deg);
  border-radius: 0.25rem;
}

.offer-lable {
  text-align: left;
  font: normal normal 600 1.5rem/35px Poppins;
  letter-spacing: 0rem;
  color: #0f7ad4;
  opacity: 1;
}

.offer-lable-rate {
  text-align: left;
  font: normal normal medium 1rem/25px Poppins;
  font-size: 1rem;
  font-weight: normal;
  font-family: "Poppins Medium";
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.offer-input {
  width: 7.875rem;
  height: 2.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  opacity: 1;
  padding: 0.625rem;
}

.offer-input[type="numbers"] {
  content: "$";
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #808d98;
  opacity: 1;
}

.offer-bg {
  width: 100%;
  /* height: 32.125rem; */
  background: #f1f5f8 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 2.5rem 20px;
}

.offer-bg-white {
  width: 100%;
  /* height: 32.125rem; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #d9e5ef;
  opacity: 1;
  padding: 2.5rem 20px;
}

.right_side_parent {
  padding-left: 17.1875rem !important;
  padding-right: 0rem;
  transition: 0.3s all ease-in-out;
}

.left_side_parent {
  padding-left: 5.9375rem !important;
  padding-right: 0rem;
}

.loader-1 .wrap:before {
  height: 100% !important;
}

.AgencyOnbordLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  /* Assuming you want it centered vertically as well */
}

.admin-user-h1 {
  text-align: left;
  font: normal normal 600 1.5rem/35px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.admin-user-h2 {
  text-align: left;
  font: normal normal 600 1.3rem/35px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.admin-user-p {
  text-align: left;
  font: normal normal medium 1rem/25px Poppins;
  font-size: 1rem !important;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.new-user-lables {
  text-align: left;
  font: normal normal 600 1.125rem/27px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.admin-new-user-input {
  width: 100%;
  height: 2.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  opacity: 1;
  padding: 0.625rem;
}

.admin-new-user-input[type="text"] {
  text-align: left;
  font: normal normal normal 1rem/25px Poppins;
  font-size: 1rem !important;
  letter-spacing: 0rem;
  color: #808d98;
  opacity: 1;
}

.td-name {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.td-end {
  letter-spacing: 0rem;
  line-height: 24px;
  display: flex;
  font-size: 14px;
  color: #17a325;
  opacity: 1;
  font-family: "Poppins Medium";
  justify-content: center;
  align-items: center;
  min-width: 178px;
  border-radius: 1.125rem;
  text-align: center;
  width: fit-content;
  height: 2.4375rem;
  padding: 9px 30px;
}

.border-bottom td {
  color: #0a365b !important;
}

.nav-bar-class {
  z-index: 3 !important;
  margin: 0 !important;
  height: 92px;
}

.patient_chat-head {
  text-align: left;
  font: normal normal 600 1.375rem/33px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.reject-map .map-box {
  margin-top: 0 !important;
  max-width: 26.875rem;
  height: 15.625rem;
}

.m-41 {
  margin-top: calc(41px - 1rem);
}

.m-31 {
  margin-bottom: 31px;
}

.w-91 {
  width: 91px !important;
}

/* --------------------------- cancel-madal */
.cancel-madal .modal-dialog {
  max-width: 66.75rem;
  height: 32.25rem;
}

.discribe-input {
  width: 60.75rem;
  height: 7.625rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  opacity: 1;
  padding: 0.625rem;
  resize: none;
}

.discribe-input:focus {
  outline: none;
  box-shadow: none;
}

.discribe-input::placeholder {
  position: absolute;
  top: 3;
}

.discribe-input[type="text"]::placeholder {
  position: absolute;
  top: 3;
}

.dicsribe-btn {
  width: 8.75rem;
  height: 2.875rem;
  background: transparent linear-gradient(300deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  text-align: center;
  font: normal normal normal 1rem/1.5625rem Poppins;
  letter-spacing: 0rem;
  color: #ffffff;
  margin-top: 2.5rem;
  opacity: 1;
}

.input-text-select {
  text-align: left;
  font: normal normal normal 1rem/1.5625rem Poppins;
  letter-spacing: 0rem;
  color: #808d98;
  opacity: 1;
  line-height: 2.0625rem;
  margin-left: 0.625rem;
}

.css-qc6sy-singleValue div {
  text-align: left;
  font: normal normal normal 1rem/1.5625rem Poppins;
  letter-spacing: 0px;
  color: #808d98;
  opacity: 1;
  margin: 0 !important;
  padding-left: 0.1563rem;
}

.a-button {
  text-align: left;
  font: normal normal 600 1.5rem/2.1875rem Poppins;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
  margin-bottom: 1.3125rem;
}

.agency-modal-1 .modal-dialog {
  max-width: 66.75rem;
}

.th-profile-inputs {
  width: 100%;
  height: 2.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  opacity: 1;
  padding: 0.625rem;
}

.disable-btn-class {
  background: lightgray !important;
  color: gray !important;
  cursor: not-allowed !important;
}

.error-tag p {
  width: 100% !important;
}

.error-tag input:focus {
  border-color: transparent;
}

.blocklist-btn {
  /* width: 13.1875rem; */
  color: #fff;
  border: 0;
  height: 2.875rem;

  padding-left: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 16px;
  background: transparent linear-gradient(291deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 5.375rem !important;
  opacity: 1;
  display: flex !important;
  align-items: center !important;
  /* justify-content: center; */
  gap: 7px !important;
  /* font-size: 16px; */
}

.thera-payment-lable {
  text-align: left;
  font: normal normal medium 1.125rem/1.6875rem Poppins;
  font-weight: 500;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
}

.date-exp {
  text-align: left;
  font: normal normal normal 1rem/1.5625rem Poppins;
  letter-spacing: 0px;
  color: #808d98;
  opacity: 1;
}

.Block-title {
  text-align: left;
  font: normal normal 600 36px/55px Poppins;
  letter-spacing: 0px;
  font-size: 2.25rem !important;
  height: 3.4375rem !important;
  color: #0a365b;
  opacity: 1;
}

.BlockList-modal .modal-dialog {
  max-width: 1415px !important;
}

.addblock-table {
  margin-top: 45px;
}

.addblock-table .table thead th {
  width: 27%;
}

.addblock-table .th-child:nth-child(1) {
  padding-left: 0 !important;
  width: 10%;
}

.addblock-table .th-child:nth-child(2) {
  padding-left: 5.875rem;
}

.adblock-textarea {
  width: 494px;
  height: 81px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b6c4ce;
  border-radius: 4px;
  opacity: 1;
  resize: none;
  padding: 0.625rem;
  color: #0A365B;
}

.adblock-textarea:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.calc-widt {
  width: calc(100% - 40%) !important;
}

.react-switch-head {
  text-align: left;
  font: normal normal 600 0.9375rem/23px Poppins;
  letter-spacing: 0rem;
  color: #0f7ad4;
  opacity: 1;
}

.emails-body tr td {
  text-align: left;
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #0a365b !important;
  opacity: 1;
}

.emails-body tr td:nth-child(1) {
  width: 35%;
}

.table-marginn-top {
  margin-top: 50px;
}

.emails-body tr {
  background: #f1f5f8 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 4px #00000029;
  border: 1px solid #eaebeb;
  border-radius: 4px;
  opacity: 1;
  border: none !important;
}

.agency-table-head tr th {
  border: none !important;
}

.emails-body tr td {
  background-color: transparent !important;
  --bs-table-accent-bg: transparent !important;
  border: none !important;
}

/* The radio-container */
.radio-container {
  display: block;
  position: relative;
  padding-left: 46px;
  padding-top: 1.5%;
  /* margin-bottom: 12px; */
  margin-bottom: 0;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio_mark {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.25rem;
  height: 2.25rem;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input~.radio_mark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked~.radio_mark {
  background-image: linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio_mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked~.radio_mark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .radio_mark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: transptrent;
}

.lable-cancel {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #808d98;
  opacity: 1;
  margin-left: 10px;
}

.create_agency-btns {
  top: 0;
  right: 0;
}

/* ON Board Therapist */
.paddingOn-board {
  padding-left: 50px;
}

.onBoard-tooltip-style .tooltip-inner {
  background-color: #0f7ad4 !important;
  text-align: start !important;
  min-width: 24.25rem !important;
  padding: 0.9375rem 15px !important;
  font-size: 0.875rem !important;
  font-family: "Poppins" !important;
  letter-spacing: 0rem;
  line-height: 1.125rem;
}

.onboard-therapist-0 {
  transform: translate3d(300%, 94%, 0rem) !important;
}

.onboard-therapist-2 {
  transform: translate3d(300%, 33.5rem, 0rem) !important;
}

.onboard-therapist-0.tooltipReject {
  transform: translate3d(239%, 150%, 0rem) !important;
}

.onboard-therapist-2.tooltipReject {
  transform: translate3d(239%, 48.5rem, 0rem) !important;
}

/* tooltips for creditial */
.tooltip1 .tooltip-arrow {
  display: none !important;
}

.tooltip1 .tooltip-inner {
  background-color: #0f7ad4 !important;
  text-align: start !important;
  min-width: 24.25rem !important;
  height: 4.125rem !important;
  padding: 0.9375rem 15px !important;
  font-size: 0.875rem !important;
  font-family: "Poppins" !important;
  letter-spacing: 0rem;
  line-height: 1.125rem;
}

.table td,
.table th {
  vertical-align: middle !important;
}

.tooltip2 .tooltip-arrow {
  display: none !important;
}

.tooltip2 .tooltip-inner {
  background-color: #0f7ad4 !important;
  text-align: start !important;
  min-width: 24.25rem !important;
  height: 6.1875rem !important;
  padding: 0.9375rem 15px !important;
  font-size: 0.875rem !important;
  font-family: "Poppins" !important;
  letter-spacing: 0rem;
  line-height: 1.125rem;
}

.tooltip3 .tooltip-arrow {
  display: none !important;
}

.tooltip3 .tooltip-inner {
  background-color: #0f7ad4 !important;
  text-align: start !important;
  min-width: 24.25rem !important;
  height: 9.6875rem !important;
  padding: 0.9375rem 15px !important;
  font-size: 0.875rem !important;
  font-family: "Poppins" !important;
  letter-spacing: 0rem;
  line-height: 1.125rem;
}

.tooltip1 {
  transform: translate3d(175%, 48.375rem, 0px) !important;
}

.tooltip1.tootlipdown1 {
  transform: translate3d(175%, 64.375rem, 0px) !important;
}

.tooltip2 {
  transform: translate3d(175%, 53.125rem, 0px) !important;
}

.tooltip2.tootlipdown2 {
  transform: translate3d(175%, 70.125rem, 0px) !important;
}

.tooltip3 {
  transform: translate3d(175%, 59.4375rem, 0px) !important;
}

.tooltip3.tootlipdown3 {
  transform: translate3d(175%, 78.4375rem, 0px) !important;
}

.therapist {
  /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
  transform: translate3d(464%, 69.4375rem, 0px) !important;
}

.therapist2 {
  /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
  transform: translate3d(464%, 75.4375rem, 0px) !important;
}

.therapist3 {
  /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
  transform: translate3d(464%, 81.4375rem, 0px) !important;
}

.Credential-margin {
  margin-top: 1.2rem !important;
  margin-bottom: 0.75rem !important;
}

.WidthCred-BTN {
  width: 96.5% !important;
}

.Main_modal_1 .modal-lg {
  max-width: 1448px !important;
}

/* 29-Agency-PatientCHARTS  */

.patient-details {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.patient-details p {
  padding: 0;
  margin: 0;
}

.patient-details .patient-genreal h4 {
  text-align: left;
  font: normal normal 600 24px/35px Poppins;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
  line-height: 2.1875rem;
  margin-bottom: 3.6px;
}

.patient-details .patient-genreal p {
  text-align: left;
  font: normal normal medium 16px/24px Poppins;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  letter-spacing: 0px;
  color: #a1aeb9;
  opacity: 1;
}

.patient-details .patient-report p:nth-child(1) {
  text-align: left;
  font: normal normal medium 1rem/1.5rem Poppins;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0px;
  color: #a1aeb9;
  opacity: 1;
  margin-bottom: 6px;
}

.patient-details .patient-report p:nth-child(2) {
  text-align: left;
  font: normal normal normal 1rem/1.5rem Poppins;
  letter-spacing: 0px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: #0a365b;
  opacity: 1;
}

.patient-details .patient-report {
  display: flex;
  gap: 74px;
  flex-wrap: wrap;
}

.patient-details.next-modal .patient-report {
  gap: 25px !important;
}

.left_button .button_prent p {
  text-align: left;
  font: normal normal normal 16px/24px Poppins;
  letter-spacing: 0px;
  color: #a1aeb9;
  text-transform: uppercase;
  opacity: 1;
  margin-bottom: 18px;
}

.left_button .button_prent button {
  border-radius: 100px;
  opacity: 0.2;
  text-align: left;
  font: normal normal medium 14px/24px Poppins;
  letter-spacing: 0px;
  opacity: 1;
  border: none;
  width: 178px;
  height: 39px;
  display: inline-flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  font-weight: 500;
}

.left_button .button_prent {
  margin-bottom: 22px;
}

.left_button .button_prent button svg {
  margin-right: 12px;
}

.CompleteAll-color {
  background: #17a32530;
  color: #17a325;
  opacity: 0.2;
}

.Sent_to_HHA {
  background: #6a40d43b;
  color: #6a40d4;
  opacity: 0.2;
}

.user-row-bar {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000029;
  opacity: 1;
  padding: 16px 16px 47px 45px;
  padding-left: calc(45px - 15px);
}

.close_btn-fixed {
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 11;
}

.bg-gray-main {
  background: #f1f5f8 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 33px 28px;
}

.bg-gray-main-form-print {
  background: #f1f5f8 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 33px 63px
}

.bg-gray-main .Document_list {
  text-align: center;
  font: normal normal 600 24px/35px Poppins;
  letter-spacing: 0px;
  color: #0f7ad4;
  opacity: 1;
}

.Doc_list_table {
  padding-left: calc(79px - 28px);
  padding-bottom: 155px;
}

.Doc_list_table .table thead th {
  width: 10%;
  font-weight: 500;
  border-bottom: 1px solid #ebeaed;
}

.Doc_list_table .table tbody tr {
  background: #fff;
  border-bottom: 1px solid #ebeaed;
}

.Doc_list_table .table tbody tr td:nth-child(1) {
  text-decoration: underline;
  font-size: 16px;
  color: #2e2c34;
  font-weight: 500;
  cursor: pointer;
}

.Doc_list_table .table tbody tr td:nth-child(1) svg {
  margin-right: 26px;
}

.Doc_list_table .table tbody tr td:nth-child(2) span {
  width: fit-content;
  height: 30px;
  padding: 7px 24px;
  font-size: 12px;
  font-weight: 500;

  border-radius: 100px;
}

.need_review {
  color: #d42b2b;
  background: #fff2f2;
}

.Completed_status {
  color: #76b360;
  background-color: #f2fcef;
}

.Doc_list_table .table tbody tr td {
  padding: 17px 10px;
}

.max-width_modal1 {
  max-width: 852px !important;
}

.max-width_modal1 .closeButton {
  position: absolute;
  top: 30px;
  right: 38px;
  z-index: 11;
}

.modal-lg .closeButton {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 111;
}

.Needs_Correction {
  padding: 44px 50px;
}

.Needs_Correction h4 {
  text-align: left;
  letter-spacing: 0px;
  color: #2e2c34;
  opacity: 1;
  margin-bottom: 21px;
  font-size: 24px;
  font-weight: 600;
}

.Needs_Correction p {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  color: #808d98;
  opacity: 1;
  margin-bottom: 28px;
}

.Needs_Correction .input_tabs label {
  text-align: left;
  font: normal normal medium 14px/21px Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
}

.Needs_Correction .input_tabs textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b6c4ce;
  border-radius: 4px;
  opacity: 1;
  resize: none;
  padding: 12px 15px;
  letter-spacing: 0px;
  color: #0a365b !important;
  font-size: 16px !important;
  font-weight: 400;
  margin-bottom: 21px;
}

.Needs_Correction .input_tabs textarea::placeholder {
  color: #0a365b !important;
}

.Discard-danger {
  padding: 14px 39px !important;
  border-radius: 12px !important;
  margin-right: 22px;
}

.primary-confirm {
  padding: 14px 39px !important;
  border-radius: 12px !important;
  background: transparent linear-gradient(284deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box !important;
}

.margin-need-correction {
  margin-left: 21px;
}

.main_area_row label {
  line-height: 24px;
  margin-bottom: 17px;
  text-align: left;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
  font-size: 16px;
  font-weight: 500;
}

.resize-none {
  resize: none;
}

.Special-Requests {
  padding: 10px;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b6c4ce;
  opacity: 1;
  border-radius: 4px;
}

.navTabs-disable {
  background: #efefef;
  opacity: 0.5;
}

.tabs-Disable .nav-link.disabled {
  background: #efefef;
  opacity: 0.5;
}

.DocTabs-position {
  position: absolute;
  top: 50%;
  left: 47%;
}

.formModal .modal-dialog {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  max-width: 1560px;
}

.addNewCred .modal-dialog {
  max-width: 1000px;
}

.Special-Requests {
  padding: 10px;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b6c4ce;
  opacity: 1;
  border-radius: 4px;
}

.vertical-rl {
  writing-mode: vertical-rl;
  rotate: -180deg;
}

.input-textSize {
  width: 100%;
  outline: none;
  display: block;
}

.square-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  width: 10px;
  height: 10px;
  border: 1px solid #000;
}

.square-radio:checked {
  background-color: blue;
}

.chakra-pin-input {
  width: 1rem;
  height: 25px;
  outline: 2px solid black;
}

.column-container {
  columns: 2;
}

.fifth td {
  line-height: 0.75rem;
}

.formModal .modal-dialog {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  max-width: 1560px;
}

.items-stretch {
  display: flex;
}

.input_group_episode {
  width: 80%;
}

.input_field_form_item {
  color: #9f9f9f;
  margin: 0 5px;
}

.episode_title h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
}

.input_between_span {
  color: #808d98 !important;
}

.Episode_group_form_input_group {
  width: 80% !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b6c4ce;
  border-radius: 4px;
  padding: 5px 16px;
}

@media (max-width: 1620px) {
  .therapist {
    /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
    transform: translate3d(435%, 69.4375rem, 0px) !important;
  }

  .therapist2 {
    /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
    transform: translate3d(435%, 75.4375rem, 0px) !important;
  }

  .therapist3 {
    /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
    transform: translate3d(435%, 81.4375rem, 0px) !important;
  }

  .onboard-therapist-0 {
    transform: translate3d(190%, 90%, 0rem) !important;
  }

  .onboard-therapist-2 {
    transform: translate3d(190%, 32.5rem, 0rem) !important;
  }

  .onboard-therapist-0.tooltipReject {
    transform: translate3d(190%, 145%, 0rem) !important;
  }

  .onboard-therapist-2.tooltipReject {
    transform: translate3d(190%, 47.5rem, 0rem) !important;
  }

  .tooltip1 {
    transform: translate3d(175%, 48.375rem, 0px) !important;
  }

  .tooltip1.tootlipdown1 {
    transform: translate3d(155%, 63.375rem, 0px) !important;
  }

  .tooltip2 {
    /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
    transform: translate3d(175%, 55.375rem, 0px) !important;
  }

  .tooltip2.tootlipdown2 {
    transform: translate3d(155%, 70.125rem, 0px) !important;
  }

  .tooltip3 {
    /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
    transform: translate3d(175%, 62.375rem, 0px) !important;
  }

  .tooltip3.tootlipdown3 {
    transform: translate3d(155%, 76.4375rem, 0px) !important;
  }
}

@media (max-width: 1510px) {
  .sac.text-area-scroll {
    width: 66% !important;
  }
}

@media (max-width: 1440px) {
  .sac.text-area-scroll {
    width: 63% !important;
  }

  .therapist {
    /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
    transform: translate3d(373%, 67.4375rem, 0px) !important;
  }

  .therapist2 {
    /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
    transform: translate3d(373%, 72.4375rem, 0px) !important;
  }

  .therapist3 {
    /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
    transform: translate3d(373%, 79.4375rem, 0px) !important;
  }

  .Credential-margin {
    margin-top: 0.625rem !important;
    margin-bottom: 0.5rem !important;
  }

  .onboard-therapist-0 {
    transform: translate3d(200%, 102%, 0rem) !important;
  }

  .onboard-therapist-2 {
    transform: translate3d(200%, 36.5rem, 0rem) !important;
  }

  .onboard-therapist-0.tooltipReject {
    transform: translate3d(200%, 168%, 0rem) !important;
  }

  .onboard-therapist-2.tooltipReject {
    transform: translate3d(200%, 49.5rem, 0rem) !important;
  }

  .tooltip1 {
    transform: translate3d(175%, 41.375rem, 0px) !important;
  }

  .tooltip1.tootlipdown1 {
    transform: translate3d(155%, 57.375rem, 0px) !important;
  }

  .tooltip2 {
    /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
    transform: translate3d(175%, 46.375rem, 0px) !important;
  }

  .tooltip2.tootlipdown2 {
    transform: translate3d(155%, 64.125rem, 0px) !important;
  }

  .tooltip3 {
    /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
    transform: translate3d(175%, 54.375rem, 0px) !important;
  }

  .tooltip3.tootlipdown3 {
    transform: translate3d(155%, 71.4375rem, 0px) !important;
  }
}

@media (max-width: 1280px) {
  .margin-need-correction {
    margin-left: unset;
    margin-top: 21px;
  }

  .margin-need-complete {
    margin-right: 21px;
  }
}

@media (max-width: 1279px) {
  .sac.text-area-scroll {
    width: 58% !important;
  }

  .acc-lable-sidebar {
    padding-left: 24%;
  }

  .therapist {
    /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
    transform: translate3d(313%, 76.4375rem, 0px) !important;
  }

  .therapist2 {
    /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
    transform: translate3d(313%, 82.4375rem, 0px) !important;
  }

  .therapist3 {
    /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
    transform: translate3d(313%, 88.4375rem, 0px) !important;
  }

  .onboard-therapist-0 {
    transform: translate3d(190%, 108%, 0rem) !important;
  }

  .onboard-therapist-2 {
    transform: translate3d(190%, 38.5rem, 0rem) !important;
  }

  .onboard-therapist-0.tooltipReject {
    transform: translate3d(190%, 173%, 0rem) !important;
  }

  .onboard-therapist-2.tooltipReject {
    transform: translate3d(190%, 50.5rem, 0rem) !important;
  }

  .tooltip1 {
    transform: translate3d(155%, 65.375rem, 0px) !important;
  }

  .tooltip1.tootlipdown1 {
    transform: translate3d(175%, 48.375rem, 0px) !important;
  }

  .tooltip2 {
    /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
    transform: translate3d(155%, 71.125rem, 0px) !important;
  }

  .tooltip2.tootlipdown2 {
    transform: translate3d(175%, 55.375rem, 0px) !important;
  }

  .tooltip3 {
    /* transform: translate3d(31.8125rem, 811px, 0px) !important; */
    transform: translate3d(155%, 77.4375rem, 0px) !important;
  }

  .tooltip3.tootlipdown3 {
    transform: translate3d(175%, 62.375rem, 0px) !important;
  }

  .patient-details.next-modal .patient-report {
    gap: 24px !important;
  }
}

@media (max-width: 1199px) {
  .userimge1.one {
    width: 10.625rem !important;
    height: 10.625rem !important;
    border-radius: 12.5rem;
  }

  .sac.text-area-scroll {
    width: 53% !important;
  }

  .WidthCred-BTN {
    width: 100% !important;
  }

  .therapist2.tooltip {
    margin-top: 70px !important;
    margin-left: -464px !important;
  }

  .therapist.tooltip {
    margin-top: 120px !important;
    margin-left: -456px !important;
  }

  .calc-widt {
    width: 100% !important;
  }

  .onboard-therapist-0 {
    transform: translate3d(240%, 108%, 0rem) !important;
  }

  .onboard-therapist-2 {
    transform: translate3d(240%, 38.5rem, 0rem) !important;
  }

  .onboard-therapist-0.tooltipReject {
    transform: translate3d(240%, 172%, 0rem) !important;
  }

  .onboard-therapist-2.tooltipReject {
    transform: translate3d(240%, 50.5rem, 0rem) !important;
  }
}

@media (max-width: 1090px) {
  .calc-widt {
    width: 100% !important;
  }

  .onboard-therapist-0 {
    transform: translate3d(220%, 110%, 0rem) !important;
  }

  .onboard-therapist-2 {
    transform: translate3d(220%, 38.5rem, 0rem) !important;
  }

  .onboard-therapist-0 .tooltipReject {
    transform: translate3d(220%, 170%, 0rem) !important;
  }

  .onboard-therapist-2 .tooltipReject {
    transform: translate3d(220%, 49.5rem, 0rem) !important;
  }

  .patient-details.next-modal .patient-report {
    gap: 24px !important;
  }
}

@media (max-width: 1020px) {
  .calc-widt {
    width: 100% !important;
  }

  .onboard-therapist-0 {
    transform: translate3d(200%, 107%, 0rem) !important;
  }

  .onboard-therapist-2 {
    transform: translate3d(200%, 38.5rem, 0rem) !important;
  }

  .onboard-therapist-0.tooltipReject {
    transform: translate3d(200%, 170%, 0rem) !important;
  }

  .onboard-therapist-2.tooltipReject {
    transform: translate3d(200%, 49.5rem, 0rem) !important;
  }
}

@media (max-width: 1020px) {
  .calc-widt {
    width: 100% !important;
  }
}

@media (max-width: 992px) {
  .onboard-therapist-0 {
    transform: translate3d(200%, 107%, 0rem) !important;
  }

  .onboard-therapist-2 {
    transform: translate3d(200%, 38.5rem, 0rem) !important;
  }

  .onboard-therapist-0.tooltipReject {
    transform: translate3d(200%, 170%, 0rem) !important;
  }

  .onboard-therapist-2.tooltipReject {
    transform: translate3d(200%, 49.5rem, 0rem) !important;
  }
}

@media (max-width: 768px) {
  .EarliestAvailability {
    align-items: flex-start;
    padding-right: unset;
  }

  .make-offer-btn.btn-width-1 {
    padding: unset;
  }

  .desktop-50 {
    width: 100% !important;
  }

  .paddingOn-board {
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobile_left_width {
    padding-left: 30px !important;
  }

  .pr-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .mobile_width-30 {
    width: 40%;
  }

  .mobile_width2-30 {
    width: 38%;
  }

  .blockApprovebtn {
    padding-right: 4.5%;
  }

  .mobile_justify {
    justify-content: center !important;
  }

  .ad-activee {
    padding-bottom: 10px;
  }

  .padding-mobile-alr {
    padding-left: 71px;
  }

  .mobile-mb-3 {
    margin-bottom: 18px;
  }
}

@media (min-width: 1280px) {
  .GrAttachment_Icon {
    position: absolute;
    right: 9px;
    top: 7px;
    z-index: 33;
    width: 18px;
  }
}

@media (min-width: 1440px) {
  .GrAttachment_Icon {
    position: absolute;
    right: 11px;
    top: 12px;
    z-index: 33;
    width: unset;
  }
}

@media (max-width: 767px) {
  /* .connect_main_strip {
    padding: 10px 27px 10px 20px !important;
  } */
}

.items-stretch {
  width: 100%;
}

.basis-200 {
  width: 18%;
  padding-bottom: 10px;
  padding-right: 10px;
  border-right: 2px solid #000;
  height: auto;
}

.basis-200 .font-extrabold {
  font-size: 50px;
  line-height: 1;
  font-weight: 600;
}

.ceneter-div {
  width: 65%;
}

.from-heading {
  display: flex;
  align-items: flex-end;
}

.border-x-2 {
  border-right: 2px solid #000;
}

.border-r-0 {
  border-right: none !important;
}

.text-left.text-1rem {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  padding: 32px;
  margin-left: 10px;
  font-weight: 600;
}

.text-heading-bold .request {
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
}

.text-heading-light-bold {
  font-size: 1rem;
  margin-top: 8px;
  font-weight: 600;
}

.flex {
  display: flex;
}

.vertical-rl {
  border-left: 2px solid #000;
}

.pl-1 {
  padding-left: 1rem;
}

.border-b-2 {
  border-bottom: 1px solid #000 !important;
}

.border-r-1 {
  border-right: 1px solid #000;
}

.border-bottom-2 {
  border-bottom: 1px solid #000 !important;
}

.position-unset {
  position: unset !important;
  opacity: unset !important;
  cursor: unset !important;
  height: unset !important;
  width: 100% !important;
}

.checkmark-5.Form {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: unset;
}

.container-2 .checkmark-5.Form::after {
  left: 0.5125rem;
  top: 0.1375rem;
}

.w-14 {
  width: 14%;
}

/* .limited-inut-text {
  font-size: 14px;
} */
.payee-code {
  width: 37%;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.main-exempt {
  margin-bottom: 26px;
}

.border-y-2 {
  border-bottom: 2px solid #000;
}

.w-40 {
  width: 40%;
}

.basis-60 {
  width: 60%;
}

.height-170 {
  width: 100%;
  height: 155px;
  resize: none;
}

.bg-black {
  padding: 0px 8px;
  font-size: 19px;
}

.border-right-2 {
  border-right: 2px solid #000 !important;
}

.border-left-2 {
  border-left: 2px solid #000 !important;
}

.border-bottom-2 {
  border-bottom: 2px solid #000 !important;
}

.otp-inputs-css {
  width: 9%;
  height: 46px;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  border-right: 0;
  outline: none;
  margin-top: 2px;
}

.otp-inputs-css:nth-last-child(1) {
  border-right: 2px solid #000;
}

.border-1 {
  border: 2px solid #000;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.border-tops-2 {
  border-top: 2px solid #000 !important;
}

.progress-1 {
  margin-top: 106px;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  background-color: #fff;
}

.common_bar_bottom {
  padding-top: 150px;
}

.signHere p {
  font-size: 25px;
  font-weight: 600;
  line-height: 25px;
}

.signHere {
  display: flex;
  flex-direction: column;
  border-right: 2px solid #000;
}

.mein-fonts {
  font-size: 14px;
}

.ul-li-margin ul,
.ul-li-margin ol {
  margin: 0;
}

.ul-li-margin li {
  padding: 10px 0;
  padding-right: 10px;
  line-height: 22px;
  text-align: left;
}

.ul-li-margin p {
  padding-top: 10px;
  margin: 0;
  line-height: 24px;
  text-align: left;
}

.alphaNumeric li {
  line-height: 28px;
}

.border-top-2 {
  border-top: 2px solid #000;
}

.ptEvaluation {
  max-width: 1500px !important;
}

.ptEvaluation .modal-content {
  background-color: #f7f7f7 !important;
}

.form-heading-1 {
  text-align: center;
}

.fileds-from {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 16px;
  align-items: center;
}

.non-visit-fileds-from {
  justify-content: unset !important;
  gap: 0px !important;
  
}

.fileds-from label {
  margin: 0;
  line-height: auto;
  vertical-align: middle;
}

.fileds-from input {
  /* height: 36px !important; */
}

.mein-white-from-background {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000014;
  border-radius: 12px;
  opacity: 1;
  padding: 30px 40px;
  margin-bottom: 16px;
  margin-top: 28px;
  position: relative;
}

.border-right-1 {
  border-right: 1px solid #dcdcdc;
}

.mein-white-from-background.h5_line::before {
  position: absolute;
  border-bottom: 1px solid #dcdcdc;
  content: "";
  background: #dcdcdc;
  height: 1px;
  width: 100%;
  top: 60px;
  left: 0;
}

.radio-of-forms {
  display: flex;
  gap: 31px;
  margin-bottom: 18px;
}

.radio-of-forms label {
  margin: 0;
}

.Additional_text {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  opacity: 1;
  height: 117px;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  resize: none;
}

.ptEvaluation .form-control {
  width: 100%;
  height: auto !important;
}

.mein-white-from-background .form-control {
  height: auto !important;
}

.form-pt-table {
  border: 1px solid #dcdcdc;
  border-radius: 8px;
}

.form-pt-table table thead {
  background-color: #f7f7f7;
}

.form-pt-table table thead tr th {
  letter-spacing: 0px;
  color: #202020;
  opacity: 1;
  font-size: 18px;
  line-height: 34px;
  padding: 0px 1.5rem;
  white-space: nowrap;
  font-weight: 600;
  border-right: 1px solid #dcdcdc;
}

.form-pt-table table thead tr th:nth-child(2),
.form-pt-table table thead tr th:nth-child(3),
.form-pt-table table thead tr th:nth-child(4),
.form-pt-table table thead tr th:nth-child(5),
.form-pt-table table thead tr th:nth-child(6),
.form-pt-table table thead tr th:nth-child(7),
.form-pt-table table thead tr th:nth-child(8),
.form-pt-table table thead tr th:nth-child(9),
.form-pt-table table tbody tr td:nth-child(2),
.form-pt-table table tbody tr td:nth-child(3),
.form-pt-table table tbody tr td:nth-child(4),
.form-pt-table table tbody tr td:nth-child(5),
.form-pt-table table tbody tr td:nth-child(6),
.form-pt-table table tbody tr td:nth-child(7),
.form-pt-table table tbody tr td:nth-child(8),
.form-pt-table table tbody tr td:nth-child(9),
.form-pt-table table tbody tr td:nth-child(10),
.form-pt-table table tbody tr td:nth-child(11),
.form-pt-table table tbody tr td:nth-child(12) {
  text-align: center;
}

.form-pt-table table tbody tr td {
  text-align: left;
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  letter-spacing: 0px;
  color: #202020;
  border-right: 1px solid #dcdcdc;
  padding: 0px 1.5rem;
}

.border-table-danger,
.border-table-danger[type="checkbox"] {
  border: 1px solid #ff8080;
  border-color: #ff8080;
  background-color: #fff6f6;
}

.thead-2 {
  border-top: 2px solid #dcdcdc;
}

.border-table-danger::placeholder {
  color: red;
  opacity: 0.3;
}

.boder-form-pt-b {
  border-bottom: 2px solid #dcdcdc;
}

.no-wrap-white-space {
  white-space: nowrap;
}

.btn-pte {
  background: transparent linear-gradient(302deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  color: #fff;
}

.fc-toolbar-chunk div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.fc-toolbar-chunk div h2 {
  text-align: center;

  font-size: 15px !important;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
}

.fc-toolbar-chunk .fc-prev-button.fc-button.fc-button-primary,
.fc-toolbar-chunk .fc-next-button.fc-button.fc-button-primary {
  width: 23px;
  height: 23px;
  display: flex;
  position: relative;
  border-radius: 100px;
  background: #fff;
  border: 2px solid #c3d2de;
  justify-content: center;
  align-items: center;
}

.fc-toolbar-chunk .fc-prev-button.fc-button.fc-button-primary .fc-icon.fc-icon-chevron-left {
  /* position: absolute; */
  /* left: 0px; */
  /* top: 1px; */
  font-size: 18px;
  color: #c3d2de;
}

.error_absolute {
  position: absolute !important;
  top: 180px;
}

.AuthorizationsTabs .mb-4 {
  margin-bottom: 0 !important;
  padding: 0px 27px;
}

.AuthorizationsTabs .patient-card {
  width: 88%;
  padding: 22px 27px;
  height: auto !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #d4e3ef;
  border-radius: 18px;
  opacity: 1;
}

.AuthorizationsTabs .nav-tabs {
  border: 0;
}

.AuthorizationsTabs .patient-card .patient-card-div .AuthorizationsTabs-inputs,
.AddAuthorisationBody .AuthorizationsTabs-inputs,
.AuthorizationsTabs .patient-card .patient-card-div .AuthorizationsTabs-inputs-select-input .css-13cymwt-control {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b6c4ce;
  border-radius: 4px;
  opacity: 1;
  padding: 12px 14px;
  height: 46px;
  white-space: nowrap;
}

.AuthorizationsTabs .patient-card .patient-card-div .AuthorizationsTabs-inputs-select-input .css-13cymwt-control,
.AuthorizationsTabs .patient-card .patient-card-div .AuthorizationsTabs-inputs-select-input .css-t3ipsp-control {
  padding: 0 10px;
  height: 46px;
}

.AuthorizationsTabs .patient-card .patient-card-div .AuthorizationsTabs-inputs-select-input .css-13cymwt-control .css-1fdsijx-ValueContainer .css-1dimb5e-singleValue,
.AuthorizationsTabs .patient-card .patient-card-div .AuthorizationsTabs-inputs-select-input .css-t3ipsp-control .css-1fdsijx-ValueContainer .css-1dimb5e-singleValue {
  font-weight: 400;
}

.AuthorizationsTabs .patient-card .patient-card-div {
  gap: unset !important;
  /* display: grid !important;
	grid-template-columns:repeat(4, 1fr); */
}

.AuthorizationsTabs .patient-card .patient-card-div>div,
.Authorisation-btn,
.AuthorizationsTabs .common-btns-div {
  padding: 0 10px;
}

.AuthorizationsTabs-inputs-select-input-margin {
  margin-bottom: 27.15px;
}

.mb-8px {
  margin-bottom: 8px !important;
}

.AuthorizationsTabs .Authorisation-btn {
  margin-bottom: 22px;
}

.AuthorizationsTabs-border-red {
  border: 1px solid #d42b2b !important;
}

.AuthorizationsTabs-border-green {
  border: 1px solid #67d669 !important;
}

.AuthorizationsTabs-border-black {
  border: 1px solid black !important;
}

.AuthorizationsTabs .common-btns-div {
  margin-bottom: calc(37.85px - 22px);
}

.AuthorizationsTabs .common-btns-div .cancel-btns,
.AuthorizationsTabs-dialog .common-btns-div .cancel-btns {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d42b2b;
  border-radius: 12px;
  opacity: 1;
  padding: 11px 28px;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #d42b2b;
  opacity: 1;
}

.AuthorizationsTabs .common-btns-div .Approve-btns,
.AuthorizationsTabs-dialog .common-btns-div .Approve-btns,
.Approve-btns {
  background: transparent linear-gradient(284deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 12px;
  border: 0;
  padding: 11px 24px;
  margin-left: 18.5px;
  font-size: 16px;
  width: fit-content;
  line-height: 24px;
  letter-spacing: 0px;
  color: #fff;
}

.Approve-btns:disabled {
  opacity: 0.5;
  /* Reduce opacity to indicate the button is disabled */
  cursor: not-allowed;
  /* Change cursor to not-allowed */
}

.whitespace-nowrap {
  white-space: nowrap;
}

.AuthorizationsTabs-dialog .common-btns-div {
  margin-top: 47px;
}

.AuthorizationsTabs-dialog .common-btns-div .Approve-btns,
.AuthorizationsTabs-dialog .common-btns-div .cancel-btns {
  padding: 14px 39px;
}

.AuthorizationsTabs-dialog {
  max-width: 812px !important;
}

.AuthorizationsTabs-dialog .modal-body {
  padding: 40px 50px !important;
}

.AuthorizationsTabs-dialog .closeButton {
  position: absolute;
  right: 38px;
  top: 41px;
  z-index: 9999;
}

.AddAuthorisationBody .Heading {
  font-size: 28px;
  line-height: 48px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #2e2c34;
  opacity: 1;
  margin-bottom: 30px;
}

.AddAuthorisationBody .dateLable {
  text-align: left;
  letter-spacing: 0px;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 6px;
  color: #0a365b;
  opacity: 1;
}

.AddAuthorisationBody .episodeDate {
  text-align: left;
  font-size: 16px;
  margin-bottom: 23px;
  line-height: 18px;
  letter-spacing: 0px;
  color: #2e2c34;
  opacity: 1;
}

.search_bar_frm.documentaion {
  margin-top: 54px;
  padding: 0px 45px;
}

.Commen-head-tabs-changes .nav-tabs {
  border-bottom: none !important;
  gap: unset !important;
  padding-left: 10px;
}

.Commen-head-tabs-changes .nav-tabs .nav-item {
  display: flex;
}

.Commen-head-tabs-changes .nav-tabs span {
  margin-right: 8px;
}

.Commen-head-tabs-changes .nav-tabs .menu_boxing.active span {
  /* display: none !important; */
  background-color: #fff;
  color: #e71d1d;
}

.Commen-head-tabs-changes .nav-tabs p {
  margin: 0 !important;
}

.Commen-head-tabs-changes .nav-tabs .menu_boxing.active.nav-link {
  padding: 8px 20px;
  border: none !important;
  background: linear-gradient(-90deg,
      rgba(15, 122, 212, 1) 8%,
      rgba(106, 64, 212, 1) 98%) !important;
  color: #fff;
  display: flex;
  border-radius: 4px;
}

.Commen-head-tabs-changes .nav-tabs .nav-item:nth-child(1) .menu_boxing.nav-link {
  border-right: 0 !important;
  border-radius: 4px 0px 0px 4px !important;
  align-items: center;
}

.Commen-head-tabs-changes .nav-tabs .nav-item:nth-child(2) .menu_boxing.nav-link {
  border-left: 0 !important;
  border-radius: 0px 4px 4px 0px !important;
  align-items: center;
}

.Commen-head-tabs-changes .nav-tabs .menu_boxing.nav-link {
  padding: 8px 20px;
  display: flex;
  border: none !important;
  border: 1px solid lightgray !important;
  color: #fff;
  border-radius: 4px;
}

.Commen-head-tabs-changes .nav-tabs .menu_boxing.active.nav-link p {
  color: #fff !important;
}

.error-font {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.fc-toolbar-chunk .fc-next-button.fc-button.fc-button-primary .fc-icon.fc-icon-chevron-right {
  /* position: absolute; */
  /* left: 0px; */
  /* top: 1px; */
  font-size: 18px;
  color: #c3d2de;
}

.fc-customButton-button.fc-button.fc-button-primary {
  background: transparent;
  border: 1px solid #a1aeb9;
  color: #a1aeb9;
  border-radius: 50px;
  padding: 6px 22px;
}

.Patient-calender {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #d4e3ef;
  padding: 33px 24px !important;
}

.Patient-calender .fc-col-header-cell-cushion {
  width: 50px;
  font-weight: 600;
}

.Patient-calender .fc-toolbar-title {
  margin-right: 0px !important;
  width: 120px;
  text-align: left;
}

.fc-event-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc-col-header thead tr th:nth-child(1),
.fc-timegrid-slots table tbody tr td:nth-child(1) {
  /* display: none; */
  visibility: hidden;
  /* border: 0; */
}

.Patient-calender .fc-event-title.fc-event-title.fc-sticky {
  font-size: 13px;
}

.fc .fc-button-primary:focus {
  box-shadow: unset !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 47px !important;
}

/* table css */

.Functional_Mobility_table .table-responsive {
  display: inline-table;
}

.primery_span_vital_signs {
  font-size: 20px;
}

span.font-bold {
  font-weight: 700;
}

.input_inside .input_inside_checkbox {
  margin-right: 0.8rem;
}

.input_inside {
  margin-bottom: 1rem;
}

.input_inside_label {
  display: flex;
  align-items: baseline;
}

.margin-bottom-18 {
  margin-bottom: 18px;
}

.Vital_Signs_row_gap {
  margin-bottom: 16px;
}

.cursor-pointer {
  cursor: pointer !important;
}

/* pop-up css */
.modal_Verify_title {
  text-align: left;
  font: normal normal 600 36px/48px Poppins;
  letter-spacing: 0px;
  color: #2e2c34;
  opacity: 1;
  margin-bottom: 8px;
}

.modal_Verify_description {
  text-align: left;
  font: normal normal normal 16px/20px Poppins;
  letter-spacing: 0px;
  color: #2e2c34;
  opacity: 1;
  margin-bottom: 40px;
}

.otp_validation {
  width: 75px;
}

.otp_validation input {
  border: none;
  border-bottom: 1px solid #e6e6e6;
  color: #2e2c34 !important;
  text-align: center;
  font-size: 36px !important;
  padding-bottom: 20px;
}

.otp_validation_modal_body {
  padding: 36px 36px !important;
}

.otp_validation input.valid {
  border-color: #6a40d4;
}

.modal_body_btn {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
}

.modal_body_btn button {
  border: none;
  outline: none;
  background-color: transparent;
}

.modal_body_btn button:nth-child(1) {
  font: normal normal medium 16px/20px Poppins;
  letter-spacing: 0px;
  color: #84818a;
  opacity: 1;
}

.modal_body_btn button:nth-child(2) {
  font: normal normal 600 16px/20px Poppins;
  letter-spacing: 0px;
  color: #5542f6;
  opacity: 1;
}

.otp-continue-btn {
  background: transparent linear-gradient(284deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 86px;
  border: 0;
  padding: 11px 24px;
  font-size: 16px;
  width: fit-content;
  line-height: 24px;
  letter-spacing: 0px;
  color: #fff;
  width: 100%;
}

.resend-disable {
  color: #878383 !important;
  pointer-events: none !important;
}

.rejected {
  position: absolute;
  left: 10px;
  top: -23px;
}

@media (max-width: 1440px) {
  .rejected {
    left: 10px;
    top: -17px;
  }

  .progress-1 {
    margin-top: 67px;
  }
}

.stripehover_svg {
  display: none;
}

.svg_btn:hover .stripehover_svg {
  display: block;
}

.svg_btn:hover .stripe_svg {
  display: none;
}

.Stripe_btn {
  background: #635bff;
  border-radius: 10px;
  padding: 20px;
  height: auto;
  font-weight: 600;
}

.Stripe_btn:hover {
  background: #7a73ff;
}

.profile_information_row .rejected {
  position: absolute;
  top: 0px;
  left: 0;
}

.profile_information_field_row {
  align-items: baseline;
}

.profile_information_field_row_address {
  align-items: baseline;
}

.profile_information_field_row_address .rejected {
  position: absolute;
  top: -20px;
  left: 0;
}

/* Header Fixed  */

nav.navbar.navbar-expand-lg {
  position: fixed;
  width: 100%;
  z-index: 999;
  height: auto;
  top: 0;
}

.otp_img img {
  padding-bottom: 23px;
  max-width: 72px;
  height: 72px;
}

.connect_main_strip {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b6c4ce;
  opacity: 1;
  border-radius: 30px;
  padding: 52px 52px 37px 52px;
  text-align: center;
}

.connect_main_strip p {
  color: #0a365b;
  font-size: 16px !important;
  margin-bottom: 65px;
}

.connect_strip_cls img {
  max-width: 100% !important;
}

h4.payment_cls {
  font-size: 18px;
  color: #abb4bb;
}

input.unit_aptcls::placeholder {
  color: #000;
}

.connect_strip_cls {
  min-width: 1120px;
}

.strip_connectes_inner {
  border: 1px solid #635bff;
  border-radius: 10px;
  max-width: 320px;
  margin: 0 auto;
  padding: 10px 0px 10px 0px;
}

.strip_connectes_inner_admin {
  border: 1px solid #635bff;
  border-radius: 10px;
  /* margin: 0 auto; */
  padding: 10px 0px 10px 0px;
}

.txt_connect_ {
  display: flex;
  text-align: left;
  gap: 5px;
}

.Stripetxt_connect {
  margin-left: 10px;
}

.txt_connect_ p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #31b984;
  opacity: 1;
  padding-left: 5px;
}

.skip_btn_main button {
  width: 8.875rem;
  height: 2.875rem;
  background: #d8dfe4 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  text-align: center;
  font: normal normal normal 1.125rem/27px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  border: none;
  margin-left: 1%;
  height: 2.4375rem;
  font-size: 0.8125rem !important;
}

h2.connect_title {
  font: normal normal 600 36px/55px Poppins;
  margin-bottom: 54px;
}

.mobile_width .rejected_text {
  border: 2px solid #e89797 !important;
}

input.rbt-input-main.rbt-input {
  height: 2.875rem;
}

.rejected-border input.rbt-input-main.rbt-input {
  /* border-color: #e89797; */
  border: 2px solid #c52525;
}

.review-heading {
  text-align: left;
  font: normal normal 600 36px/55px Poppins;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
}

.review-bodytext {
  text-align: left;
  font: normal normal 600 18px/27px Poppins;
  letter-spacing: 0px;
  color: #808d98;
  opacity: 1;
}

.review-container {
  margin-top: 100px;
  width: 909px !important;
  height: 502px;
}

.stripe-text {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
}

.strip_connectes_main {
  max-width: 355px;
}

span.stripe_connent_text {
  display: inline-block;
  color: #635bff;
  font-weight: 700;
  font-size: 16px;
}

.strip_connectes_main p {
  letter-spacing: 0px;
  color: #635bff !important;
  opacity: 1;
  font-size: 20px;
}

.strip_connectes_main_admin p {
  letter-spacing: 0px;
  color: #635bff !important;
  opacity: 1;
  font-size: 20px;
  text-wrap: nowrap;
}

.item_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-black {
  color: #000 !important;
}

.bg-blue {
  background-color: #e7f4ff;
  color: black !important;
}

/* Notification css start 9/jan/2024*/
.notification-div {
  position: relative;
  justify-content: space-between !important;
}

.cardNotification {
  border: 1px solid #eae8e8 !important;
  border-radius: 8px !important;
  box-shadow: rgba(159, 162, 191, 0.18) 0px 9px 16px,
    rgba(159, 162, 191, 0.32) 0px 2px 2px;
  overflow: hidden !important;
  width: 400px !important;
  margin: 16px !important;
  position: absolute !important;
  right: -18px;
  padding: 10px;
  background-color: #ffff;
  top: 40px;
}

.cardNotification .row.read {
  background-color: #f0f0f0;
}

.cardNotification .row.unread {
  font-weight: bold;
}

/* Notification css end 9/jan/2024*/

.cursor-context {
  cursor: context-menu;
}

/* patient_main_parent inner_content_form select media quarry*/
@media (max-width:1720px) {
  .strip_connectes_inner_admin img {
    width: 100%;
  }

  .StrikeImg,
  .Striketext {
    padding: 0 5px !important;
  }
}

@media (max-width: 1606px) {
  .patient_main_parent .inner_content_form select {
    width: 12rem;
  }

  .StrikeImg img {
    width: 48px;
  }

  .StrikeImg,
  .Striketext {
    padding: 0 5px !important;
  }

  .StrikeRightArrow.col-12 {
    padding-right: 5px !important;
  }

}

@media (max-width: 1490px) {
  .patient_main_parent .inner_content_form select {
    width: 10rem;
  }

  .strip_connectes_main_admin p {
    font-size: 14px;
  }

  .strip_connectes_main_admin p span {
    font-size: 14px;
  }
}

@media (max-width: 1011px) {
  .patient_main_parent .inner_content_form select {
    width: 14rem;
    gap: 5px;
  }

  .patient_right_form .inner_content_form {
    flex-wrap: wrap;
  }
}

.CodeVerificationSec {
  flex-direction: column;
  gap: 0px !important;
}

.rate_fields>input {
  color: blue !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.h-35vh {
  height: 35vh;
}

.background-submit-button {
  width: 22.75rem !important;
}

.visitTypeLink {
  text-decoration: underline;
  color: #0F7AD4 !important
}

.form-date-width {
  max-width: 70px;
}

.margin-left-17 {
  margin-left: 17px !important;
}

/* Warning Prompt Modal CSS Start */
.warning_prompt_container {
  height: 200px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add_new_credential_header {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 50px;
  font-size: 24px !important;
  line-height: 36px;
  color: #0A365B;
}

.add_new_credential_body {
  margin-bottom: 50px;
}

.reason-rejection {
  width: 70%;
  border: 1px solid #FF9292;
  padding: 20px;
  background-color: #FFEFEF;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  color: #808D98 !important;
}

/* Warning Prompt Modal CSS End */

.coverage-area-loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8;
}

.file-upload-wrapper input[type=file]::file-selector-button {
  display: none;
}

.FindPatientsdesktopMarginTop {
  margin-top: 40px;
}

.UserProfilePrefillDetails {
  justify-content: end;
  gap: 40px;
  align-items: end;
}

.UserProfilePrefillDetails p {
  margin: 0;

}

.PrefillDetailsEmailPassword {

  flex-direction: column;
}

.PenddingM .closeButton {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
}

@media (max-width:1791px) {
  .PrefillDetailsEmailPassword {
    gap: 20px;
  }
}

.PendingModelMarginTop {
  margin-top: 30px;
}

.PendingModelMarginTop .p6 {
  margin-top: 0;
}

@media (max-width:991px) {
  .FindPatientsdesktopMarginTop {
    margin-top: 0px;
  }

  .PendingModelMarginTop {
    margin-top: 0px;
  }
}


.cross_warning .closeButton {
  display: none;
}

.radioBtnColoumn {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  gap: 20px;
  background: #EDEDED;
  border-radius: 12px;
  padding: 20px;
  height: 100%;
}

.radioBtnColoumn .radioBtnsSection {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  text-decoration: underline;
  display: block;
}

.radioBtnColoumn input:checked~.checkmark-2 {
  background-color: #fff !important;
  border: 2px solid #0f7ad4;
}

.radioBtnColoumn .checkmark-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 6.25rem;
}

.radioBtnColoumn .container-2 .checkmark-2:after {
  left: 50%;
  top: 50%;
  transform: translate(-48%, -51%);
  width: 16px;
  height: 16px;
  background: #0f7ad4;
  border-radius: 6.25rem;
}

.radioBtnColoumn label {
  height: unset !important;
  color: #2E2C34;

}

.PrimeryAlertColor {
  color: #E71D1D;
  font-style: italic;
  font-weight: 300 !important;
}

.radioBtnColoumn label:nth-of-type(3) input:checked~.checkmark-2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.radioBtnColoumn label:nth-of-type(3) input:checked~.checkmark-2::after {
  top: 0;
  left: 0;
  transform: unset;
  position: relative;
}

.mein-white-from-background .pteval-input {
  appearance: none;
}

.QuestionMarkToolTip {
  margin-top: 30px;
  align-items: center;
}

.present_input_chha input{
  display: inline;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.chha-radio{
  margin-top: 6px;
}

.pdf-page-break {
  page-break-after: always;
  page-break-inside: avoid;
  clear:both;
}

.edit-doc-icon{
  height: 50px;
  margin-bottom: 7px;
}

.last-border{
  outline: 0.125rem solid #ff0606 !important
}
.last-border td:nth-child(1){

  border-left: 0.125rem solid #ff0606 !important;
}
.last-border td:nth-last-child(1) {
  border-right: 0.125rem solid #ff0606 !important;
}
.disable-hard {
  background-color: #a9a9a9 !important
}

.full-width-modal .modal-dialog {
  max-width: 100%;
  margin: 0;
}

.full-width-modal .modal-dialog .modal-content {
  width: 100vw;
  height: 100vh;
}

.tax-form-download{
  text-align: right;
  
  
}
.tax-form-download img{
  width: 50px;
  cursor: pointer;
}

.stripe-error .error-img {
  text-align: center;
  margin-top: 53px;
}

.stripe-error .error-img img {
  width: 200px;
}

.stripe-error .msg{
  font-family: "Poppins Semi Bold";
  text-align: center;
  font-size: 18px;
  margin-top: 32px;
}

.stripe-status .check-img {
  text-align: center;
  margin-top: 53px;
}

.stripe-status .check-img img {
  width: 200px;
}

.stripe-status .status{
  font-family: "Poppins Semi Bold";
  text-align: center;
  font-size: 18px;
  margin-top: 32px;
}

.payment-form {
  padding: 70px 20px 30px 20px;
}

.agreement_form{
  border: 0;
  border-bottom: 1px solid;
  color: #808d98 !important;
}