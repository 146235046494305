@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,800&display=swap");

* {
  box-sizing: border-box;
  /* transition: all 0.3s ease-in-out; */
  margin: 0;
  padding: 0;
  font-family: "Poppins", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

/* 
@font-face {
  font-family: "Poppins";
  src: url("./fonts/PoppinsRegular.woff2") format("woff2"), url("./fonts/PoppinsRegular.woff") format("woff");
}

@font-face {
  font-family: "Poppins Medium";
  src: url("./fonts/PoppinsMedium.woff2") format("woff2"), url("./fonts/PoppinsMedium.woff") format("woff");
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("./fonts/PoppinsSemibold.woff2") format("woff2"), url("./fonts/PoppinsSemibold.woff") format("woff");
}

@font-face {
  font-family: "Poppins Bold";
  src: url("./fonts/PoppinsBold.woff2") format("woff2"), url("./fonts/PoppinsBold.woff") format("woff");
} */

@import "bootstrap/dist/css/bootstrap.min.css";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

.loader {
  width: 64px;
  height: 64px;
}

.spin {
  background: transparent;
  position: fixed;
  border-radius: 50%;
  overflow: hidden;
  z-index: 0;
  -webkit-animation: spin2 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spin>div:not(.spin__blocker) {
  width: 50%;
  height: 50%;
  z-index: -1;
}

.spin__blocker {
  position: absolute;
  top: 0;
  left: 0;
  top: 3%;
  left: 7%;
  background: white;
  width: 90%;
  height: 90%;
  border-radius: 50%;
}

.spin__bottom-left {
  position: absolute;
  top: 50%;
  left: 0;
  background: -webkit-linear-gradient(top left,
      rgba(0, 182, 255, 0.75) 0%,
      rgba(0, 55, 255, 0.5) 100%);
  background: linear-gradient(to bottom right,
      rgba(0, 182, 255, 0.75) 0%,
      rgba(0, 55, 255, 0.5) 100%);
}

.spin__bottom-right {
  position: absolute;
  top: 50%;
  left: 50%;
  background: -webkit-linear-gradient(bottom left,
      rgba(0, 55, 255, 0.5) 0%,
      rgba(73, 0, 255, 0.25) 100%);
  background: linear-gradient(to top right,
      rgba(0, 55, 255, 0.5) 0%,
      rgba(73, 0, 255, 0.25) 100%);
}

.spin__top-left {
  position: absolute;
  top: 0;
  left: 50%;
  background: -webkit-linear-gradient(top left,
      rgba(201, 0, 255, 0) 0%,
      rgba(73, 0, 255, 0.25) 100%);
  background: linear-gradient(to bottom right,
      rgba(201, 0, 255, 0) 0%,
      rgba(73, 0, 255, 0.25) 100%);
}

.black_input_group input {
  color: black !important;
  border-color: #B6C4CE;
}

.spin__blocker {
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
}

.Toastify {
  font-size: 1rem !important;
}

.modal-backdrop.show {
  opacity: 80% !important;
  background: #02111c !important;
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(2%, 6%);
}

.modal::-webkit-scrollbar {
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.Daily-therapist-h5 {
  text-align: left;
  font: normal normal 600 1.5rem/35px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
}

.pd-33 .modal-content {
  padding-bottom: 2.0744rem;
}

.daily-p1 {
  text-align: left;
  font: normal normal 600 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
}

.daily-p2 {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.block-width .modal-content {
  top: 4.125rem;
  left: 5%;
  width: 37.25rem;
  height: 32.5625rem;
}

.block-width {
  left: 4%;
}

.login-input {
  /* width: 30.4375rem; */
  max-height: 2.875rem !important;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  padding: 0.625rem;
}

.agency-details {
  display: flex;
  justify-content: space-between;
  box-shadow: 0rem 3px 10px #00000029;
  padding-top: 64px;

  /* height: 273.008px; */
}

.health_gency .inputs input {
  border: 0px;
  border-bottom: 1px solid #000;
  color: #000 !important;
}

.lastSection {
  min-width: 330px;
}

.lastSection .inputs {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  gap: 20px
}

.therapy_services_main input {
  border: 0;
  border-bottom: 1px solid;
}

.agency-div4 {
  display: flex;
}

span.therapy_services_list {
  min-width: 25px !important;
  display: inline-block;
}

.list_alpha_space {
  padding-left: 25px;
}

.business-therapy-main input {
  border: 0;
  border-bottom: 1px solid;
}

span.Business_text {
  border-bottom: 1px solid;
}

.agency-top {
  position: absolute;
  left: 79%;
  /* bottom: 80px; */
  top: 0.625rem;
}

.Connexion_docm h1 {
  font-size: 16px;
  font-weight: 700;
}

.switch-agency {
  padding: 0rem;
}

.start-19 {
  justify-content: center;
}

.staffing-sno {
  text-align: left;
  letter-spacing: 0rem;
  color: #a1aeb9;
  opacity: 1;
  font-family: "poppins" sans-serif;
  font-weight: medium;
  font-size: 1rem;
}

.homepage-1 .modal-dialog {
  width: 65.8125rem;
}

.radio-name {
  text-align: left;
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #808d98;
  opacity: 1;
}

.radio-inout-tharepist {
  width: 2.25rem;
  height: 2.25rem;
  /* background: transparent url("../src/images/radio_selected.svg") 0% 0% no-repeat padding-box; */
  border: 0.0625rem solid #b6c4ce;
  border-radius: 5.375rem;
}

.m-l-40 {
  margin-left: 40%;
}

.dailyagency-1 .modal-dialogt {
  max-width: 47.875rem !important;
}

.activepatients-1 .modal-dialog {
  max-width: 94.3125rem !important;
}

.activepatients-1 .modal-dialog {
  transform: translate(-32%, 0) !important;
}

.test {
  max-width: 90.5rem !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 3px 10px #00000029;
  opacity: 1;
}

.userimge1 {
  width: 12.625rem;
  height: 12.625rem;
  border-radius: 12.5rem;
}

.username-1 {
  font: normal normal 600 1.5rem/35px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  font-family: "Poppins";
  font-weight: 600;
}

.userdetails-1 {
  font: normal normal normal 1rem/1.5rem Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  font-family: "Poppins";
  font-size: 1rem;
}

.userlabele-1 {
  font: normal normal normal 1rem/1.5rem Poppins;
  letter-spacing: 0rem;
  color: #a1aeb9;
  text-transform: uppercase;
  opacity: 1;
  font-size: 1rem;
  font-weight: normal;
  font-family: "Poppins";
}

.userstatus-1 {
  width: 11.125rem;
  height: 2.4375rem;
  /* UI Properties */
  background: #fdeddc 0% 0% no-repeat padding-box;
  border-radius: 1.125rem;
  opacity: 1;
  font-size: 0.875rem !important;
}

.p-user {
  margin-right: 18%;
  text-align: end;
}

.status-p {
  margin-right: 9%;
  text-align: center !important;
  margin-bottom: 1% !important;
}

.bg-white-user {
  padding: 1.875rem 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 9px 10px #00000029;
  opacity: 1;
  padding-bottom: 3.125rem;
}

.Patient-lable {
  color: #a1aeb9;
}

.close-open {
  width: 11.125rem;
  height: 2.4375rem;
  background: #d9f8dc;
  color: #17a325;
  border-radius: 1.125rem;
  opacity: 1;
}

.status-open {
  margin-bottom: 0;
  margin-top: 0.625rem;
}

.input-w333 .form-control {
  width: 20.8125rem;
}

.test .modal-content {
  border: none;
}

.accrordian-date {
  text-align: left;
  font: normal normal 600 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.patient-card {
  padding: 0.625rem;
  width: 33.5%;
  height: 9.875rem;
  border-radius: 0.9375rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 3px 8px #d4e3ef;
  border-radius: 1.125rem;
  opacity: 1;
  border: none;
  margin: 0.625rem 20px 10px 0px;
}

.patient-card-div1 {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 50%;
  background: #4359d4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.Patient-calender {
  background-color: #fff;
  padding: 0.625rem;
  margin-top: 1.5rem;
}

.rates-bg {
  padding: 1.25rem;
  background: #fff;
  margin-bottom: 1.75rem;
}

.td-name {
  color: #0a365b;
}

.card-titel1 {
  text-align: left;
  font-size: 1.375rem !important;
  font: normal normal 600 1.375rem/33px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.accrordian-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 3.125rem;
}

.Uheader-1 {
  background: #f1f5f8 0% 0% no-repeat padding-box;
  opacity: 1;
}

.therapist-header {
  opacity: 1 !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #0F7AD4 !important;
  font-family: "Poppins" !important;
}

.accordion-header button {
  font: normal normal 600 1.5rem/35px Poppins;
  letter-spacing: 0rem;
  color: #0f7ad4 !important;
  opacity: 1;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
}

.test-panding-bg {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 3.125rem;
  padding: 0 1.25rem;
  background: #f1f5f8 0% 0% no-repeat padding-box;
  opacity: 1;
}

.test-panding {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 3.125rem;
  padding: 0 1.25rem;
  opacity: 1;
}

.patient-color {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 3.125rem;
  padding: 0 1.25rem;
  background: #ffff 0% no-repeat padding-box;
  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 10px 10px #0000001e;
  opacity: 1;
}

.closeButton {
  justify-content: flex-end !important;
}

/* agency reject  */
.agen-reject-head-main {
  margin-top: 3rem;
}

.agen-btn-first {
  margin-top: 2.6875rem;
}

.agen-reject-head {
  text-align: left;
  font-family: "Poppins" !important;
  font-size: 1.5rem;
  font-weight: 600 !important;
  letter-spacing: 0rem;
  color: #0a365b !important;
  opacity: 1;
  margin-bottom: 1.25rem;
  line-height: 2.1875rem;
}

.agen-reject-head2 {
  font-family: "Poppins" !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  color: #0f7ad4 !important;
}

.agen-reject-head3 {
  font-family: "Poppins" !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  color: #808d98 !important;
}

.agen-reject-input {
  width: 51.5rem !important;
  height: 9.25rem !important;
  padding: 1 rem;
  /* margin-bottom: 1.25rem; */
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
}

.agen-reject-lbl {
  text-align: left;
  font-family: "Poppins" !important;
  font-size: 1.125rem;
  font-weight: 600 !important;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  margin-bottom: 1.75rem !important;
}

.agen-reject-check {
  min-width: 2.25rem !important;
  min-height: 2.25rem !important;
  background: #0f7ad4 0% 0% no-repeat padding-box !important;
  border: 0.0625rem solid #0f7ad4 !important;
  border-radius: 0.25rem !important;
  opacity: 1 !important;
  margin-right: 0rem;
}

.agen-reject-body {
  text-align: left;
  font-family: "Poppins" !important;
  letter-spacing: 0rem !important;
  color: #a1aeb9 !important;
  text-transform: uppercase !important;
  opacity: 1 !important;
  font-size: 1rem !important;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  line-height: 1.5rem;
}

.agen-reject-body2 {
  text-align: left;
  font-family: "Poppins" !important;
  letter-spacing: 0rem !important;
  color: #0a365b !important;
  text-transform: uppercase !important;
  opacity: 1 !important;
  font-size: 1rem !important;
  margin-left: 0rem;
  width: 100%;
  line-height: 1.5rem;
  word-break: break-word;
}

.agen-reject-input-1 {
  width: 51.5rem;
  height: 9.25rem;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  opacity: 1;
  /* margin-left: 3.125rem; */
  /* margin-bottom: 1.25rem; */
  margin-top: 1.25rem;
  padding: 0.625rem;
}

.agen-reject-input-1:focus {
  outline: none;
}

.agen-reject-input {
  /* margin-bottom: 1.3125rem !important; */
  height: 2.875rem !important;
}

.agen-reject-lbl {
  text-align: left;
  font-family: "Poppins";
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  font-size: 1rem;
  font-weight: 200;
}

.agen-reject-check-form {
  margin-bottom: 2.3125rem;
}

.agen-reject-check-form {
  border-bottom: 0.125rem solid #e4ecf2 !important;
}

.agen-reject-check2 {
  margin-right: 1.5rem;
}

.agen-reject-btn {
  margin-top: 3.75rem !important;
  margin-bottom: 1.875rem !important;
}

.displayname-rates {
  font: normal normal 600 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #808d98;
  opacity: 1;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Poppins";
}

.rates-labale-2 {
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  font-size: 1rem;
  font-weight: medium;
  font-family: "Poppins Medium";
}

.coverage-btn {
  width: 18.75rem;
  height: 2.875rem;
  /* UI Properties */
  background: transparent linear-gradient(285deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  border: none;
  /* UI Properties */
  text-align: center;
  letter-spacing: 0rem;
  color: #ffffff;
  opacity: 1;
  font-size: 1.125rem;
  font-family: "Poppins";
  font-weight: normal;
  text-transform: uppercase;
}

.location-input {
  width: 30%;
  margin-bottom: 16px;
  padding: 0.125rem 10px;
  height: 1.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
}

.delete-btn1 {
  width: 18.75rem;
  height: 2.875rem;
  /* UI Properties */
  background: transparent linear-gradient(285deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  border: none;
  color: #fff;
  text-align: center;
  font: normal normal normal 1.125rem/27px Poppins;
  letter-spacing: 0rem;
  color: #ffffff;
  opacity: 1;
}

.save-btn1 {
  width: 12.5rem;
  height: 2.875rem;
  /* UI Properties */
  background: transparent linear-gradient(285deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  border: none;
  color: #fff;
  text-align: center;
  font: normal normal normal 1.125rem/27px Poppins;
  letter-spacing: 0rem;
  color: #ffffff;
  opacity: 1;
}

.map-width {
  width: 96% !important;
}

.closebtn-modal {
  position: absolute;
  right: 0.625rem;
  top: 1.875rem;
  background: none;
}

.map-box {
  color: white;
  width: 66.25rem;
  height: 26.25rem;
  margin-top: 32px;
}

.therapist-map .map-box {
  height: 17.5rem;
  width: 48%;
  margin-top: 0rem !important;
  border-radius: 6px;
}

.sub-head-exp {
  text-align: left;
  font: normal normal medium 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  font-family: "Poppins medium";
  font-size: 1rem;
  font-weight: normal;
  opacity: 1;
  margin-top: 1%;
}

.select-box-1 {
  width: 9.375rem;
  height: 2.875rem;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  margin-bottom: 16px;
}

.thera__error_width {
  width: 9.375rem;
}

.exp-label {
  margin-top: 10px;
  text-align: left;
  font: normal normal medium 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  font-size: 1rem;
  font-family: "Poppins medium";
}

.exp-check {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 0.0625rem solid #b6c4ce !important;
  border-radius: 0.25rem !important;
}

.exp-body {
  word-wrap: break-word;
  word-break: break-all;
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #808d98 !important;
  opacity: 1;
  text-transform: capitalize !important;
  font-size: 1rem;
  font-family: "Poppins";
}

.therapist .tooltip-inner,
.therapist2 .tooltip-inner,
.therapist3 .tooltip-inner {
  background-color: #0f7ad4 !important;
  text-align: start;
  padding: 0.9375rem 15px !important;
  font-size: 0.875rem !important;
  width: fit-content !important;
  font-family: "Poppins" !important;
  letter-spacing: 0rem;
  line-height: 1.125rem;
}

.AgencyModal .modal-content {
  border-radius: 0 !important;
}

.therapist3.tooltip,
.therapist2.tooltip,
.therapist.tooltip {
  margin-top: 50px;
  margin-left: 21px;
}

.exp-plus {
  /* width: 2.375rem;
  height: 2.4375rem;
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box; */
  border: none;
  border-radius: 12.5rem;
}

.exp-btn1 {
  width: 18.75rem;
  height: 2.875rem;
  /* UI Properties */
  background: transparent linear-gradient(285deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  border: none;
  color: #fff;
  text-align: center;
  font: normal normal normal 1.125rem/27px Poppins;
  letter-spacing: 0rem;
  color: #ffffff;
  opacity: 1;
}

.top-link {
  width: 40% !important;
  text-align: center;
  font: normal normal 600 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  text-transform: uppercase;
}

.nav-icon-w {
  width: 1.75rem;
}

.nav-border {
  border-top: 0.3125rem solid #0f7ad4;
}

.top-link-active {
  border-top: 0.3125rem solid #e71d1d;
  color: #e71d1d !important;
}

.cred-input {
  width: 21.1875rem;
  height: 2.875rem;
  background: #f1f5f8 0% 0% no-repeat padding-box;
  border-radius: 0.25rem;
  padding: 0.625rem;
}

#dynamicText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cred-page {
  font: normal normal 600 1rem/25px Poppins !important;
  margin-top: 3.4375rem !important;
  margin-left: 6.25rem;
}

.cred-m {
  font-size: 1.125rem;
  font-weight: 800;
  font: normal normal 600 1.125rem/27px Poppins !important;
  color: rgb(0, 0, 0);
  margin-left: 0.9375rem;
}

.cred-header {
  font-size: 18px !important;
  line-height: 27px;
  font-weight: 700;
  color: #0A365B;
}

.cred-document-type {
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  color: #0A365B !important;
}

.cred-status {
  font-size: 14px !important;
  line-height: 21px !important;
  align-items: center !important;
  font-weight: 500;
  background: #d9f8dc;
  border: none;
  width: 11.125rem;
  padding: 0.625rem 20px;
  border-radius: 1.125rem;

}

.cred-covid {
  width: 100% !important;
}

.cred-row {
  margin-right: 37.5rem !important;
}

.cred-sign {
  border-radius: 0.3125rem !important;
  background: transparent linear-gradient(285deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;

  opacity: 1;
  border: none;
  color: #fff;
  text-align: center;
  font: normal normal normal 1.125rem Poppins;
  letter-spacing: 0rem;
  color: #ffffff;
  opacity: 1;

  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
  padding: 0;
}

.cred-md-7-btn {
  margin-left: -0.9375rem;
}

.cred-lbl {
  font-size: 1rem;
  font-weight: 600;
  font-family: "poppins";
  color: rgb(0, 0, 0);
  margin-bottom: 0.75rem !important;
}

.cred-ch-box {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.625rem !important;
  border: solid 0.0625rem #b6c4ce !important;
}

.cred-last-in {
  width: 47%;
  height: 2.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  opacity: 1;
  padding: 0.625rem;
}

.cred-btn {
  margin-left: 1.875rem;
}

.cred {
  margin-left: 1.875rem;
  margin-top: 3.125rem;
}

.cred-in {
  background: #f1f5f8 !important;
  border: none !important;
  font-family: normal normal "poppins";
  background-repeat: no-repeat;
  margin-bottom: 1.25rem !important;
  margin-bottom: 1.625rem !important;
}

::-webkit-file-upload-button {
  display: none;
}

.cred-date {
  width: 14.25rem;
  height: 2.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  opacity: 1;
  padding: 0.625rem;
}

.newuser-m {
  margin-top: 3.1875rem;
}

.commen2 {
  margin-top: 1.25rem;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,800&display=swap");

* {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none !important;
  color: black !important;
  font-weight: 400;
}

.header.chat__header_padding {
  background: #fafafa 0% 0% no-repeat padding-box;
  opacity: 1;
  /* z-index: -11; */
  position: sticky;
}

.unstyle {
  margin-top: 20px;
}

.right-side_msg {
  width: 50%;
}

.side-bar_msg {
  color: #ffffff;
  background: #ff5248 0% 0% no-repeat padding-box;
  border-radius: 11px;
}

.side-bar_msg2 {
  background: #5542f6 0% 0% no-repeat padding-box;
  color: #ffffff;
}

.for-btnc {
  background: #0070db 0% 0% no-repeat padding-box;
  color: #ffffff;
  border-radius: 16px 1px 0px 16px;
}

.header h5 {
  font: normal normal 600 18px/27px Poppins;
  font-size: 18px;
}

.top_msg {
  background: #ffffff 0% 0% no-repeat padding-box;
}

.top_msg.active {
  background: #fafafa 0% 0% no-repeat padding-box;
}

.user-name {
  font: normal normal 600 16px/24px Poppins;
}

.user-msg {
  text-align: left;
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
}

#search {
  top: 3.5rem;
  left: 2.4rem;
  font-size: 1.4em;
}

.pt {
  color: #0f7ad4;
}

.urgent {
  color: #ff7172;
  text-transform: uppercase;
  font-size: 14px;
  position: relative;
}

.urgent::before {
  border-radius: 10px;
  content: "";
  position: absolute;
  left: -9px;
  /* top: 0px; */
  width: 7px;
  height: 7px;
  background-color: #ff7172;
  top: 35%;
}

.input_group_form {
  width: 66% !important;
  margin: 2px 2px;
}

.right_side_muted_txt {
  color: #17a325;
  cursor: pointer;
  font-size: 12px;
  position: relative;
}

.right_side_muted_txt::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 3px;
  border-radius: 10px;
  background-color: #17a325;
  left: -10px;
  top: 8px;
}

.follow {
  position: relative;
  cursor: pointer;

  color: #4a40b5;
}

.desktop-margin_left {
  padding-left: 42px !important;
}

.margin-1_rem {
  margin-top: 1.5rem;
}

.follow::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 10px;
  background-color: #4a40b5;
  left: -16px;
  top: 8px;
}

.left-side_msg {
  position: relative;
  left: 0;
  width: 100%;
}

.small_chat_img {
  width: 40px !important;
  height: 40px !important;
}

.scroll_0::-webkit-scrollbar {
  display: none !important;
}

.chat-display-none {
  display: block;
}

.btnn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ebeaed;
  border-radius: 0px 6px 6px 0px;
  color: #0a365b;
  padding: 11px 25px;
  width: 164px;
  height: 42px;
  margin: 0 0 0 0;
  text-align: left;
  letter-spacing: 0px;
  font-family: "Poppins";
  font-size: 14px;
  text-align: center;

  color: #0a365b;
  opacity: 1;
}

.btn-n {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ebeaed;
  border-radius: 6px 0px 0px 6px;

  color: #0a365b;
  padding: 11px 25px;
  width: 164px;
  height: 42px;
  margin: 0 0 0 0;
  text-align: left;
  letter-spacing: 0px;
  font-family: "Poppins";
  font-size: 14px;
  text-align: center;

  color: #0a365b;
  opacity: 1;
}

.btnn.active {
  background: transparent linear-gradient(294deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 0px 6px 6px 0px;
  color: white;
  padding: 11px 25px;
  border: none;
  margin: 0 0 0 0;
  color: #ffffff;
  font-family: "Poppins";
  font-size: 14px;
}

.btn-n.active {
  color: #ffffff;
  background: transparent linear-gradient(294deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 6px 0px 0px 6px;
  color: white;
  padding: 11px 25px;
  border: none;
  margin: 0 0 0 0;
  color: #ffffff;
  font-family: "Poppins";
  font-size: 14px;
}

.btnn-n svg {
  width: 24px;
}

.check-size .checkmark1 {
  width: 1.5rem;
  height: 1.5rem;
}

.check-size .checkmark1::after {
  left: 0.4375rem !important;
  top: 0.0625rem !important;
}

.btnn-n {
  padding: 12px 12px;
  border: none;
  width: 42px;
  height: 42px;
  text-align: left;
  font-family: "Poppins";
  font-size: 14px;
  display: flex;
  justify-content: center;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
  margin: 0 0 0 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ebeaed;
  border-radius: 4px;
}

.btn-a {
  color: #0a365b;
  padding: 11px 25px;
  border: none;
  margin: 0 0 0 0;
  font-family: "Poppins";
  font-size: 14px;

  border: 1px solid #ebeaed;
  border-radius: 6px 0px 0px 6px;
}

.btn-b {
  position: absolute;
  top: 0px;
  height: 53px;
  display: block;
  left: 20px;
  background-color: #006aff;
  color: white;
  padding: 0 10px;
  border-radius: 10px 0 0 10px;
}

.sac {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dcdbdd;
  border-radius: 0 16px 16px 0 !important;
  padding: 15px 10px;
}

.sac2 {
  padding: 15px 10px;

  border: none;
  background-color: #eee;
  width: 60%;
}

.subject {
  opacity: 0;
}

#arrow {
  position: absolute;
  right: 500px;
  bottom: 8px;
  font-size: 37px;
  color: #006aff;
}

.badge {
  display: inline-block;
  padding: 3px 8px;
  font-size: 13px !important;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.solid_border {
  border: 1px solid #ff7b77;
  padding: 10px;
  border-radius: 5px 5px 5px 0;
}

#times {
  position: absolute;
  left: 1130px;
  bottom: 8px;
  font-size: 37px;
  color: #006aff;
  box-shadow: 0px 4px 25px rgba(11, 75, 89, 0.1);
}

#times2 {
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #c2dbf1;
  margin: auto;
  cursor: pointer;
  font-size: 37px;
  color: white;
  box-shadow: 0px 4px 25px rgba(11, 75, 89, 0.1);
  width: 56px;
  text-align: center;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 95px;
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #c2dbf1;
  opacity: 1;
}

#time {
  font-size: 60px;
  color: #006aff;
  box-shadow: 0px 4px 25px rgba(11, 75, 89, 0.1);
}

.conta {
  position: relative;
  margin: 0px 32px !important;
}

/* .conta::before {
  content: "";
  width: 1px;
  height: 100%;
  top: 51px;
  height: 800px;
  left: 16px;
  position: absolute;
  border: 1px dotted black;
  opacity: 0.5;
} */

.sidebar_status {
  position: relative;

  margin-left: 34px;
}

.icon_cross_1 svg {
  width: 1.75rem !important;
  height: 1.75rem !important;
}

.conta .sidebar_status::before {
  content: "";
  width: 15px;
  height: 14px;
  left: -18px;
  top: 22px;
  position: absolute;
  border: 1px solid #b6b4ba;
  background-color: #b6b4ba;
  letter-spacing: 4px;
  border-radius: 10px;
}

.conta .sidebar_status::after {
  content: "";
  height: 100%;
  top: 39px;
  height: 119%;
  left: -10.5px;
  position: absolute;
  opacity: 0.5;
  outline: 2px dotted #b6b4ba;
  outline-offset: -1px;
}

.conta .sidebar_status.last::after {
  display: none;
}

.select-container-box {
  padding-right: 0px;
  position: relative;
}

.select-container-box::after {
  content: "";
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 15px;
  background-image: url(./images/chevron-down.svg);
  background-size: contain;
  background-position: center;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  width: 28px;
  height: 11px;
  transition: all 0.3s ease;
}

.select-container-box.addActive::after {
  transition: all 0.3s ease;
  transform: translateY(50%);

  rotate: 180deg;
}

.offcanvas-backdrop.fade.show {
  display: none;
}

.small,
small {
  margin: 0;
}

.text-chat-mr {
  text-align: left;
  font: normal normal medium 16px/24px Poppins !important;
  letter-spacing: 0px !important;
  color: #a1aeb9 !important;
  font-size: 16px !important;
  opacity: 1;
}

.offcanvas.offcanvas-bottom.position-relative.show {
  bottom: 72px;
}

.shad {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000029;
  opacity: 1;
}

.color {
  background: transparent linear-gradient(281deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 9px 9px 0px 9px;

  color: white;
  border-radius: 0px 10px 10px 10px;
  margin: 0 0 0 0;
}

.brdr {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px 5px 5px 0;
}

.cancel {
  margin-left: 10px;
  padding: 17px 28px;
  border-radius: 10px;
  border: none;
}

.cancel i {
  font-size: 30px;
}

.dwn {
  margin-left: 10px;
  padding: 17px 28px;
  border-radius: 10px;
  background-color: #006aff;
  color: white;
  border: none;
}

.user-name {
  text-align: left;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
}

.dwn i {
  font-size: 30px;
}

.box {
  height: 30px;
  width: 30px;
  font-size: 20px;
  border: #006aff;
}

.col-md-2_5 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-9_5 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.for-btnc {
  padding: 17px;
  border: none;
  background: #0070db 0% 0% no-repeat padding-box;
  border-radius: 16px 1px 0px 16px;
  opacity: 1;
  text-align: left;
  font: normal normal normal 14px/24px Poppins;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.h-none .form-control {
  height: unset !important;
}

.dropdown-toggle::after {
  display: none;
}

.chat__header_padding {
  padding: 0px 40px;
  padding-top: 0;
}

.solid_border {
  text-align: left;
  font: normal normal normal 1rem/1.625rem Poppins;
  letter-spacing: 0px;
  color: #202020;
  opacity: 1;
  border-radius: 8px 8px 8px 0px;
}

.color.brdr {
  background: transparent linear-gradient(281deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 9px 9px 0px 9px;
  opacity: 1;
}

.margin-bottom-40 {
  margin-bottom: 30px;
}

.image_margin_right {
  margin-right: 12px;
}

.image_margin_left {
  margin-left: 12px;
}

#arrow2 {
  background: transparent linear-gradient(331deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #c2dbf1;
  border-radius: 18px;
  opacity: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 65px;
  top: 10px;
  font-size: 23px;
  color: white;
  width: 36px;
  border-radius: 30px;
  text-align: center;
  height: 36px;
}

.download-btn {
  width: 157px;
  height: 51px;
  background: transparent linear-gradient(300deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 14px 24px;
  color: white;
  border: none;
}

.cancel-download-btn {
  width: 129;
  height: 51px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ebeff2;
  border-radius: 12px;
  padding: 14px 24px;
  color: black;
}

.download-btn span {
  font: normal normal normal 16px/26px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.cancel-download-btn span {
  font: normal normal normal 16px/26px Poppins;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
}

.home_search_bar::placeholder {
  text-align: left;
  font: normal normal normal 14px/24px Poppins;
  letter-spacing: 0px;
  color: #808d98;
  opacity: 1;
}

.search_bar-1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ebeaed;
  border-radius: 4px;
  opacity: 1;
  border-left: 0 !important;
}

.search_bar-icon {
  background-color: transparent;
  border-right: 0 !important;
}

.acc-lable-close {
  color: #9ca1b0;
  font-size: 12px;
}

@media only screen and (min-width: 2200px) {
  .connect_strip_cls {
    min-width: 1250px;
  }
}

@media only screen and (max-width: 1440px) {
  .connect_strip_cls {
    min-width: 1000px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .connect_strip_cls {
    min-width: 1000px;
  }

}

@media only screen and (max-width: 1199px) {
  .connect_strip_cls {
    min-width: 860px;
  }

  .payment-popup .modal-dialog {
    max-width: 70rem !important;
  }
}

@media only screen and (max-width: 991px) {
  .connect_strip_cls {
    min-width: 760px;
  }

  .payment-popup .modal-dialog {
    max-width: 65rem !important;
  }
}

@media only screen and (max-width: 768px) {
  .connect_strip_cls {
    min-width: 600px !important;
  }

  .payment-popup .modal-dialog {
    max-width: 50rem !important;
  }
}

@media only screen and (max-width: 740px) {
  .connect_strip_cls img {
    max-width: 50px;
  }

  .connect_strip_cls {
    min-width: 480px !important;
  }

  .payment-popup .modal-dialog {
    max-width: 44rem !important;
  }
}

@media screen and (max-width: 480px) {
  .left-side_msg {
    width: 50vw;
  }

  button.save_continues_btn {
    max-width: 155px;
  }

  .connect_strip_cls {
    min-width: 395px !important;
  }

  .payment-popup .modal-dialog {
    max-width: 33rem !important;
  }

  .connect_main_strip {
    padding: 21px 17px 20px 21px;
  }

  button.save_continues_btn {
    max-width: 200px;
  }
}

/* @media (max-width: 884px) {
    
    #arrow2 {
        background: transparent linear-gradient(331deg, #6A40D4 0%, #0F7AD4 100%) 0% 0% no-repeat padding-box;
        cursor: pointer;
        position: relative;
        left: 403px;
        bottom: 46px;
        font-size: 23px;
        color: white;
        width: 36px;
        border-radius: 30px;
        text-align: center;
        height: 36px;
    }
    #times2 {
       display: none;
    }
   
    
}
@media (max-width: 766px) {
    
    #arrow2 {
        background: transparent linear-gradient(331deg, #6A40D4 0%, #0F7AD4 100%) 0% 0% no-repeat padding-box;
        cursor: pointer;
        position: relative;
        left: -90px;
        bottom: -9px;
        font-size: 23px;
        color: white;
        width: 36px;
        border-radius: 30px;
        text-align: center;
        height: 36px;
    }
    #times2 {
        display: block;
     }
    
    
} */

@media (max-width: 1200px) {
  * {
    font-size: 0.8rem;
  }

  .input-245 {
    width: 100%;
  }

  .bENZXI {
    width: 100% !important;
  }

  .ca-body {
    background-image: none;
  }

  .map {
    width: 100%;
    height: 31.25rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .cred-row {
    margin-right: 0.9375rem !important;
  }
}

@media (max-width: 476px) {
  * {
    font-size: 0.5rem;
  }

  .cred-btn-1 {
    margin-bottom: 0.625rem;
  }
}

@media (max-width: 503px) {
  * {
    font-size: 0.5rem;
  }

  .cred-m-col {
    padding: 0% !important;
    margin-left: 0.3125rem !important;
  }

  .cred-p {
    margin-right: 0.3125rem !important;
  }

  .col-md-7 {
    padding-left: 0.625rem !important;
  }

  .col-md-5 {
    padding-left: 0.625rem !important;
  }

  .cred-col {
    padding-right: 0rem !important;
    padding-left: 1.875rem !important;
  }
}

@media (max-width: 524px) {
  * {
    font-size: 0.5rem;
  }

  .cred-input {
    display: flex;
    flex-direction: column;

    padding-left: 0.3125rem;
  }

  .cred-lbl {
    margin-top: 0.625rem;
  }

  .cred-ch-box {
    margin-top: 0.625rem;
  }

  .cred-ch-p {
    align-items: center;
  }
}

.file-btn-1 {
  width: 2.875rem !important;
  height: 2.875rem !important;
  /* UI Properties */
  background: #0f7ad4 0% 0% no-repeat padding-box !important;
  border-radius: 0.25rem !important;
  opacity: 1;
}

.input-attach {
  position: relative;
  right: 1rem;
}

.file-upload-wrapper {
  position: relative;
  /* height: 2.875rem; */
}

.w9 {
  font: normal normal medium 1rem/25px Poppins;
  font-size: 1rem;
  font-family: "Poppins medium";
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  font-weight: normal;
}

.file-upload-wrapper:after {
  content: attr(data-text);
  font-size: 1rem;
  font-family: "Poppins";
  position: absolute;
  top: 0.5625rem;
  left: 0;
  background: #f1f5f8;
  padding: 0.125rem 15px;
  display: block;
  width: calc(100% - 2.5rem);
  pointer-events: none;
  z-index: 0;
  height: 2.875rem - 20px;
  line-height: 2.875rem - 20px;
  color: #808d98;
  border-radius: 0.3125rem 10px 10px 5px;
  font-weight: 300;
}

.space-box {
  height: 100%;
  background: #fff;
  position: absolute;
  /* border: 1px solid; */
  z-index: 1;
  /* left: 84%; */
  right: 0;
}

.file-upload-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  height: 2.875rem;
  background-color: #0f7ad4;
  background-repeat: no-repeat;
  background-image: url("./images/attechment_crential.svg") !important;
  color: #fff;
  background-size: 1.625rem;
  background-position: 50% 50%;
  width: 2.875rem;
  color: #f1f5f8;
  font-weight: 700;
  z-index: 2;
  font-size: 1rem;
  line-height: 3.75rem;
  padding: 0 0.9375rem;
  text-transform: uppercase;
  pointer-events: none;
  border-radius: 4px;
}

.click_pin_icon::before {
  display: none;
}

.click_label_button::before {
  display: none;
}

.ad_cls_btn {
  min-width: 230px !important;
}


.click_pin_icon label {
  background-color: #0f7ad4;
  z-index: 2;
  /* width: 54px; */
  width: 2.875rem !important;
  border-radius: 3px;
}

.click_label_button label,
.click_label_button button {
  background: transparent linear-gradient(284deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 12px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  position: relative;
  justify-content: center !important;
  cursor: pointer;
  color: #fff;
  width: 184px !important;
  display: flex;
}

.click_pin_icon label img {
  max-width: 20px;
}

.file-upload-wrapper:hover {
  background: #3c745793, 10%;
}

.mobile-75 {
  width: 75% !important;
}

/* input {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99;
        height: 3.75rem - 20px;
        margin: 0;
        padding: 0;
        display: block;
        cursor: pointer;
        width: 100%;
    } */
.input-attach {
  position: absolute;
  z-index: 50;
  top: 0.3125rem;
  margin-left: 16%;
  left: 72%;
  color: white;
  cursor: pointer;
}

.checkbox-text {
  text-align: left;
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #808d98;
}

.top-21 {
  top: -1.3125rem;
}

.location_name {
  text-align: left;
  font: normal normal 600 1.8125rem/43px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  text-transform: capitalize;
}

/*end tooltips for creditial */
.dash-head {
  font: normal normal 600 1.375rem/33px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  font-size: 1.375rem;
  font-family: "Poppins";
  font-weight: 6000;
  opacity: 1;
  padding-left: 2.9375rem !important;
  padding-right: 0.625rem !important;
  padding-top: 1.375rem;
  padding-bottom: 1.5rem !important;
  margin: 0;
}

.list-2 {
  width: 100%;
  height: 100%;
  padding: 1.125rem 25px !important;
  margin: 0 !important;
}

/* .bgimage {
    position: absolute;
    background: url("../src/images/loginpage21.png");
    width: 100% !important;
    height: 100vh;
    background-size: 58%;
    background-repeat: no-repeat;
    background-position: 100% 0%;
} */
.padding-bottom {
  padding-bottom: 1.5625rem;
}

.login-error {
  width: 51.5% !important;
  height: auto;
  border-radius: 0 0 0 0;
}

.menuIcons_coll {
  margin: auto;
  width: 1.3125rem;
}

.menuItemss-1.activeel {
  padding-left: 0.9375rem !important;
  margin-left: 0;
}

.width-images {
  width: 4.375rem !important;
  height: 4.375rem !important;
  margin: 0 !important;
  border-radius: 100%;
  padding: 0.625rem;
}

.table-container-1 {
  padding-left: 6.75rem;
  padding-right: 9.375rem;
}

.dailyActive .modal-dialog {
  max-width: 40%;
}

.dailyPatients .modal-dialog {
  max-width: 79% !important;
}

.dailypatients1 table tbody td {
  width: 33%;
}

/* .commen-width {
    width: 30%;
} */
.topp-images {
  width: 12.625rem;
  height: 12.625rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 2.8125rem;
}

.div-1 {
  padding: 1.3125rem 260px 39px 50px !important;
  margin: 0 !important;
  display: flex;
  justify-content: space-between;
}

.Patient-name {
  text-align: left;

  font-size: 1.5rem;
  font-weight: 600;
  font-family: "Poppins";
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.top-img-admin {
  width: 2.9375rem;
}

.div-5 {
  /* display: "flex",
    justifyContent: "space-between",

    padding: "0 1.25rem",
    flexDirection: "column",
} */
  display: flex;
  justify-content: space-between;
  padding: 0rem 20px;
  flex-direction: column;
}

.Current {
  text-align: left;

  font-size: 1rem;
  font-weight: normal;
  font-family: "Poppins";
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.edit-btn {
  background: none;
  border: none;
  text-decoration: underline;
  color: #0f7ad4;
  height: auto;
}

.Choose_msg {
  text-align: left;
  font: normal normal medium 16px/25px Poppins;
  letter-spacing: 0px;
  color: #e37374;
  opacity: 1;
}

@media (max-width: 160rem) and (min-width: 1920px) {
  .bgimage {
    position: absolute;
    background: url("../src/images/login-img_2560.png");
    width: 100% !important;
    height: 100vh;
    background-size: 57%;
    background-repeat: no-repeat;
    background-position: 100%;
    overflow-x: hidden;
  }

  .col1 {
    width: 20%;
  }

  .agency-top {
    left: 75% !important;
  }

  /* .right_side_parent {
        padding-left: 10.5%;
        padding-right: 0rem;
        transition: 0.3s all ease-in-out;
    } */
  .col-1200-3 {
    flex: 0 0 33% !important;
    max-width: 24% !important;
  }

  .newusers .col1 {
    min-width: 25%;
  }
}

@media (max-width: 767px) {
  .cardNotification {
    right: -75px;
    width: 435px !important;
  }

  /* .mobile-75 {
    width: 100% !important;
} */
}

@media (max-width: 641px) {
  .cardNotification {
    right: -26px;
    width: 385px !important;
  }

  .card_notifications_main p.cart_notifi_ctxt {
    font-size: 13px !important;
  }

  .card_notifications_main p.cart_notifi_timeing span.cart_noti_txt {
    font-size: 13px !important;
  }
}

@media (max-height: 641px) {

  .margin-top-myside,
  .margin-bar-sidebar,
  .margin-bar-sidebar-open {
    margin-top: 50% !important;
  }

  .margin-top-myside.sticky-bottom {
    position: unset;
  }

  .sidebar_normal {
    overflow: auto;
  }

  .sidebar_collapse {
    overflow: auto;
  }
}

@media (max-width: 120rem) {
  .home-user-type.active .home-list-content h4 {
    font-size: 1.125rem;
  }

  .home-user-type {
    width: 97.5%;
  }

  .col1-3 {
    position: relative;
    width: 100%;
    min-height: 0.0625rem;
    flex: 0 0 15.666667%;
    max-width: 22.666667%;
  }

  .col-1200-3 {
    flex: 0 0 33% !important;
    max-width: 24% !important;
  }

  /* .right_side_parent {
        padding-left: 14%;
        padding-right: 0rem;
        transition: 0.3s all ease-in-out;
    } */
}

@media (max-width: 120rem) and (min-width: 1430px) {
  .newusers .col1 {
    min-width: 26%;
  }

  .menuItemss-1 {
    padding: 0.625rem 0.625rem 0.6875rem 1.75rem;
  }

  .menuItemss-1.activeee {
    padding: 0.625rem 0.625rem 0.6875rem 1.75rem;
  }

  .right_side_img {
    background: none;
  }

  .login-block {
    display: none;
  }

  .bgimage {
    position: absolute;
    background: url("../src/images/loginpage21.png");
    width: 100% !important;
    height: 100vh;
    background-size: 69%;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    overflow-x: hidden;
  }

  .col1 {
    max-width: 20%;
  }

  .agency-top {
    left: 79% !important;
  }

  .agency-top1 {
    top: -10% !important;
  }
}

@media (max-width: 1440px) {
  * {
    font-size: 0.8rem;
  }
}

@media (max-width: 1440px) and (min-width: 1280px) {
  .input-245 {
    width: 100%;
  }

  .bENZXI {
    width: 100% !important;
  }

  .home-user-type {
    width: 100%;
  }

  /* .spanp {
        font-size: 0.875rem;
    } */
  .progress-check-after,
  .progress-check {
    width: 1.125rem;
  }

  .font-titel {
    font-size: 0.75rem;
  }

  .nav-bar-class {
    height: 60px;
  }

  .table-img {
    width: 3rem;
    height: 3rem;
  }

  .menuItemss-1 {
    margin-bottom: 0%;
  }

  .therepist-sidebar {
    height: 100% !important;
  }

  .agency-sidebar {
    height: 100% !important;
  }

  /* therapist page */

  /* .right_side_parent {
        padding-left: 16%;
        padding-right: 0rem;
        transition: 0.3s all ease-in-out;
    } */
  .top-img-admin {
    width: 1.6875rem;
  }

  .left_side_parent {
    padding-left: 6%;
  }

  .right_side_img {
    background: none;
  }

  .login-block {
    display: none;
  }

  .bgimage {
    position: absolute;
    background: url("../src/images/loginpage21.png");
    width: 100% !important;
    height: 100vh;
    background-size: 77%;
    background-repeat: no-repeat;
    background-position: 100%;
    overflow-x: hidden;
  }

  .col1 {
    max-width: 20%;
  }

  .col-1200-3 {
    flex: 0 0 33% !important;
    max-width: 24% !important;
  }

  .agency-top {
    left: 73% !important;
  }

  .padding-box {
    transition: 300ms;
  }

  .gap-40 {
    gap: 64px;
  }

  .login-main .heading-text h3 {
    font-size: 32px;
  }

  .login-main .heading-text p {
    font-size: 16px;
  }

  .login-main .form-group label {
    font-size: 16px;
  }

  .login-input {
    /* width: 22.625rem !important; */
    font-size: 12.8px !important;
  }

  /* .login-error {
    width: 25.125rem !important;
  } */
  .checkbox1 {
    width: 1.25rem !important;
  }

  .login-logo {
    max-width: 21.875rem;
    margin-bottom: 6.125rem;
  }

  .top-img-admin {
    width: 1.875rem;
  }

  /* .home-user-type .home-list-content h4 {
        font-size: 0.8125rem;
    } */
  /* .home-user-type.active .home-list-content h4 {
        font-size: 13px;
    } */
  .col1 {
    max-width: 20%;
  }

  .home-user-type.active .home-user-type-number {
    /* max-width: 2.5rem;
        height: 2.5rem; */
    background-color: #fff;
    padding: 0.625rem;
    border-radius: 50%;
    object-fit: contain;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .home-user-type .home-user-type-number {
    /* max-width: 2.5rem;
        height: 2.5rem; */
    background-color: #fdeddc;
    padding: 0.625rem;
    border-radius: 50%;
    object-fit: contain;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-right: 0.6875rem;
  }

  .home-user-type .home-user-type-number p {
    font-size: 17.6px;
  }

  .referral_value .home-user-type .home-list-content h4 {
    /* font-size: 11.2px; */
    text-align: left;
    letter-spacing: 0rem;
    color: #0a365b;
    line-height: 1.5rem;
    font-family: "Poppins Medium" !important;
    flex-grow: 1;
    font-weight: normal;
  }

  .home-user-type.active .home-list-content h4 {
    /* font-size: 11.2px; */
    font-family: "Poppins SemiBold" !important;
  }

  .menu-name {
    font-size: 0.9375rem !important;
  }

  .nav-icon-w {
    width: 1.4375rem;
  }

  .chart_content .chart_main_parent h3 {
    font-size: 1.5rem;
    margin: 0;
    line-height: 2.0625rem;
    font-weight: 600;
    color: #0a365b;
    font-family: "Poppins SemiBold";
    font-family: "Poppins", sans-serif;
  }

  .dash-head {
    font-size: 19.2px !important;
    margin: 0;
  }

  .chart_content .chart_main_parent p {
    font-size: 12.8px;
    font-weight: 600;
    margin: 0;
  }

  .padding-bottom {
    padding-right: 0rem !important;
  }

  .date_text svg {
    width: 1.5625rem;
    color: #c3d2de;
  }

  .btn-dob {
    width: 6.0625rem;
    font-family: "Poppins";
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.0625rem solid #a1aeb9;
    border-radius: 1.125rem;
    padding: 0.375rem;
    color: #a1aeb9;
  }

  .right_main_top_nav img {
    width: 1.25rem;
  }

  .right_main_top_nav .top-nav-p {
    font-size: 20.8px;
  }

  .commen-width img {
    width: 3.125rem;
    height: 3.125rem;
  }

  .commen-width p img {
    width: 1.25rem;
    height: 1.25rem;
  }

  .commen-width h1 {
    font-size: 12.8px;
  }

  .title-22 {
    font-size: 1rem;
  }

  .table-width-1 .table thead th {
    width: 38%;
    padding-left: 4rem;
  }

  .table-container-1 {
    padding-left: 2.6875rem;
    padding-right: 5.8125rem;
  }

  .border-bottom td {
    font-size: 0.8125rem;
  }

  .home-user-type {
    padding: 0.75rem 13px;
  }

  .sidebar_normal .menu_icon_slidein {
    padding-right: 0.625rem;
  }

  .patient_main_parent .inner_content_form select {
    width: 12.5625rem;
    height: 2.375rem;
  }

  .table-container-2 {
    padding-left: 2.6875rem !important;
    padding-right: 3.0625rem !important;
  }

  .patient_main_parent .inner_content_form p {
    font-size: 0.8125rem;
  }

  .searching {
    font-size: 0.8125rem !important;
  }

  .menuContainerr {
    height: 100%;
  }

  /* .margin-new .home-user-type {
        width: 16.1875rem;
    } */
  .admin-image {
    width: 4.375rem !important;
    height: 4.375rem !important;
  }

  .sidebar_normal .inner_content_leftbar h4 {
    font-size: 0.8125rem;
  }

  .sidebar_normal .inner_content_leftbar p {
    font-size: 0.8125rem;
  }

  .th-child:nth-child(1) {
    padding-left: 5.31rem !important;
  }

  .Daily-therapist-h5 {
    text-align: left;
    font: normal normal 600 Poppins;
    font-size: 16px;
    letter-spacing: 0rem;
    color: #0a365b;
  }

  .search-bar input {
    font-size: 12.8px !important;
    width: 100%;
  }

  .newusers .home-user-type {
    max-width: 100%;
  }

  .newusers .col1 {
    max-width: 27%;
  }

  /* .menuItemss-1 {
        padding: 0.625rem 10px 11px 38px !important;
    } */

  .view-btn.active.admin-user {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    justify-content: flex-start;
    padding: 0.5rem 2.5rem 0.5rem 0.5rem;
    color: #fff !important;
    font-weight: 100 !important;

    font-size: 0.875rem !important;
    font-family: "Poppins" !important;
  }

  .tr-btn {
    width: 5.625rem !important;
    height: 2.0625rem;
    /* font-size: 0.8125rem !important; */
  }

  /* .table thead th {
        font-size: 0.8125rem !important;
    } */

  /* New tharepist */
  .agency-details1 {
    padding-top: 32px !important;
  }

  .agency-top1 {
    top: -6% !important;
    left: 80% !important;
  }

  .card-top-img {
    width: 160px;
  }

  .therapist-name {
    font-size: 1.125rem !important;
  }

  .icon-center {
    /* width: 50%; */
    font-size: 1.75rem;
    margin-right: 0.625rem;
  }

  .heading-uppercase {
    font-size: 0.6875rem;
  }

  .details-p {
    font-size: 0.8125rem !important;
  }

  .gaping {
    gap: 24px;
  }

  .agency-btn {
    width: 7.5rem !important;
    height: 2.0625rem !important;
    font-size: 0.75rem !important;
  }

  .check-icon {
    width: 1.5625rem;
    height: 1.5625rem;
    margin-left: -1.4375rem;
  }

  .cross-icon {
    width: 1.5625rem;
    height: 1.5625rem;
    margin-left: -2.3125rem;
  }

  .top-link {
    font-size: 0.875rem !important;
  }

  .margin-bar {
    margin-bottom: 24px !important;
  }

  .new-therapist-head {
    font-size: 1.125rem !important;
  }

  .rate-card-m {
    margin-bottom: 16px;
  }

  /* .New-rate-l {
        font-size: 11.2px !important;
    } */
  .add-input {
    height: 2rem;
    padding: 10px;
  }

  .title-24 {
    font-size: 0.875rem;
  }

  .therapist-map .map-box {
    height: 13rem;
  }

  .circel-add {
    width: 2.25rem;
    height: 2.25rem;
  }

  .date-ex {
    font-size: 0.8125rem !important;
  }

  .license-ex {
    font-size: 1rem !important;
  }

  p.p1,
  .p2 {
    width: 2.25rem !important;
    height: 2.25rem !important;
  }

  /* New tharepist  */
  .new-agency {
    font-family: "Poppins SemiBold" !important;
    /* font-size: 0.875rem !important; */
  }

  .new-agencyactive {
    /* font-size: 1.25rem !important; */
    font-family: "Poppins SemiBold" !important;
  }

  .details-email {
    text-align: left;
    font: normal normal normal 0.8125rem/25px Poppins !important;
    font-size: 0.8125rem !important;
    letter-spacing: 0rem;
    color: #0a365b;
    opacity: 1;
  }

  .switch-name {
    text-align: left;
    font: normal normal 600 0.8125rem/23px Poppins;
    letter-spacing: 0rem;
    color: #0f7ad4;
    opacity: 1;
  }

  /* agency reject */
  .m-21 {
    margin-bottom: 21px !important;
  }

  .agen-reject-input-1 {
    width: 100%;
  }

  .agen-reject-check {
    min-width: 1.5625rem !important;
  }

  .agen-reject-body2 {
    font-size: 0.8125rem !important;
    color: #0a365b !important;
  }

  .agen-reject-body {
    font-size: 0.8125rem !important;
  }

  .agen-reject-check-form {
    border-bottom: 0.125rem solid #e4ecf2 !important;
  }

  .reject-lb {
    font-size: 0.75rem !important;
  }

  .agen-reject-head3 {
    font-size: 0.875rem !important;
  }

  .agency-user-type.label-heading.active {
    font-size: 0.875rem !important;
  }

  .card-2 {
    width: 23.125rem;
    height: 5.625rem;
  }

  .card-bottom {
    font-size: 0.75rem !important;
    font-family: "Poppins" !important;
    letter-spacing: 0rem;
    color: #a1aeb9 !important;
    text-transform: uppercase !important;
    opacity: 1;
  }

  .patientDoc p {
    font-size: 0.8125rem;
  }

  .Card-ditails {
    width: 3rem !important;
    height: 3rem !important;
  }

  .icon-1-1 {
    margin-left: -1.3125rem !important;
    margin-right: 0.4375rem !important;
  }

  .btn1 {
    width: 7.5rem !important;
    height: 2.0625rem !important;
    font-size: 0.75rem !important;
  }

  .agen-reject-btn {
    margin-top: 2rem !important;
  }

  /* agency Signup  */
  .cs-input {
    font-size: 0.8125rem !important;
  }

  .cs-input::placeholder {
    font-size: 0.8125rem !important;
  }

  .alert {
    font-size: 0.8125rem !important;
  }

  .ad-headd {
    font-size: 1rem !important;
    padding-bottom: 0.625rem;
  }

  .ad-activee {
    font-size: 1rem !important;
    padding-bottom: 0.625rem;
    border-bottom: 0.125rem solid #0f7ad4;
    font-family: "Poppins Bold";
  }

  .lable-1440 {
    font-family: "poppins Medium";
  }

  .input-1440 {
    width: 133% !important;
  }

  .set-head {
    font-size: 28.8px;
  }

  .py-input::placeholder {
    font-size: 12.8px;
  }

  .py-input {
    font-size: 12.8px;
    width: 100%;
    height: 2.5rem;
  }

  .py-input1::placeholder {
    font-size: 12.8px;
  }

  .py-input[type="text"] {
    font-size: 12.8px !important;
  }

  .py-input1 {
    font-size: 12.8px;
    width: 100%;
    height: 2.5rem !important;
  }

  .py-input1[type="text"] {
    font-size: 12.8px !important;
  }

  .py-input1[type="numbers"] {
    font-size: 12.8px !important;
  }

  .py-input[type="numbers"] {
    font-size: 12.8px !important;
  }

  .py-input1[type="expirationDate"] {
    font-size: 12.8px !important;
  }

  .py-input1:focus {
    font-size: 12.8px;
    width: 100%;
  }

  .py-input1 .form-control::placeholder {
    font-size: 12.8px;
  }

  .py-input1 .form-control {
    font-size: 12.8px;
    width: 100%;
    /* height: 2.375rem !important; */
  }

  .form-control[type="text"] {
    font-size: 12.8px !important;
  }

  .py-p {
    font-size: 0.8125rem;
  }

  .py-lb {
    font-size: 0.875rem;
  }

  .cs-heading {
    font-family: "Poppins SemiBold";
  }

  .terms p {
    font-size: 0.875rem;
  }

  .terms {
    height: 25rem;
  }

  .tclb {
    font-size: 0.875rem;
  }

  .tcch {
    width: 1.3125rem;
  }

  .AgencyModal .modal-dialog {
    max-width: 37.5rem;
  }

  .img-modal {
    width: 100%;
    padding-left: 21%;
    padding-right: 21%;
  }

  .ss-h4 {
    text-align: center;
    font: normal normal 600 1.375rem/55px Poppins SemiBold;
    letter-spacing: 0rem;
    color: #0a365b;
    opacity: 1;
  }

  .ss-p {
    text-align: left;
    font: normal normal 600 0.6875rem/20px Poppins;
    letter-spacing: 0rem;
    color: #808d98;
    opacity: 1;
  }

  .padding-m-1 {
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .w-550 {
    width: 26.5625rem;
  }

  .w-270 {
    width: 13rem;
  }

  /* tharepist steps */
  .therapist-inputs {
    width: 1.4375rem;
    height: 2.5rem;
  }

  .set-head-th {
    font-size: 27.04px;
  }

  .hover-icon1 {
    width: 1.25rem;
  }

  .input-1440-2 {
    width: 100%;
    height: 2.25rem;
  }

  .input-1440-2[type="numbers"] {
    font-size: 12.8px !important;
  }

  .alert {
    font-size: 0.6875rem !important;
  }

  .displayname-rates {
    font-size: 12.8px;
  }

  .navImg {
    width: 10.9375rem;
    padding: 0.25rem;
    padding-left: 0;
  }

  .active-ca {
    font-size: 0.8125rem !important;
  }

  .ca-loginBtn {
    width: 6.875rem;
    height: 2.3125rem;
  }

  .coverage-btn {
    width: 16.0625rem;
    height: 2.4375rem;
    font-size: 0.8125rem !important;
  }

  .ad-btn-back {
    height: 2.4375rem;
    font-size: 0.8125rem !important;
  }

  .btn-add-location {
    border: none;
  }

  .map-box {
    width: 52.4375rem;
    height: 23.9375rem;
  }

  .exp-body {
    font-size: 0.8125rem !important;
    color: #808d98 !important;
  }

  .exp-plus {
    width: 3.375rem;
    height: 2.4375rem;
  }

  /* button,
    input,
    optgroup,
    select,
    textarea {
        font-size: 0.875rem !important;
    } */

  .pd-dt {
    font-size: 0.8125rem;
  }

  .cs-date {
    font-size: 0.75rem !important;
  }

  .w9 {
    font-size: 0.75rem;
    font-family: "Poppins SemiBold";
    margin-bottom: 0.5rem !important;
  }

  .cred-p {
    font-size: 0.875rem !important;
  }

  .file-upload-wrapper:after {
    content: attr(data-text);
    font-size: 0.8125rem;
  }

  .file-upload-wrapper:before {
    height: 2.4375rem;
  }

  .cred-input {
    height: 2.4375rem !important;
    /* width: 17.125rem !important; */
  }

  .cred-date {
    height: 2.4375rem !important;
    /* width: 11.875rem !important; */
  }

  .cred-date[type="date"] {
    font-size: 0.8125rem !important;
  }

  .np-p {
    font-family: "Poppins";
    font-size: 0.875rem !important;
    font-weight: 100;
  }

  .np-it-h6 {
    font-size: 0.875rem !important;
  }

  .np-ch {
    min-width: 1.5625rem;
  }

  .np-lb {
    font-size: 0.875rem;
  }

  /* campliance page */
  .congratulation-box {
    width: 59.375rem;

    background: #e1ffe2 0% 0% no-repeat padding-box;
    border: 0.0625rem solid #3bef42;
    border-radius: 0.25rem;
    opacity: 1;
    padding: 0.8125rem 23px;
    margin-top: 3.125rem;
  }

  .congratulation-box h4 {
    text-align: left;
    letter-spacing: 0rem;
    color: #37b42a;
    font-size: 0.875rem;
    opacity: 1;
    margin-bottom: 0;
  }

  .congratulation-box p {
    text-align: left;
    letter-spacing: 0rem;
    font-size: 0.75rem;
    color: #0ba711;
    opacity: 1;
    margin: 0rem;
  }

  .compliance-head {
    font-size: 1.875rem;
  }

  .compliance-margin {
    margin-top: 0.625rem;
    margin-bottom: 0rem;
  }

  /* .box-step {
        width: 100%;
    } */
  .check-icon-1 {
    align-self: flex-end;
    /* align-items: self-start; */
    transform: translate(6.375rem, -70px);
  }

  .font-32 {
    font-size: 2rem !important;
  }

  .font-14 {
    font-size: 0.875rem !important;
  }

  .progressbar li {
    font-size: 0.8125rem;
  }

  .agency-name-p {
    font-size: 1.5rem;
  }

  .paymentInfo {
    font-size: 0.875rem;
    gap: 4% !important;
  }

  .padding-gap-profile {
    height: 4.375rem !important;
    font-size: 0.875rem !important;
    padding-left: 5% !important;
    gap: 4% !important;
  }

  .tab-01,
  .tab-02 {
    width: 30px !important;
    height: 30px !important;
  }

  .Profile-text {
    height: 2.25rem;
  }

  .Profile-text[type="text"] {
    font-size: 0.75rem !important;
  }

  .Profile-lable {
    font-size: 0.875rem !important;
  }

  .right_sec {
    width: 75%;
    /* scale: 75%;
        transform: translate(-8.9375rem, -17%); */
  }

  .lableField {
    font-size: 0.875rem !important;
  }

  /* .profile-icon {
        width: 7.5rem;
        height: 7.5rem;
    }
    .upload-P {
        margin-bottom: 0;
        font-size: 0.875rem;
    }
    .browse-f {
        font-family: "Poppins";
        font-weight: 500 !important;
    } */
  /* .sidebar_normal {
        width: 3.125rem;
    } */
  .profile-section {
    scale: 75%;
    transform: translate(-3.75rem, 0px);
  }

  .radio-name {
    font-size: 0.8125rem;
  }

  .radio-inout-tharepist {
    width: 1.75rem;
    height: 1.75rem;
  }

  .home-list-content .rate-p {
    font-size: 2.0625rem !important;
  }

  .home-list-content .rate-p span {
    font-size: 0.875rem !important;
  }

  .Common-referral-icons {
    width: 1.75rem;
  }

  /* .referral_name {
    font-size: 0.8rem !important;
  } */

  /* .referral_value {
    font-size: 1rem !important;
  } */
  .referral_name {
    margin-left: 1rem !important;
  }

  .m-8 {
    margin-bottom: 3px !important;
  }

  .referral_info {
    margin-left: 0 !important;
  }

  .userimge1 {
    width: 10.625rem;
    height: 10.625rem;
  }

  .f-color {
    font-size: 0.875rem;
  }
}

@media (max-width: 1280px) {
  /* .login-error {
    width: 21.8rem !important;
  } */
  /* sidebar */

  /* sidebar */

  /* .home-user-type .home-list-content h4 {
        font-size: 0.75rem;
        line-height: 0.9375rem;
    } */
  .home-user-type.active {
    font-size: 0.75rem;
    line-height: 0.9375rem;
  }

  .menu-name {
    font-size: 1rem;
  }

  .menu-name.active {
    font-size: 1rem;
  }
}

@media (max-width: 85.375rem) {

  /* .home-user-type .home-list-content h4 {
        line-height: 1.0625rem;
    } */
  .home-user-type.active {
    line-height: 1.0625rem;
  }

  .sidebar_normal img {
    padding-left: 0.625rem;
  }

  .padding-box {
    padding-left: 6% !important;
    transition: 100ms;
  }
}

.top-nav-p {
  font-weight: 600;
  font-family: "Poppins SemiBold";
  font-family: "Poppins", sans-serif;
  font-size: 1.75rem;
}

@media (max-width: 1280px) and (min-width: 100px) {
  .check-icon-1 {
    transform: translate(6.5rem, -74px);
  }

  .right_side_parent,
  .left_side_parent {
    padding: 0 !important;
  }

  .left_side_parent {
    padding-left: 4.375rem !important;
  }

  .input-245 {
    width: 100%;
  }

  .bENZXI {
    width: 100% !important;
  }

  .top-img-admin {
    width: 1.6875rem;
  }

  .row1 {
    margin-right: 1% !important;
    margin-left: 3% !important;
  }

  .home-user-type.active {
    font-size: 0.75rem;
    line-height: 0.9375rem;
  }

  /* .home-user-type .home-list-content h4 {
        font-size: 0.75rem;
        line-height: 0.9375rem;
    } */
  .list-2 {
    padding: 1.1875rem 10px !important;
  }

  .input-error {
    width: 29.6875rem;
  }

  .bgimage {
    position: absolute;
    background: url("../src/images/login-img_1200x800.png");
    width: 100%;
    height: 100vh;
    background-size: 77%;
    background-repeat: no-repeat;
    background-position: 100%;
    overflow-x: hidden;
  }

  .col1 {
    max-width: 33.333%;
  }

  .col-1200-3 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

.col {
  width: 18%;
}

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 0.0625rem;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.col-xs-15 {
  width: 20%;
  float: left;
}

/* @media (max-width: 800px) and (min-width: 100px) {
    body {
        width: 100vw !important;
    }
} */
@media (min-width: 48rem) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 62rem) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 75rem) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}

/* patient details */

.patient-div1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 0.9375rem;
}

.patient-box1 {
  padding: 1.875rem 29px 30px 50px;
  display: flex;
  box-shadow: 0rem 3px 10px #00000029;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 0.625rem;
}

.patient-text {
  font-size: 0.875rem;
  line-height: 1.5625rem;
  width: 76%;
}

.PatientDetails-btn {
  text-align: left;
  font: normal normal 600 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #d42b2b;
  opacity: 1;
}

/* Modals In Sttafing */
.PenddingM .modal-dialog {
  max-width: 90.5rem;
}

.PenddingM {
  left: 8px !important;
}

.ApprovM .modal-dialog {
  max-width: 101.6875rem;
}

.payment-popup .modal-dialog {
  max-width: 82rem;
}

.therapist_heading .th-1 {
  width: 20% !important;
}

p.tableApprovText {
  width: 12rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-row td {
  padding: 0.625rem;
}

.Patient-01 .modal-dialog {
  max-width: 101.6875rem;
}

.M_Patinet {
  display: flex;
  border: 0.0625rem solid #d5e3ee;
  border-radius: 0.5rem;
  justify-content: flex-start;
  gap: 5%;
  /* width: 24.25rem; */
  margin-bottom: 1.25rem;
  align-items: center;
  padding: 0.5rem 15px;
}

.M_Patinet1 {
  display: flex;
  border: 0.0625rem solid #d5e3ee;
  border-radius: 0.5rem;
  justify-content: flex-start;
  gap: 5%;
  /* width: 24.25rem; */
  margin-bottom: 1.25rem;
  align-items: center;
  padding: 1.875rem 19px;
}

.Card-ditails {
  width: 4.25rem;
  height: 4.25rem;
  border-radius: 50%;
  background-color: #dfeffc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Declined-01 .modal-dialog {
  max-width: 101.6875rem;
}

.patient-p {
  font-weight: bold;
  color: #0a365b;
  font-size: 1rem;
  line-height: 24px;
}

.patient-p1 {
  color: #0a365b;
  font-size: 1rem;
  line-height: 24px;
}

.patient-h5 {
  font-size: 1.375rem;
  color: #0a365b;
  margin-top: 3.125rem;
  margin-bottom: 1.5rem;
}

.right-h5 {
  font-size: 1.375rem;
  color: #0a365b;
  margin-bottom: 1.375rem;
  font-weight: bolder;
}

.td-name1 {
  text-align: left;
  font: normal normal bold 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.CreatAdmin .modal-dialog {
  max-width: 39.3125rem;
}

.user-modal .modal-dialog {
  max-width: 37.25rem;
}

.margin-new {
  margin-top: 34px !important;
}

/* compliance */
.Cinfo-box {
  width: 76.875rem;
  height: 5.9375rem;
  background: #e1ffe2 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #3bef42;
  border-radius: 0.25rem;
  opacity: 1;
  /* padding: 0.8125rem 0 ; */
}

.rate-margin {
  margin-bottom: 1.3125rem;
}

.head-margin {
  margin-bottom: 1.9375rem;
  border-bottom: 0.125rem solid #e4ecf2 !important;
}

.div-head {
  margin-bottom: 1.625rem;
  margin-top: 4.125rem;
}

.New-rate-l {
  text-align: left;
  font: normal normal medium 1rem/25px Poppins;
  font-family: "Poppins Medium";
  letter-spacing: 0rem;
  line-height: 1.5625rem;
  color: #0a365b;
  opacity: 1;
}

.new-agencyactive {
  text-align: left;
  font: normal normal bold 1.25rem/30px Poppins;
  font-size: 1.25rem;
  font-family: bold;
  font-family: "Poppins";
  letter-spacing: 0rem;
  color: #0f7ad4;
  opacity: 1;
  border-bottom: 0.0625rem solid #0f7ad4;
  padding-bottom: 0.75rem;
  margin: 0;
  cursor: pointer;
}

.new-agency {
  text-align: left;
  font: normal normal 600 1.125rem/27px Poppins;
  font-size: 1.125rem;
  letter-spacing: 0rem;
  color: #808d98;
  opacity: 1;
  cursor: pointer;
}

.therapist-1 {
  padding-top: 1.75rem;
}

.therapist-name {
  text-align: left;
  font: normal normal 600 1.5rem/2.1875rem Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  text-transform: capitalize;
  /* width: 25.25rem; */
}

.new-therapist-head {
  text-align: left;
  font: normal normal 600 1.5rem/35px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  padding: 0;
}

/* new users New Agency */

.Email-width {
  width: 100%;
}

.Email-width .Email-gap {
  gap: 2.5625rem;
}

.Email-gap .Email-gap1 {
  gap: 5.625rem;
}

.Email-gap .switch-agency {
  gap: 5.625rem !important;
}

.Email-gap1 .react-switch-bg {
  margin-left: 0rem !important;
}

.width-name-1 {
  max-width: 30%;
}

.width-name-2 {
  width: 30%;
}

.bg-email {
  background: #f1f5f8 0% 0% no-repeat padding-box;
  box-shadow: 0rem 3px 4px #00000029;
  border: 0.0625rem solid #eaebeb;
  border-radius: 0.25rem;
  opacity: 1;
  padding: 1rem 0px;
}

/* .Email-gap .switch-btn {
        width: 40% !important;
    }
    .Email-gap .pl-3 {
        padding-left: 288px !important;
    } */
.padding-fixed {
  padding: 0rem 46px !important;
  padding-right: 0.625rem !important;
}

.top-link-active-green {
  color: #0ba711 !important;
  border-top: 0.3125rem solid #0ba711;
}

.top-link-active-red {
  color: #e71d1d !important;
  border-top: 0.3125rem solid #e71d1d;
}

.top-link-active-blue {
  border-top: 0.3125rem solid #0f7ad4;
}

/* .radio-name {
    text-align: left;
    font: normal normal normal 1rem/25px Poppins;
    letter-spacing: 0rem;
    color: #808d98;
    opacity: 1;
}
.radio-inout-tharepist {
    width: 2.25rem;
    height: 2.25rem;
  
    border: 0.0625rem solid #b6c4ce;
    border-radius: 5.375rem;
} */
.margin-radio-b {
  margin-bottom: 73px !important;
  margin-top: 16px;
}

.margin-head-1 {
  margin-top: 4.5rem;
  margin-bottom: 2.25rem;
}

.padding-6-p {
  padding: 6%;
}

.update-btn,
.update-btn-new {
  text-align: start;
  width: 8.75rem;
  height: 2.875rem;
  background: transparent linear-gradient(300deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  border: none;
  color: #fff;
  padding: 0.3125rem;
  align-items: center;
  gap: 10%;
}

.update-btn {
  display: flex;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.dropdown-div {
  border: 1px solid #B6C4CE;
  height: 46px;
  width: 450px;
  margin-right: 25px;
  text-align: start !important;
}

.custom-dropdown-menu {
  position: relative;
  transform: translate(0px, 34px) !important;
  width: 450px;
  font-size: 333px;
}

.reason-rejection-header {
  font-size: 24px;
  font-family: 500;
  line-height: 36px;
}

.cred-checkbox {
  font-size: 14px !important;
  font-family: 400 !important;
  line-height: 21px !important;
  color: #808D98 !important
}

.fields-1 {
  padding: 0.625rem !important;
}

.bsChevroDown {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}

/* agecy Profile tabs */
.agencyInfo {
  width: 100%;
  height: 5.1875rem;
  /* UI Properties */
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 0.75rem;
  border: 0.0625rem solid #b4d1e8;
  opacity: 1;
  display: flex;
  text-align: left;
  font: normal normal 600 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.paymentInfo {
  width: 100%;
  height: 5.1875rem;
  /* UI Properties */
  background: #e8e6ff 0% 0% no-repeat padding-box;
  border-radius: 0.75rem;
  opacity: 1;
  display: flex;
  text-align: left;
  font: normal normal 600 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.ProfessionalInfo {
  width: 100%;
  height: 5.1875rem;
  /* UI Properties */
  background: #fae6ff 0% 0% no-repeat padding-box;
  border-radius: 0.75rem;
  opacity: 1;
  display: flex;
  text-align: left;
  font: normal normal 600 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.gap-60 {
  gap: 60px;
}

.defualtRate {
  width: 100%;
  height: 5.1875rem;
  /* UI Properties */
  background: #fdeddc 0% 0% no-repeat padding-box;
  border-radius: 0.75rem;
  opacity: 1;
  display: flex;
  text-align: left;
  font: normal normal 600 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.documents {
  width: 100%;
  height: 5.1875rem;
  /* UI Properties */
  background: #d9f8dc 0% 0% no-repeat padding-box;
  border-radius: 0.75rem;
  opacity: 1;
  display: flex;
  text-align: left;
  font: normal normal 600 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.blockList {
  width: 100%;
  text-align: center;
  font: normal normal 600 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
  height: 5.1875rem;
  /* UI Properties */
  background: #f8d9d9 0% 0% no-repeat padding-box;
  border-radius: 0.75rem;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.tab-01 {
  width: 48px;
  height: 48px;
  background: #dfeffc 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tab-02 {
  width: 48px;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.top-margin-Profile {
  margin-top: 45.008px;
  margin-bottom: 41.008px;
}

.Profile-lable {
  text-align: left;
  font: normal normal 600 1.125rem/27px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.Profile-text[type="text"] {
  text-align: left;
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #808d98;
  opacity: 1;
}

.Profile-text .form-control[type="text"] {
  text-align: left;
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #808d98;
  opacity: 1;
}

@media (max-width: 85.375rem) {
  .login-logo img {
    width: 100%;
  }

  .login-main .heading-text h3 {
    font-size: 30.4px;
  }

  .login-main .heading-text p {
    font-size: 14.4px;
  }

  .login-input {
    /* width: 18.9375rem !important; */
    font-size: 12.8px !important;
  }

  .login-button {
    width: 6.25rem;
    height: 1.9375rem;
    border-radius: 1.875rem !important;
    border: none !important;
    background: linear-gradient(90deg,
        rgba(15, 122, 212, 1) 8%,
        rgba(106, 64, 212, 1) 98%);
    color: white;
    font-size: 0.875rem !important;
    cursor: pointer;
  }
}

.user-heading-01 {
  text-align: left;
  font: normal normal 600 1.5rem/35px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.newusertable .table-newuser table tr th {
  width: 29%;
}

.Cancel-red {
  background-color: #e71d1d !important;
  color: #ffffff;
  border: none;
}

.Cancel-red.disabled {
  background-color: lightgray !important;
  color: gray !important;
  border: none;
  cursor: default;
}

.Cancel-red.disabled:hover {
  background-color: lightgray !important;
}

.Cancel-red:hover {
  background: #e71d1d !important;
  color: #ffffff;
  border: none;
}

.secDignosisList {
  width: fit-content;
  height: 2.25rem;
  /* UI Properties */
  background: transparent linear-gradient(300deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  text-align: center;
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-bottom: 1%;
  margin-top: 1%;
  border: none;
  padding-right: 2.5%;
}

.btn-new-referral {
  width: 18.75rem;
  height: 2.875rem;
  /* UI Properties */
  border: 0.0625rem solid lightgray !important;
  background: transparent linear-gradient(300deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box !important;
  border-radius: 6.25rem !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #0a365b !important;
  display: inline-block;
  align-items: center;
  gap: 15%;
  margin-bottom: 1%;
  justify-content: center;
  margin-top: 1%;
  border: none;
}

.btn-new-referral:hover {
  width: 18.75rem;
  height: 2.875rem;
  /* UI Properties */
  background: transparent linear-gradient(300deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box !important;
  border-radius: 6.25rem !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #ffffff !important;
  display: inline-block;
  align-items: center;
  justify-content: center;
  gap: 15%;
  margin-bottom: 1%;
  margin-top: 1%;
  border: none;
}

.secDignosisList:focus {
  box-shadow: none !important;
  outline: none;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
}

.css-4ljt47-MenuList .css-1n7v3ny-option,
.css-gg45go-NoOptionsMessage {
  font-size: 1rem !important;
}

.new-password {
  text-align: left;
  font: normal normal 600 2.25rem/55px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.old-password {
  text-align: left;
  font: normal normal 600 1.125rem/27px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.new-password-input {
  width: 34.375rem;
  height: 2.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  opacity: 1;
}

.set-password-btn {
  width: 18.75rem;
  height: 2.875rem;
  background: transparent linear-gradient(300deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 6.25rem !important;
  opacity: 1;
}

.cancel-password-btn {
  width: 12.5rem;
  height: 2.875rem;
  background: lightgray 0% 0% no-repeat padding-box !important;
  color: rgb(46, 46, 46) !important;
  border-radius: 6.25rem !important;
  opacity: 1;
}

.agency-p-1-h {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #a1aeb9;
  text-transform: uppercase;
  opacity: 1;
}

.agency-p-1 {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.map-flex {
  height: 100%;

  /* flex-direction: column; */
}

.map-flex2 {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  /* flex-direction: column; */
}

.new-thera-map .map-flex {
  display: flex;
  /* flex-wrap: wrap; */
  height: 100%;
  gap: 1.875rem;

  /* flex-direction: column; */
}

.agency-map-1.therapist__profile_map .map-box {
  width: 530px;
  height: 280px;
  margin-top: 0;
  border-radius: 6px;
}

.therapistUpdateClass div#map_parent {
  display: grid;
  grid-template-columns: 40% 40%;
  grid-template-rows: auto;
}

.agency-map-1.therapist__profile_map.therapistUpdateClass .map-box {
  width: 100%;
  height: 350px;
}

.map-box {
  width: 33.125rem;
  height: 18.75rem;
  margin-top: 0;
}

.agency-map-1 .map-flex {
  flex-direction: row;
  gap: 48px !important;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.notify-head {
  text-align: left;
  font: normal normal 600 1.375rem/33px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.rate-p {
  text-align: left;
  font: normal normal 600 2.625rem/24px Poppins;
  letter-spacing: 0rem;
  font-size: 2.625rem !important;
  color: #3ab746;
  opacity: 1;
}

.rate-p span {
  /* UI Properties */
  text-align: left;
  font: normal normal 600 1.125rem/24px Poppins;
  letter-spacing: 0rem;
  font-size: 1.125rem !important;
  color: #547e58 !important;
  opacity: 1;
}

.therapist-d-1 .home-list .home-list-content {
  display: inline-block;
}

.therapist-d-1 .home-list .home-list-content h4 {
  margin-bottom: 2rem !important;
}

.therapist-d-1 .home-list.list1 {
  height: 9.875rem;
  align-items: center;
  cursor: pointer;
}

.mb-3_5 {
  margin-bottom: 34px !important;
}

.mb-25 {
  margin-bottom: 25px;
}

.therapist-d-1 .home-list.list2 {
  height: 9.875rem;
  align-items: center;
  cursor: pointer;
}

.therapist-d-1 .home-list.list3 {
  height: 9.875rem;
  align-items: center;
  cursor: pointer;
}

.paitiant-chart-input input:disabled {
  background: #000 !important;
}

.custom-switch {
  position: relative;
  display: inline-block;
  width: 3.625rem;
  height: 1rem;
}

.custom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b6c4ce;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom-switch .slider:before {
  position: absolute;
  content: "";
  width: 1.875rem;
  height: 1.875rem;
  left: 0rem;
  bottom: -0.3125rem;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0.0625rem 1px 2px 1px rgba(0, 0, 255, 0.2);
}

.custom-switch input:checked+.slider {
  background-color: #c5f6be;
}

.custom-switch input:checked+.slider:before {
  background-color: #60d670;
}

.custom-switch input:focus+.slider {
  box-shadow: 0 0 0.0625rem #2196f3;
}

.custom-switch input:checked+.slider:before {
  -webkit-transform: translateX(1.625rem);
  -ms-transform: translateX(1.625rem);
  transform: translateX(1.625rem);
}

.custom-switch .slider.round {
  border-radius: 2.125rem;
}

.custom-switch .slider.round:before {
  border-radius: 50%;
}

.reject-map .map-flex .map-box {
  margin-top: 0 !important;
}

.ad-input {
  font-size: 1rem;
  display: flex;
  align-items: center;
  -moz-appearance: textfield;
}

.patientDoc-p {
  text-align: left;
  font: normal normal medium 0.875rem/24px Poppins;
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  letter-spacing: 0rem;
  color: #0f7ad4 !important;
  opacity: 1;
}

.patient-card-div {
  display: flex;
  gap: 2.375rem;
  align-items: center;
}

.patient-card-div-2 {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
}

.patient-card-span {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #a1aeb9;
  text-transform: uppercase;
  opacity: 1;
}

.patient-card-span-2 {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.patient-card-p {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #a1aeb9;
  opacity: 1;
}

.patient-pt {
  text-align: left;
  font: normal normal 600 1rem/25px Poppins;
  letter-spacing: 0rem;
  color: #ffffff;
  opacity: 1;
}

.Patient-lable {
  text-align: left;
  font: normal normal medium 1rem/24px Poppins;
  font-size: 1rem !important;
  letter-spacing: 0rem;
  color: #a1aeb9;
  opacity: 1;
}

.new-thera-icons {
  width: 2.875rem;
  height: 2.625rem;
}

/* Table Seprete */

.table-seprate table {
  border-collapse: collapse;
  border-spacing: 25px;
  -webkit-border-horizontal-spacing: 6px;
}

.table-seprate table tbody tr {
  border-bottom: 1px solid #dee2e6 !important;
  border-color: #dee2e6 !important;
  border-collapse: collapse !important;
}

.table-seprate table tbody tr td {
  border: 0 !important;
}

.table_inside_gaping {
  padding: 0 0 0 44px;
}

.color-Pending {
  background: #fdeddc 0% 0% no-repeat padding-box;
  border-radius: 18px;
  width: fit-content;
  padding: 9px 71px;
  white-space: nowrap;
  height: 39px;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: 0px;
  color: #ca7718 !important;
  opacity: 1;
}

.color-ToBeScheduled,
.color-ToBeCo-Signed,
.color-NeedsCorrection {
  background: #fddcdc 0% 0% no-repeat padding-box;
  border-radius: 18px;
  width: fit-content;
  padding: 9px 41px;
  white-space: nowrap;
  height: 39px;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: 0px;
  color: #d42b2b !important;
  opacity: 1;
}

.margin-left-35 {
  margin-left: 35px !important;
}

.table-seprate .th-child:nth-child(1) {
  padding-left: 8rem;
}

.table-seprate .th-1,
.table-seprate .th-2 {
  display: flex;
  justify-content: start;
}

.agency-margiin-left {
  margin-left: 1rem;
}

.margin-1_rem .form-group label {
  margin: 0;
  /* margin-bottom: 8px; */
  top: 28.4375rem;
  left: 22.5625rem;
  /* height: 1.4375rem; */
  text-align: left;
  font: normal normal normal 0.75rem/1.5rem Poppins;
  font-size: 1rem;
  letter-spacing: 0rem;
  color: #a1aeb9;
  text-transform: uppercase;
  opacity: 1;
}

.patient_details_label {
  margin-bottom: 10px !important;
}

.form-group-addmore {
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #0f7ad4;
  opacity: 1;
  display: flex;
  align-items: center;
  height: 100%;
}

.patient_details.document-tab span {
  color: #d42b2b;
}

.uploaded_list {
  color: #2e2c34 !important;
}

/* ---------------------------------- */

/* The container */
.container-card {
  display: block;
  position: relative;
  padding-left: 2.1875rem;
  cursor: pointer;
  font-size: 1.375rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-card input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.25rem;
  height: 2.25rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
}

/* When the checkbox is checked, add a blue background */
.container-card input:checked~.checkmark1 {
  background-color: #0f7ad4;
  border: 0.0625rem solid #0f7ad4;
  opacity: 1;
}

.container-card input:disabled~.checkmark1 {
  opacity: 0.3;
  cursor: not-allowed;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark1::after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-card input:checked~.checkmark1::after {
  display: block;
}

/* Style the checkmark/indicator */
.container-card .checkmark1::after {
  left: 0.8125rem;
  top: 0.4375rem;
  width: 0.4375rem;
  height: 0.9375rem;
  border: solid white;
  border-width: 0 0.1875rem 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* ---------------------------------------------------- */

/* chatbox  */

.container-card {
  display: block;
  position: relative;
  padding-left: 2.1875rem;
  cursor: pointer;
  font-size: 1.375rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-card input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-chat1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
}

/* When the checkbox is checked, add a blue background */
.container-card input:checked~.checkmark-chat1 {
  background-color: #0f7ad4;
  border: 0.0625rem solid #0f7ad4;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-chat1 ::after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-card input:checked~.checkmark-chat1 ::after {
  display: block;
}

/* Style the checkmark/indicator */
.container-card .checkmark-chat1 ::after {
  left: 0;
  top: 0;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* --------------- */
/* The container */
.container2 {
  display: block;
  position: relative;
  padding-left: 2.1875rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  font-size: 1.375rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.25rem;
  height: 2.25rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
}

/* When the checkbox is checked, add a blue background */
.container2 input:checked~.checkmark1 {
  background-color: #0f7ad4;
  border: 0.0625rem solid #0f7ad4;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark1::after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container2 input:checked~.checkmark1::after {
  display: block;
}

/* Style the checkmark/indicator */
.container2 .checkmark1::after {
  left: 0.8125rem;
  top: 0.4375rem;
  width: 0.4375rem;
  height: 0.9375rem;
  border: solid white;
  border-width: 0 0.1875rem 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* ----------------- radio ----------------------  */

/* The container */
.container-2 {
  display: block;
  position: relative;
  padding-left: 2.1875rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  font-size: 1.375rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.25rem;
  height: 2.25rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 6.25rem;
}

/* When the checkbox is checked, add a blue background */
.container-2 input:checked~.checkmark-2 {
  background-color: #0f7ad4;
  border: 0.0625rem solid #0f7ad4;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-2:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-2 input:checked~.checkmark-2:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-2 .checkmark-2:after {
  left: 0.5rem;
  top: 0.5rem;
  width: 1.125rem;
  height: 1.125rem;
  background: white;
  border-radius: 6.25rem;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.p-25 {
  padding-top: 1.5625rem !important;
  padding: 0rem;
}

.new-therapist-new .map-flex {
  gap: 1.875rem;
  flex-wrap: wrap;
}

.marging-bottom-thera {
  margin-bottom: 4.5625rem !important;
}

.therapist-image-doc {
  width: 1.75rem;
  height: 1.6875rem;
}

.heading_1-a {
  line-height: 1.6875rem !important;
}

.upload-bbtn-1 {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #a1aeb9;
  text-transform: uppercase;
  opacity: 1;
}

.pr-50 {
  padding-right: 104px !important;
}

.m-8 {
  margin-bottom: 8px;
}

.th-table-pt-1 {
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #a1aeb9;
  text-transform: uppercase;
  opacity: 1;
}

.accordion-button::after {
  background-image: url(images/accordian_expand_icon.svg);
  background-size: 100%;
  width: 2.4375rem;
  height: 2.375rem;
  position: relative;
  z-index: 777;
  top: 0;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(images/accordian_expand_icon.svg);
  background-size: 100%;
}

.accordion-body .accordion-button::after {
  background-image: url(images/accordian_collapse_icon_blue.svg);
  background-size: 100%;
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 777;
  top: 0;
}

.accordion-body .accordion-button:not(.collapsed)::after {
  background-image: url(images/accordian_collapse_icon_blue.svg);
  background-size: 100%;
}

.col1 .home-user-type {
  width: 18.375rem;
}

.commen2 .home-user-type {
  width: 97.5%;
}

@media (min-width: 48rem) {
  .col1-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 27.333333% !important;
  }
}

.heading-uppercase1 {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #a1aeb9;
  text-transform: uppercase;
  opacity: 1;
}

.page-titele-agency {
  text-align: left;
  font: normal normal 600 1.5rem/35px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.sub-title1 {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #a1aeb9;
  text-transform: uppercase;
  opacity: 1;
}

.sub-title1-p {
  text-align: left;
  font: normal normal normal 1rem/24px Poppins;
  letter-spacing: 0rem;
  color: #0a365b;
  opacity: 1;
}

.cancel-btn-1 {
  width: 18.75rem;
  height: 2.875rem;
  /* UI Properties */
  background: transparent linear-gradient(285deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 5.375rem;
  opacity: 1;
  border: none;
  /* UI Properties */
  text-align: center;
  letter-spacing: 0rem;
  color: #ffffff;
  opacity: 1;
  font-size: 1.125rem;
  font-family: "Poppins";
  font-weight: normal;
  text-transform: capitalize;
}

.episodes {
  font-size: 1.5rem;
}

.text-resize {
  resize: none;
  width: 1051px;
  height: 107px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b6c4ce;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.text-resize[type="text"] {
  resize: none;
  width: 1051px;
  height: 107px;
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #000 !important;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.text-resize:focus {
  outline: none;
  box-shadow: none;
}

.cancel-1 {
  width: 140px;
  height: 46px;
  background: transparent linear-gradient(300deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 86px;
  border: none;
  opacity: 1;
  color: #fff;
  font-size: 16px;
  margin-bottom: 24px;
  margin-left: 24px;
}

.icons-update {
  width: 2.1875rem;
}

.Accepted-green {
  background-color: #d9f8dc !important;
  color: #17a325 !important;
  padding: 0.25rem 1.0625rem;
  width: 6.25rem;
  border-radius: 5.375rem;
  height: 1.75rem;
  font-size: 0.875rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Accepted-red {
  background-color: #fddcdc !important;
  color: #e71d1d !important;
  padding: 0.25rem 1.0625rem;
  width: 6.25rem;
  border-radius: 5.375rem;
  height: 1.75rem;
  font-size: 0.875rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addres-col {
  text-align: left;
  font: normal normal normal 1rem/1.5rem Poppins;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
}

.displine-list {
  text-align: left;
  font: normal normal normal 1rem/1.5rem Poppins;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
}

.status-td {
  width: 11.125rem;
  height: 2.4375rem;
  background: #d9f8dc 0% 0% no-repeat padding-box;
  border-radius: 1.125rem;
  opacity: 1;
  font: normal normal medium 0.875rem/1.5rem Poppins;
  letter-spacing: 0rem;
  color: #17a325;
  opacity: 1;
  display: flex;
  align-items: center;

  flex-wrap: wrap;
  justify-content: center;
}

.status-td-red {
  background: rgb(248, 184, 184) 0% 0% no-repeat padding-box !important;
  color: red !important;
}

.accept-modal-p1 {
  font-size: 1.5rem !important;
  line-height: 2.1875rem !important;
  letter-spacing: 0px !important;
  color: #0a365b !important;
  opacity: 1;
}

/* .asstherepist-sidebar {
  height: 13.625rem !important;
} */

.my-unfinish {
  padding-top: 71px !important;
  padding-left: 50px !important;
  padding-bottom: 45px !important;
}

.status-td-green {
  color: #17a325 !important;
  background-color: #d9f8dc !important;
  font-weight: 500;
}

.status-td-orange {
  color: #ca7718 !important;
  font-weight: 500;
  background-color: #fdeddc !important;
}

.status-td-red {
  color: #d42b2b !important;
  font-weight: 500;
  background-color: #fddcdc !important;
}

.status-td-gray {
  color: #5a5a5a !important;
  font-weight: 500;
  background-color: #eaeaea !important;
}

.status-td-pink {
  color: #c63e77 !important;
  font-weight: 500;
  background-color: #fddcea !important;
}

.status-td-blue {
  color: #4a40b4 !important;
  font-weight: 500;
  background-color: #e8e6ff !important;
}

.select-inputupdate {
  height: 2.5rem;
  outline: none;
  padding: 0.3125rem 10px;
  background: #ffffff;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  width: 100%;
  letter-spacing: 0rem;
  font-size: 1rem;
  line-height: 1.5625rem;
  font-family: "Poppins";
  color: #808d98 !important;
}

.paitiant-chart-input {
  height: 46px;
  outline: none;
  padding: 0.3125rem 10px;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  letter-spacing: 0rem;
  font-size: 1rem;
  line-height: 1.5625rem;
  font-family: "Poppins";
  color: #000 !important;
}

.addToBlockListBtn .save-btnn {
  width: fit-content;
  background: transparent linear-gradient(299deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 86px;
  opacity: 1;
  border: none;
  color: white;
  font-size: 1.2rem !important;
  display: flex;
  align-items: center;
  padding: 30px 30px;
  justify-content: center;
}

.save-btnn {
  width: 139px;
  height: 36px;
  background: transparent linear-gradient(299deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 86px;
  opacity: 1;
  border: none;
  color: white;
  font-size: 1.2rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-rate-visit:focus {
  box-shadow: unset !important;
  border-color: lightgray !important;
}

.inputWidth.input-group {
  width: 100%;
  margin-left: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.blockedByLink {
  color: black;
  text-decoration: none;
}

.blockedByLink:hover {
  color: black;
  text-decoration: none;
}

.upload-newreffral {
  color: #0a365b !important;
  margin-bottom: 8px;
}

.margin-top-login {
  margin-top: 30%;
}

.new-reffral-visit .container-fluid .visit_sec .cs-heading {
  text-align: left;
  font: normal normal 600 24px/35px Poppins;
  font-family: "Poppins";
  font-size: 24px;
  line-height: 35px;

  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
}

.new-reffral-visit .container-fluid {
  padding: 0;
}

.h2-primary {
  text-align: left;
  font: normal normal 600 24px/35px Poppins;
  font-family: "Poppins";
  font-size: 24px;
  line-height: 35px;

  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
}

.select-icon label,
.lable-staff label {
  width: 100%;
  margin-bottom: 0.8125rem;
  letter-spacing: 0rem;
  color: #0a365b;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  font-family: "Poppins Semi Bold";
  font-size: 1rem !important;
}

.margin-topstaff {
  margin-top: 47px;
}

.input-error.login-error {
  /* position: absolute;
  bottom: 0; */
  width: 100% !important;
  margin: right !important;
}

.login-input {
  width: 80% !important;
}

.container {
  max-width: 1400px !important;
}

.fogot_icn {
  right: -40px;
  top: 3px;
}

.right-side-img {
  padding: 0;
  margin: 0;
  position: fixed;
  top: 0;
  right: 0;
  width: 68%;
  height: 100vh;
  z-index: -1;
}

.loginContainer {
  justify-content: flex-start;
}

.delet-iconagency {
  top: 0px;
  right: 0;
}

.new-reff-radio {
  justify-content: flex-start !important;
}

.css-5gt656-control {
  height: 2.5rem;
}

.update_status_unfini {
  width: 58%;
}

.font-size_16 {
  font-size: 1rem !important;
}

.select-width {
  width: unset !important;
}

.width-75 {
  width: 75%;
}

.width-50 {
  width: 45%;
}

.btn-width-1 {
  /* width: 43.3333%; */
  padding: 7px 8px;
  display: flex;
}

.desktop-gap {
  gap: 16px;
}

.margin-left-16 {
  margin-left: 1em;
}

.reset_icon_tooltip {
  right: -5%;
  bottom: 5%;
}

.desktop_width-50 {
  width: 90%;
}

.desktop_flex {
  display: flex;
  justify-content: space-between;
}

.desktp_display-none {
  display: none;
}

.mobile_display-none {
  display: block;
}

.profile_margin-top {
  margin-top: 170px;
}

.reset_input {
  width: 95% !important;
}

.block-list-table .th-child:nth-child(1) {
  padding-left: 0 !important;
}

.block_sub_icon {
  position: absolute;
  right: 3px;
  bottom: 5px;
}

.Profile_tooltip1 .tooltip-arrow {
  display: none;
}

.profile-section {
  padding-left: 0;
}

.margin-left-admin-user {
  margin-left: 35px;
}

.Profile_tooltip1 .tooltip-inner {
  background-color: #0f7ad4 !important;
  text-align: start !important;
  min-width: 24.25rem !important;
  /* height: 4.125rem !important; */
  padding: 0.9375rem 15px !important;
  font-size: 0.875rem !important;
  font-family: "Poppins" !important;
  letter-spacing: 0rem;
  line-height: 1.125rem;
}

.Profile_tooltip1 .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  display: none;
}

.true_width {
  width: 88% !important;
}

.thera-login-margin {
  padding-top: 80px;
}

/*.btn-heights {
  height: 2.875rem !important;
  margin: 0 !important;
} */
.pt-66 {
  padding-top: 0.6rem !important;
}

.padding-agency-toptabs {
  padding: 27px 25px !important;
}

.agency-tabs-h4 h4 {
  margin-bottom: 6px;
}

/* .Profile_tooltip1 {
  transform: translate3d(50%, 345px, 0px) !important;
} */
.padding-left-ref {
  padding-left: 30px !important;
}

.padding-left-ref-45 {
  padding-left: 45px !important;
}

.ref-height-6 {
  height: 46px !important;
}

.ref-height {
  height: 2.25rem;
}

.radio_btns-margin {
  margin-bottom: 26px !important;
}

.radio-name-mb-3 {
  margin-bottom: 20px;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.margin-bar-new-ref {
  margin-bottom: 36px !important;
}

.new-reff-mobile-width {
  width: 50%;
}

.mobile-new-reff {
  margin-left: 35px;
}

.padding-therapist-tabs {
  padding: 40px 25px !important;
}

.ViewOptions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 28px;
  margin-left: 2rem;
}

.ViewOptions button {
  border: 0;
  padding: 12px 11px;
  background: linear-gradient(286deg, #6a40d4 0%, #0f7ad4 100%);
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  gap: 7.08px;
}

.ViewOptions button:nth-child(1) {
  border-radius: 6px;
}

.ViewOptions button:nth-child(2) {
  border-radius: 0px 6px 6px 0px;
  border-color: #cccccc;
  border-right: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  z-index: -1;
  transform: translate(-5%, 0px);
}

.margin-bottom-1 {
  margin-bottom: 16px;
}

.ViewOptions button.active {
  text-align: left;
  font: normal normal 600 16px/25px Poppins !important;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  opacity: 1;
}

.unblock-btn {
  padding: 9px 35px !important;

  width: 136px;
  height: 40px;
}

.text-filed-input {
  text-align: left;
  font: normal normal normal 1rem/1.5625rem Poppins;
  letter-spacing: 0px;
  color: #808d98;
  opacity: 1;
}

.unblock-btn:hover {
  border: 1px solid transparent !important;
}

.sup-point {
  top: -3px;
}

.Add-BlockList-btn,
.Add-New-btn {
  width: 211px;
  height: 46px;
  border: 0;
  padding: 6px;
  gap: 10px;
  display: flex;
  background: linear-gradient(291deg, #6a40d4 0%, #0f7ad4 100%);
  border-radius: 86px;
  opacity: 1;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.Empty-div {
  display: none;
}

.button-width {
  width: 11rem !important;
}

.Add-New-btn {
  width: 180px !important;
  gap: 0 !important;
}


.checkmark-5::after {
  content: "";
  position: absolute;
  display: none;
}

.container-2 input:checked~.checkmark-5::after {
  display: block;
}

.container-2 .checkmark-5::after {
  left: 0.8125rem;
  top: 0.4375rem;
  width: 0.4375rem;
  height: 0.9375rem;
  border: solid white;
  border-width: 0 0.1875rem 3px 0;
  transform: rotate(45deg);
}

.container-2 input:checked~.checkmark-5 {
  background-color: #0f7ad4;
  border: 0.0625rem solid #0f7ad4;
}

.checkmark-5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.25rem;
  height: 2.25rem;
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  border: 0.0625rem solid #b6c4ce;
  border-radius: 100%;
}

.add-padding_mobile {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.desktop_block-display {
  display: none;
}

.mobile-margin-p-user {
  margin-left: 3rem;
}

.Patient-01,
.ApprovM .payment-popup {
  padding-left: 16px !important;
}

.Admin-profile-text {
  text-align: left;
  font: normal normal 600 1.5rem/2.1875rem Poppins;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
}

.profile_image_admin {
  background: #f1f5f8 0% 0% no-repeat padding-box;
  border-radius: 200px;
  min-width: 10.3125rem;
  min-height: 10.3125rem;

  opacity: 1;
}

.admin_upload_btn p {
  margin: 0;
  text-align: left;
  font: normal normal normal 16px/24px Poppins;
  letter-spacing: 0px;
  color: #a1aeb9;
  text-transform: uppercase;
  opacity: 1;
}

.admin_upload_btn button {
  text-align: left;
  font: normal normal normal 16px/24px Poppins;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
  border: 0;
  background: transparent;
}

.desk-w-75 {
  width: 75% !important;
}

.tabs_unfinish {
  gap: 18px;
}

.tabs_unfinish .nav-item {
  min-width: 15%;
}

.tabs_unfinish button {
  width: 100%;
  padding-right: 40px !important;
}

.tabs_unfinish .nav-item.show .nav-link,
.tabs_unfinish .nav-link {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b4d1e8 !important;
  border-bottom: 1px solid transparent !important;
  border-radius: 12px 12px 0px 0px !important;
  opacity: 1;
  padding: 18px 15px;
  height: 100%;
}

.tabs_unfinish .nav-item {
  margin-bottom: 0 !important;
}

.tabs_unfinish .nav-link:focus,
.nav-link:hover {
  color: unset !important;
}

.tabs_unfinish #controlled-tab-example-tab-patientprofile.nav-link.active {
  background-color: #faedde !important;
  border-radius: 12px 12px 0px 0px !important;
  border: 1px solid #faedde !important;
  border-bottom: none !important;
  color: #0a365b !important;

  opacity: 1;
}

#controlled-tab-example-tabpane-patientprofile {
  border: 10px solid #faedde !important;
  padding: 47px 29px;
}

.tabs_unfinish #controlled-tab-example-tab-patientdocument.nav-link.active {
  background-color: #e8e6fd !important;
  border-radius: 12px 12px 0px 0px !important;
  border: 1px solid #e8e6fd !important;
  border-bottom: none !important;
  color: #0a365b !important;

  opacity: 1;
}

#controlled-tab-example-tabpane-patientdocument {
  border: 10px solid #e8e6fd !important;
  padding: 47px 29px;
}

.tabs_unfinish #controlled-tab-example-tab-episodes.nav-link.active {
  background-color: #e2eefb !important;
  border-radius: 12px 12px 0px 0px !important;
  border: 1px solid #e2eefb !important;
  border-bottom: none !important;
  color: #0a365b !important;

  opacity: 1;
}

#controlled-tab-example-tabpane-episodes {
  border: 10px solid #e2eefb !important;
  padding: 35px 38px;
}

.tabs_unfinish #controlled-tab-example-tab-staffassignment.nav-link.active {
  background-color: #dff7de !important;
  border-radius: 12px 12px 0px 0px !important;
  border: 1px solid #faedde !important;
  border-bottom: none !important;
  color: #0a365b !important;

  opacity: 1;
}

#controlled-tab-example-tabpane-staffassignment {
  border: 10px solid #dff7de !important;
  padding: 47px 29px;
}

/*  */

.AddStaff-discipline {
  background: transparent linear-gradient(278deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  padding: 16px 18px;
  border: 0;
  color: #fff;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.patient_document-tabs {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #0a365b;
  opacity: 1;
}

.document-tab thead tr th {
  border-bottom: 1px solid #ebeaed;
}

.document-tab thead tr th {
  padding-top: 0;
  text-align: left;
  font: normal normal medium 16px/20px Poppins;
  font-size: 16px;
  font-weight: 500 !important;
  letter-spacing: 0px;
  color: #2e2c34;
  opacity: 1;
}

.therapist-tab thead tr th {
  color: #A1AEB9 !important;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 24px;
}

.therapist-tab-1 thead tr th,
.therapist-tab-1 tbody tr td {
  color: #2E2C34 !important;
  font-size: 16px;
  line-height: 24px;
}

.therapist-tab-1 thead tr th {
  font-weight: 500 !important;
}

.therapist-tab-1 tbody tr td {
  font-weight: 400 !important;
}

.therapist1 {
  position: relative;
}

/* Tooltip content */
.therapist1 .tooltip-inner {
  font-size: 10px !important;
  line-height: 15px;
  font-weight: 400;
  background-color: white !important;
  color: #0A365B !important;
  border-radius: 5px;
  border: 1px solid #B6C4CE !important;
  position: relative;
  padding: 10px;
}

.therapist1 .tooltip-inner::before {
  z-index: 10;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 0;
  bottom: 0;
  top: calc(50% - 5px);
  left: 0;
  box-sizing: border-box;
  transform-origin: 0 0;
  transform: rotate(45deg);
}

.therapist1 .tooltip-inner::after {
  z-index: -10;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 0;
  bottom: 0;
  top: calc(50% - 5px);
  left: 0;
  box-sizing: border-box;
  border: 5px solid #fff;
  border-color: transparent transparent #fff #fff;
  transform-origin: 0 0;
  transform: rotate(45deg);
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.4);
}


.document-tab tbody tr td {
  border-bottom: 1px solid #ebeaed;
  text-align: left;
  font: normal normal medium 16px/20px Poppins;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  opacity: 1;
}

.document-tab tbody tr td:first-child {
  min-width: 260px;
}

.dropdown-stafftab .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.dropdown-stafftab .show>.btn-primary.dropdown-toggle,
.dropdown-stafftab button {
  background-color: transparent !important;
  color: #000 !important;
  border: 0;
}

.dropdown-stafftab .dropdown-toggle::after {
  display: none;
}

.dropdown-stafftab .dropdown-menu .dropdown-item {
  text-align: left;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #0a365b !important;
  padding: 10px 23px;
  font-size: 14px;
  font-weight: 400;
  opacity: 1;
}

.icon_email {
  min-width: 48px;
  min-height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  /* padding: 10px; */
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.icon_email.Bubble::before {
  content: "";
  width: 13px;
  height: 13px;
  background-color: red;
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.icon_email.Bubble.top::before {
  right: 7px;
  top: 8px;
}

.Box-table-img {
  margin-right: 26px;
}

.updated-images-1 {
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  margin-right: 8px;
  border-radius: 100px;
  height: 30px;
  display: flex;
}

.updated-images-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-document-modal .modal-dialog {
  max-width: 812px;
}

.upload__madal-title {
  text-align: left;
  letter-spacing: 0px;
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #2e2c34 !important;
  opacity: 1;
  padding: 0 calc(50px - 16px);
  margin-bottom: 43px !important;
  margin-top: calc(43px - 16px) !important;
}

.upload-commn-padding {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.signup_form_label.upload-modal {
  text-align: left;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0px;
  color: #0a365b !important;
  opacity: 1;
}

.upload-doc-modal-button {
  width: 100%;
  height: 35vh;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ebeaed;
  border-radius: 12px;
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 43px !important;
}

.upload-icon-modal {
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-bottom: 8px !important;
  /* UI Properties */
  border: 6px solid #f9fafb;
  border-radius: 20px;
}

.upload-icon-modal-parent p {
  text-align: center;
  font: normal normal normal 14px/24px Poppins;
  letter-spacing: 0px;
  color: #4a40b4 !important;
  opacity: 1;
}

.upload-icon-modal-parent p.OffUpload {
  color: rgb(139, 139, 139) !important;
}

.upload-doc-modal-button.offUpload-color {
  background-color: rgb(245, 245, 245) !important;
}

.submit-btn-upload-doc {
  background: transparent linear-gradient(284deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 12px;
  width: 139px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  color: #fff;
}

.required_lable {
  /* UI Properties */
  text-align: left;
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: 400 !important;
  color: #d42b2b;
  opacity: 1;
  margin-left: 30px;
}

.profile_tabs-update {
  display: flex;
  gap: 9px;
}

.profile_tabs-update .AddStaff-discard {
  text-align: center;
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #d42b2b;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d42b2b;
  border-radius: 8px;
  padding: 8px 20px;
  width: 104px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_tabs-update .AddStaff-save {
  background: transparent linear-gradient(284deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 8px 20px;
  text-align: center;
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border: 0;
  width: 104px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.childContainer {
  display: flex;
  justify-content: space-between;
  padding: 0px 5px;
}

.childContainer>p {
  font-size: 16px;
}

@media (max-width: 1745px) {
  .UserProfile_TabsCta .col-xl-2 {
    flex: 0 0 21.666667%;
    max-width: 21.666667%;
  }
}

@media (max-width: 1703px) {
  .agency-reject-gap-40 {
    gap: 15%;
  }

  .chat-box-download {
    width: 68%;
  }
}

@media (max-width: 1500px) {
  .chat-box-download {
    width: 65%;
  }
}

@media (max-width: 1440px) {
  #times2 {
    position: relative;
    left: -30px;
  }

  .chat-box-download {
    width: 60%;
  }

  .agency-reject-gap-40 {
    gap: 30%;
  }

  .thera-login-margin {
    padding-top: 38px;
  }

  .sup-point {
    top: 2px;
  }

  .space-box {
    height: 100%;
    background: #fff;
    position: absolute;
    /* border: 1px solid; */
    z-index: 1;
    /* left: 84%; */
    right: 0;
  }

  /* agecy Profile tabs */
  .agencyInfo,
  .paymentInfo,
  .defualtRate,
  .documents,
  .blockList {
    line-height: 18px !important;
  }

  /* .calc-widt {
    width: 50%;
  } */
  .container {
    max-width: 1140px !important;
  }

  .p-36 {
    padding: 0px 10%;
  }
}

@media (max-width: 1279px) {
  .thera-login-margin {
    padding-top: 59px;
  }

  .chat-box-download {
    width: 55%;
  }
}

@media (max-width: 1199px) {
  .agency-reject-gap-40 {
    gap: 6%;
  }

  .chat-box-download {
    width: 48%;
  }

  /* .mobile-75 {
    width: 100% !important;
  } */

  .mobile-padding-right {
    padding-right: 0 !important;
  }

  .therapist3.tooltip,
  .therapist2.tooltip,
  .therapist.tooltip {
    margin-top: 0;
    margin-left: -421px;
  }
}

@media (max-width: 1105px) {
  .UserProfile_TabsCta .col-xl-2 {
    flex: 0 0 24.666667%;
    max-width: 24.666667%;
  }
}

@media (max-width: 1073px) {
  .chat-display-none {
    display: none;
  }
}

@media (max-width: 1024px) {

  .therapist3.tooltip,
  .therapist2.tooltip,
  .therapist.tooltip {
    margin-top: 0;
    margin-left: -344px;
  }
}

@media (max-width: 990px) {
  .mobile_width_100 {
    width: 100% !important;
  }

  .UserProfile_TabsCta .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .profile_margin-top {
    margin-top: 60px;
  }

  .new-thera-map #map_parent {
    flex-wrap: wrap;
  }

  .new-thera-map #map_parent .map-box {
    width: 100%;
  }

  .agency-reject-gap-40 {
    gap: 6%;
  }

  .therapist3.tooltip,
  .therapist2.tooltip,
  .therapist.tooltip {
    margin-top: 0px;
    margin-left: -252px;
  }

  .profile_padding {
    padding: 0% 15%;
  }

  .profile_text-center {
    text-align: center;
    width: 100%;
  }

  .profile_justify {
    display: inherit !important;
    justify-content: center !important;
  }

  .profile_width {
    width: 100%;
  }

  .mobile-padding_0 {
    padding: 0 !important;
  }

  .col1 {
    max-width: 100% !important;
  }

  .col1 {
    width: 33.333% !important;
  }

  .therapist__profile_map .map-flex {
    flex-wrap: wrap;
    width: 100%;
  }

  .therapist__profile_map .map-box {
    width: 100%;
  }

  .mobile_width-990 {
    width: 100% !important;
  }

  .mobile_flex-direction {
    flex-direction: column;
  }

  .mobile_padding_0 {
    padding: 0 !important;
  }

  .mobile_margin-auto {
    margin: auto;
  }

  .fields-1 {
    width: 100% !important;
  }

  .inputFields {
    width: 100% !important;
  }

  .mobile_table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

@media (max-width: 1200px) {
  .col1 {
    width: 33.333% !important;
  }

  * {
    font-size: 0.8rem;
  }

  .text-resize {
    width: 100% !important;
  }

  .loginContainer {
    justify-content: center;
  }

  .right-side-img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .right-side-img::before {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    content: "";
    z-index: 4;
    top: 0;
    left: 0;
  }
}

@media (max-width: 1114px) {
  .mobile_margin_1 {
    margin-top: 29px;
  }
}

@media (max-width: 991px) {
  .mobile_responsive_padding {
    padding-left: 40px !important;
    padding-right: 50px !important;
  }

  .mobile_padding {
    padding-bottom: 40px;
  }

  .add-padding_mobile {
    padding: 0px 20px !important;
  }
}

@media (max-width: 769px) {
  .desktop-margin_left {
    padding-left: 0 !important;
  }

  .mobile_w-90 {
    width: 90% !important;
  }

  .UserProfile_TabsCta .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mobile_wrap-990 {
    flex-wrap: wrap;
  }

  .profile_margin-top {
    margin-top: 27px;
  }

  .pr-50 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .mobile-pl-5 {
    padding-left: 48px !important;
  }

  .mobile-margin-p-user {
    margin-left: 0rem !important;
  }

  .mobile_block-display {
    display: none;
  }

  .desktop_block-display {
    display: block;
  }

  .sup-point {
    top: 2px;
  }

  .therapist3.tooltip,
  .therapist2.tooltip,
  .therapist.tooltip {
    margin-top: 0px;
    margin-left: -213px;
  }

  .mobile_table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .mobile_margin-top {
    margin-top: 16px;
  }

  .margin-left-admin-user {
    margin: 0;
  }

  .mobile_margin-bottom {
    margin-bottom: 16px !important;
  }

  .desktp_display-none {
    display: block;
  }

  .mobile_display-none {
    display: none !important;
  }

  .desktop_width-50 {
    width: 100%;
  }

  .mobile_width-83 {
    width: 82.5%;
  }

  .mobile_pl-3 {
    padding-left: 1rem;
  }

  .mobile_ml-0 {
    margin-left: 0 !important;
  }

  .mobile_text-center {
    text-align: center !important;
  }

  .mobile_align-itmes-center {
    align-items: center !important;
  }

  .mobile_flex {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .delet-iconagency {
    top: 100%;
    right: 0;
  }

  .col1 {
    width: 45.333% !important;
  }

  .mobile-margin-bottom {
    margin-bottom: 16px;
  }

  .desktop-gap {
    gap: 0;
  }

  .mobile-margin-top {
    margin-top: 16px;
  }

  .width-50 {
    width: auto;
    margin-bottom: 16px !important;
  }

  .mobile_wrap {
    flex-wrap: wrap !important;
  }

  .mobile_justify {
    justify-content: center;
  }

  .mobile_flex {
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .sc-gikAfH.iKLKCk {
    width: 100% !important;
  }

  .mobile_width {
    width: 100% !important;
  }

  .right-side-img {
    position: fixed;
    top: 0;
    right: 0;
    padding: 0;
    width: 100%;
    /* height: 100vh; */
    z-index: -1;
    margin: 0;
  }

  .right-side-img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .right-side-img::before {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #ffffffd0;
    content: "";
    z-index: 4;
    top: 0;
    left: 0;
  }

  .login-logo {
    max-width: 30.625rem;
    width: 100%;
    max-height: 8.9375rem;
    height: 100%;
    margin-bottom: 3.125rem;
  }

  .mobile-none {
    display: none;
  }

  .margin-top-login {
    margin-top: 10%;
  }
}

@media (max-width: 740px) {
  .UserProfile_TabsCta .col-md-4 {
    /* -ms-flex: 0 0 33.333333%; */
    flex: 0 0 49.333333%;
    max-width: 49.333333%;
  }
}

@media (max-width: 560px) {

  .AuthorizationsTabs .common-btns-div .cancel-btns,
  .AuthorizationsTabs-dialog .common-btns-div .cancel-btns {
    width: 100%;
    margin-bottom: 24px;
    text-align: center;
  }

  .AuthorizationsTabs .common-btns-div .Approve-btns,
  .AuthorizationsTabs-dialog .common-btns-div .Approve-btns {
    margin-left: unset;
    width: 100%;
  }

  .mobile_width-30,
  .mobile_width2-30 {
    width: 100%;
    margin-bottom: 8px !important;
  }

  .mobile_word_wrap {
    word-break: break-word;
  }

  .agency-margiin-left {
    margin-left: unset;
  }

  .therapist3.tooltip,
  .therapist2.tooltip,
  .therapist.tooltip {
    margin-top: 0px;
    margin-left: -50px;
  }

  .padding-therapist-tabs {
    padding: 25px 25px !important;
  }

  .mobile-new-reff {
    margin-left: 10px;
  }

  .col1 {
    width: 100% !important;
  }
}

@media (max-width: 532px) {
  .UserProfile_TabsCta .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 368px) {

  .therapist3.tooltip,
  .therapist2.tooltip,
  .therapist.tooltip {
    margin-top: 0px;
    margin-left: 0px;
  }

  .gap-60 {
    gap: 16px;
  }

  .mobileWindow {
    flex-wrap: wrap;
    margin-bottom: 8px;
  }
}

@media (max-width: 350px) {
  .mobile-new-reff {
    margin-left: 0;
  }

  * {
    font-size: 0.7rem;
  }

  .mobile_wrappar_justify-center {
    justify-content: center !important;
  }
}

@media (min-width: 2560px) {
  .col1 {
    width: 20%;
  }
}

@media (min-width: 1074px) {
  .col-md-2_5 {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .col-md-9_5 {
    flex: 0 0 70%;
    max-width: 70%;
  }
}

@media (min-width: 1280px) {
  .col-md-2_5 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-9_5 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media (min-width: 1440px) {
  .col-md-2_5 {
    flex: 0 0 22%;
    max-width: 22%;
  }

  .col-md-9_5 {
    flex: 0 0 78%;
    max-width: 78%;
  }
}

/* ---Patient---*/

.patient_details {
  padding: 10px;
}

.patient_details td {
  padding: 10px;
}

.patient_details th {
  padding: 10px;
  color: #4d5253;
  font-weight: 600;
}

.patient_document_sec {
  position: relative;
}

.sub_heading_patient_document {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
}

.patient_details .patient_document_rename {
  margin-left: auto;
  display: block;

  background: transparent linear-gradient(180deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #00000029;
  border-radius: 20px;
  opacity: 1;
  border: none;
  padding: 10px 30px;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 13px;
  width: 132px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.patient_details .patient_document_rename.disabled {
  background: lightgray !important;
  color: gray !important;
  box-shadow: none;
}

.patient_document_sec .uploaded_by {
  margin-right: 7px;
}

.upload_document a {
  display: block;
  color: #fff;
  padding: 16px 40px;
  background: linear-gradient(281deg, #6a40d4 50%, #0f7ad4 100%) 0% 0% no-repeat;

  text-align: center;
  border-radius: 15px;
}

.upload_document {
  top: 35px;
  position: absolute;
  right: 6px;
}

.sub_heading {
  margin-bottom: 30px;
}

.input-group-text,
.mobile_wrap input,
.Profile-text[type="text"],
.mobile_wrap input::placeholder,
.py-input,
.py-input::placeholder {
  color: #000 !important;
}

.rate_fields>input {
  color: blue !important;
}

.text-primary {
  color: blue !important;
}

.og-acnhor {
  color: #007bff !important;
  text-decoration: none !important;
  background-color: transparent !important;
  -webkit-text-decoration-skip: objects !important;
}

.term-heading {
  justify-content: space-between;
  width: 89%;
}

span.blue_dollor {
  color: #0f7ad4 !important;
}

input.blue_input {
  color: #0f7ad4 !important;
}

.blueText {
  color: #0f7ad4 !important;
}

input.color_placeholder {
  color: #000 !important;
}

.select_color_black .color_placeholde {
  color: #808d98 !important;
}

.DatePlaceholder.color_placeholde::placeholder {
  color: #808d98 !important;
}

.select_color_black input.color_black {
  color: #000 !important;
}

textarea.textarea_black {
  color: #000 !important;
}

.select_color_blk .color_placeholder input {
  color: #000 !important;
}

.align_txt_input .css-qbdosj-Input {
  margin-bottom: 0px;
}

input.align_password {
  margin-bottom: -11px;
}

.street_color_black input {
  color: #000 !important;
}

.card_notifications_main {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 30px 10px;
}

.card_notifications_main p.notification_txt {
  font-size: 16px;
  font-weight: 500 !important;
  font-style: initial;
}

.card_notifications_main p.link_txt_noti {
  font-size: 16px;
  font-weight: 500;
  color: #0f7ad4 !important;
  cursor: pointer;
  font-style: initial;
}

.card_notifications_main p.link_txt_noti .disabled {
  font-size: 16px;
  font-weight: 500;
  color: #a6aeb4 !important;
  font-style: initial;
  cursor: context-menu;
}

.card_notifications_main img.table-img {
  max-width: 40px;
  height: 40px;
  width: 100%;
}

.card_notifications_main p.cart_notifi_ctxt {
  font-size: 15px;
  font-weight: 500 !important;
  font-style: initial;
  /* max-width: 330px; */
  color: #000000a8;
}

.card_notifications_main span.cart_noti_txt {
  color: #000;
  font-size: 15px;
}

.card_notifications_main p.cart_notifi_timeing span.cart_noti_txt {
  font-size: 14px;
  font-weight: 500 !important;
  font-style: initial;
  color: #b3bad3;
}

.card_notifications_main .line_notication {
  border-bottom: 1px solid #80808087;
  margin: 13px 0;
  padding: 13px 0;
}

.read {
  background-color: #dfeffc;
  /* border-radius: 10px; */
}

.scrollBarDiv {
  max-height: 455px;
  overflow-y: scroll !important;
}

.notifiction_bell_icon_main .show_notifi_ {
  position: absolute;
  content: "";
  top: -7px;
  right: -13px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  height: 30px;
  background-color: #ff20208c;
  border-radius: 50px;
}

.notifiction_bell_icon_main span.no_notifiction {
  font-size: 13px;
  color: #fff;
  font-style: normal;
  font-family: poppin;
}

.notifiction_bell_icon_main .bell_icon {
  border: 2px solid #e9ecef;
  padding: 7px;
  border-radius: 5px;
}

/* Notification css start 9/jan/2024*/
.notification-div {
  position: relative;
  justify-content: space-between !important;
}

.cardNotification {
  border: 1px solid #eae8e8 !important;
  border-radius: 20px !important;
  box-shadow: rgba(159, 162, 191, 0.18) 0px 9px 16px,
    rgba(159, 162, 191, 0.32) 0px 2px 2px;
  overflow: hidden !important;
  width: 540px !important;
  margin: 16px !important;
  position: absolute !important;
  right: -18px;
  padding: 10px;
  background-color: #ffff;
  top: 60px;
}

/* .cardNotification .row.read {
  background-color: #dfeffc;
} */

.cardNotification .row.unread {
  font-weight: bold;
}

/* Notification css end 9/jan/2024*/

.staffNewReferralMain input::placeholder,
.select_color_black input.color_placeholde::placeholder,
.form-group input::placeholder {
  color: #808d98 !important;
}

.GrayDatePlaceholder.color_placeholder {
  color: #808d98 !important;
}

.DatePlaceholder {
  color: #000 !important;
}

.rejected_text_border input.rbt-input-main.rbt-input {
  border: 2px solid #c52525;
}

.rejected_text_div {
  border: 2px solid #c52525 !important;
  border-radius: 5px;
}

.rejected_text_border {
  border: 2px solid transparent;
  border-radius: 5px;
}

input.rejected_text {
  border: 2px solid #c52525;
}

.cursor-text {
  cursor: text !important
}

/* button.signed_w9 {
  border: 2px solid #ff0000;
} */

.CredentialsTooltip {
  overflow: unset !important;
  white-space: nowrap !important;
}

.CredentialsTooltip .tooltip-inner {
  max-width: 450px !important;
  background-color: white;
  color: black;
  border: 1px solid black;
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  cursor: pointer !important;
}

.AuthorizationSelect__control {
  padding: 4px;
}

.AuthorizationSelect__indicators {
  display: none !important;
}

.AlertDanger {
  border: 0.0625rem solid #e89797 !important;
  padding: 0.1875rem;
  font-size: 0.875rem;
  margin-top: 0.1875rem;
  border-radius: 4px;
}

.DefineWidthFOrChild .list_alpha_space div {
  width: 97%;
}

.DefineWidthFOrChild .list_alpha_space span {
  width: 3%;
}

.PrivacyPolicyCol .BolderEmail a,
.PrivacyPolicyCol .BolderEmail span {
  font-weight: bold;
  color: #000;
}

.PrivacyPolicyCol .BolderEmail a {
  text-decoration: underline;
}

.FormGroupPrimeryInput {
  padding: 0.3125rem 10px;
  background: #ffffff;
  border: 0.0625rem solid #b6c4ce;
  border-radius: 0.25rem;
  width: 100%;
  letter-spacing: 0rem;
  font-size: 1rem;
  line-height: 1.5625rem;
  font-family: "Poppins";
  color: #808d98 !important;
}
 
.invoice-tbody tr:last-child{
  border-bottom: 2px solid #dee2e6;
}